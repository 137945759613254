import { compose } from "@reduxjs/toolkit";
import { Button, Col, Modal, Row, Skeleton, Table } from "antd";
import dayjs from 'dayjs';
import React from "react";
import DynamicEnumSelect from "../../components/FetchEnum";
import { TitleLevel3 } from "../../components/general-component";
import Api from "../../network/api";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import { ApiHandler } from "../../network/network-manager";
import withRouter from "../../network/with-router";

const labelStyle = { fontSize: 12, fontWeight: 500, color: 'gray' }
const dataValueStyle = { fontSize: 14, fontWeight: 500, color: 'black' }

class AdminActivityPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            date_list: [],
            isDataFetching: false,
            pageSize: 50,
            totalPagination: 0,
            page: 1,
            filterDate: null,
            eachRowData: {},
            showModal: false,
            logType: []
        }
    }
    componentDidMount() {
        this.fetchData()
    }

    openModal = (data) => {
        this.setState({
            eachRowData: data,
            showModal: true
        })
    }

    fetchData = async (pageNumber, type) => {
        const { pageSize, page } = this.state
        this.setState({ isDataFetching: true })
        const requestParams = {
            page: pageNumber ? pageNumber - 1 : page - 1,
            size: pageSize,
            logType: type ?? type
        }
        await ApiHandler({ url: Api.admin_activity_log, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            .then(response => {
                this.setState({
                    data: response?.content,
                    totalPagination: response?.totalElements,
                    page: pageNumber ? pageNumber : page,
                    isDataFetching: false
                })
            })
            .catch(error => {
                this.setState({ isDataFetching: false })
            })
    }

    handlePaginationChange = (pageNumber, pageSize) => {
        this.fetchData(pageNumber);
    };

    render() {
        const { data, isDataFetching, showModal, eachRowData, totalPagination, page, pageSize, logType } = this.state
        const getRowClassName = (record, index) => {
            return index % 2 === 0 ? 'blue-row' : 'black-row';
        };
        const columns = [
            {
                title: 'No.',
                key: 'index',
                render: (value, item, index) => <span>{(page - 1) * pageSize + index + 1}</span>,
                width: 5
            },
            {
                title: 'Type',
                dataIndex: 'logType',
                key: 'logType',
                width: 100
            },
            {
                title: 'Action',
                dataIndex: 'activityType',
                key: 'activityType',
            },
            {
                title: 'Modified By',
                key: 'modifiedBy',
                render: (_, { admin }) => (
                    <>
                        <span>{admin.fullName}</span><br />
                        <span>{admin.phoneNumber}</span>
                    </>
                ),
            },
            {
                title: 'Modified Date',
                key: 'createdDateTime',
                render: (log) => {
                    const dateTime = dayjs(log.createdDateTime, 'YYYY-MM-DD HH:mm:ss');
                    let showDate = ''
                    if (dateTime) {
                        showDate = dateTime.format('DD MMM,YYYY hh:mm A')
                    }
                    return (
                        <>{showDate}</>
                    )
                }
            }, {
                title: 'Modified Fields',
                key: 'modifiedFields',
                width: 100,
                render: (log) => (
                    <Button type="primary" shape="rounded" onClick={() => this.openModal(log)}>View</Button>
                ),
            }
        ]
        return (
            <>
                <Modal
                    title="Log Detail"
                    open={showModal}
                    onCancel={() => this.setState({ showModal: false })}
                    footer={
                        <Button type="primary" >
                            Close
                        </Button>}
                >
                    <>
                        {
                            eachRowData && eachRowData.modifiedFields && eachRowData.modifiedFields.map(item => (
                                <>
                                    <Row style={{ marginBottom: 5, backgroundColor: '#f5f5f5', borderRadius: 8, paddingInline: 20, paddingBlock: 10 }}>
                                        <Col span={24} >
                                            <div style={labelStyle}>Field</div>
                                            <div style={dataValueStyle}>{item.fieldName}</div>
                                        </Col>
                                        <Col span={12}>
                                            <div style={labelStyle}>Current</div>
                                            <div style={dataValueStyle}>{item.current}</div>
                                        </Col>
                                        <Col span={12}>
                                            <div style={labelStyle}>Updated</div>
                                            <div style={dataValueStyle}>{item.updated}</div></Col>
                                    </Row >


                                </>
                            ))
                        }
                    </>
                </Modal >
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <TitleLevel3 label={"Admin Activity Log"} />
                    </Col>
                    <Col span={6} />
                    <Col span={6}>
                        <DynamicEnumSelect
                            enumtype="ADMIN_ACTIVITY_LOG"
                            className="custom-selector-gray"
                            size="large"
                            style={{
                                width: '100%',
                            }}
                            onChange={(value) => this.fetchData(1, value)}
                            placeholder="Select Log Type"
                        />
                    </Col>
                    <Col span={24}>
                        {
                            isDataFetching ?
                                <Skeleton active />
                                :
                                <Table
                                    style={{ borderRadius: 10 }}
                                    columns={columns}
                                    dataSource={data}
                                    pagination={{
                                        position: 'top' | 'bottom',
                                        total: totalPagination,
                                        current: page,
                                        onChange: this.handlePaginationChange,
                                        defaultPageSize: pageSize,
                                        showSizeChanger: false
                                    }}
                                    rowClassName={getRowClassName} />
                        }
                    </Col>
                </Row>
            </>
        )
    }
}

export default compose(withRouter)(AdminActivityPage)