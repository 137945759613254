import { EditOutlined } from "@ant-design/icons";
import { compose } from "@reduxjs/toolkit";
import { Button, Checkbox, Col, Form, Input, Modal, Row, Skeleton, Space, Table, Tag } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { TitleLevel3 } from "../../components/general-component";
import Api from "../../network/api";
import { ApiDelete, ApiFilter, ApiPost, ApiPut } from "../../network/network-manager";
import withRouter from "../../network/with-router";
import CustomPath from "../../routes/custom-path";

class DivisionPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            searchName: '',
            searchValue: [],
            openModal: false,
            openCreateModal: false,
            openUpdateModal: false,
            updateForm: {},
            updateDivision: {},
            isDataFetching: false
        }
        this.inputRef = React.createRef();
        this.updateRef = React.createRef();
    }




    componentDidMount() {
        this.fetchData();
    }

    changeSearchName = (event) => {
        this.setState({
            searchName: event.target.value
        })
    }

    search = () => {

    }

    //fetch data
    fetchData = async (value) => {
        const { searchValue } = this.state
        this.setState({
            isDataFetching: true
        })
        try {
            var params = {
                name: value ? value : searchValue,
                size: 100,
                page: 0
            }
            const response = await ApiFilter(Api.division_filter, params, this.props)
            if (response && response.content) {
                this.setState(prevState => ({
                    data: response.content,
                }));
            }
        } catch (error) {
        }
        this.setState({
            isDataFetching: false,
            searchValue: value ? value : searchValue
        })
    }

    //create Section
    saveDivision = async (formData) => {
        try {
            const response = await ApiPost(Api.division,
                {
                    "name": formData.name,
                    "shortCode": formData.shortCode,
                    "sortCode": formData.sortCode,
                    "active": formData.active,
                    'enableHomeDelivery': formData.enableHomeDelivery
                }, this.props
            )
            if (response.status === 200) {
                this.fetchData()
            }
        } catch (error) {
        }
        this.closeDivisionCreateForm()
    }


    //update Section
    updateDivision(division) {
        this.setState({
            updateDivision: division,
            openUpdateModal: true
        })
    }

    updateDivisionConfirm = async (formData) => {
        const { updateDivision } = this.state
        if (updateDivision.name === null || updateDivision.name === '') {
            return;
        }
        try {
            const response = await ApiPut(Api.division, updateDivision.id,
                {
                    'id': updateDivision.id,
                    'name': formData.name,
                    'active': formData.active,
                    "shortCode": formData.shortCode,
                    "sortCode": formData.sortCode,
                    'enableHomeDelivery': formData.enableHomeDelivery
                }, this.props
            )
            if (response.status === 200) {
                this.fetchData()
            }
            this.setState({ updateDivision: {} })
            this.closeDivisionUpdateForm()
        } catch (error) {

        }
        this.closeDivisionUpdateForm()
    }

    // delete Section
    deleteDivision = (id) => {
        this.setState({
            openModal: true,
            deleteItem: id
        });
    }

    deleteDivisionConfirm = async () => {
        const { deleteItem } = this.state
        try {
            await ApiDelete(Api.division, deleteItem, this.props)
        } catch (error) {

        }
        this.setState({
            openModal: false,
            deleteItem: ''
        });
        this.fetchData()
    }

    render() {
        const { data, openModal, openCreateModal, openUpdateModal, isDataFetching } = this.state
        const getRowClassName = (record, index) => {
            return index % 2 === 0 ? 'blue-row' : 'black-row';
        };
        const columns = [
            {
                title: 'No.',
                key: 'index',
                render: (value, item, index) => <span>{index + 1}</span>,
                width: 5
            },
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: 'Short Code',
                dataIndex: 'shortCode',
                key: 'shortCode'
            },
            {
                title: 'Sort Code',
                dataIndex: 'sortCode',
                key: 'sortCode'
            }, {
                title: 'Home Delivery',
                key: 'enableHomeDelivery',
                render: (_, { enableHomeDelivery }) => (
                    <>
                        {
                            (
                                enableHomeDelivery ? <Tag color="#87d068">Active</Tag> : <Tag color="#cd201f">Inactive</Tag>
                            )
                        }
                    </>
                ),
            }, {
                title: 'Status',
                key: 'active',
                render: (_, { active }) => (
                    <>
                        {
                            (
                                active ? <Tag color="#87d068">Active</Tag> : <Tag color="#cd201f">Inactive</Tag>
                            )
                        }
                    </>
                ),

            },
            {
                title: 'Action',
                key: 'action',
                dataIndex: '',
                textAlign: 'center',
                width: 60,
                render: (division) => (
                    <Space>
                        <Link to={`${CustomPath.township}/${division.id}`}><Button size={'middle'} type="primary" style={{ borderRadius: 40 }} onClick={() => this.updateDivision(division)}>Township</Button></Link>
                        <Button size={'middle'} type="primary" shape="circle" onClick={() => this.updateDivision(division)}><EditOutlined /></Button>
                        {/* <Button size={'middle'} type="primary" shape="circle" onClick={() => this.deleteDivision(division.id)}><DeleteOutlined /></Button> */}

                    </Space>
                ),
            },
        ]

        return (
            <>
                <Modal
                    title="Delete"
                    open={openModal}
                    onOk={() => this.deleteDivisionConfirm()}
                    onCancel={() => this.hideModal()}
                    okText="Confirm"
                    cancelText="Cancel"
                >
                    <p>Are you sure to delete?</p>
                </Modal>
                {this.divisionCreateForm(openCreateModal)}
                {this.divisionUpdateForm(openUpdateModal)}
                <Row style={{ marginBottom: "20px" }}>
                    <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
                        <TitleLevel3 label={"Division"} />
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        {/* <NJVAddButton handleClick={() => this.openDivisionCreateForm()} /> */}
                    </Col>
                </Row>
                {/* <Row style={{ marginBottom: 30 }}>
                    <Col span={1} />
                    <Col span={22} className="custom-btn">
                        <Input
                            onChange={this.changeSearchName}
                            style={{ width: 200, background: '#f1f1f1', height: 40, marginRight: 8 }}
                            placeholder="Search by name"
                        />
                        <Button size="large" onClick={() => this.search()} type="primary" >Search</Button>

                    </Col>
                </Row> */}
                {
                    isDataFetching ?
                        <Skeleton active />
                        :
                        <Table
                            // className="custom-table"
                            style={{ borderRadius: 10 }}
                            pagination={false}
                            columns={columns} dataSource={data}
                            rowClassName={getRowClassName}
                        />
                }
            </>


        )
    }

    // UI SECTION
    divisionCreateForm = (modalState) => {
        return (
            <Modal
                title="Division Form"
                open={modalState}
                closeIcon={null}
                footer={null}
            >
                <Form
                    layout="vertical"
                    ref={this.inputRef}
                    onFinish={this.saveDivision}>
                    <Form.Item name="name" label={"Name"} rules={[{ required: true, message: '*(requried)' }]}>
                        <Input style={{ background: '#f1f1f1' }} placeholder={"Enter name"} />
                    </Form.Item>
                    <Form.Item name="shortCode" label={"Short Code"} rules={[{ required: true, message: '*(requried)' }]}>
                        <Input style={{ background: '#f1f1f1' }} placeholder={"Enter short code"} />
                    </Form.Item>
                    <Form.Item name="sortCode" label={"Sorting Code"} rules={[{ required: true, message: '*(requried)' }]}>
                        <Input style={{ background: '#f1f1f1' }} placeholder={"Enter sorting code"} />
                    </Form.Item>
                    <Form.Item
                        name="active"
                        valuePropName="checked">
                        <Checkbox>Active</Checkbox>
                    </Form.Item>
                    <Form.Item
                        name="enableHomeDelivery"
                        valuePropName="checked">
                        <Checkbox>Enable Home Delivery</Checkbox>
                    </Form.Item>

                    <Form.Item style={{ textAlign: 'end', marginTop: 20 }}>
                        <Button style={{ marginRight: '20px' }} onClick={() => this.closeDivisionCreateForm()}>Cancel</Button>
                        <Button type="primary" htmlType="submit">Create</Button>
                    </Form.Item>
                </Form>
            </Modal>
        )
    }

    openDivisionCreateForm = () => {
        this.setState({
            openCreateModal: true
        })
    }

    closeDivisionCreateForm = () => {
        this.inputRef.current.resetFields()
        this.setState({
            openCreateModal: false
        })
    }

    divisionUpdateModalChange = () => {
        const { updateDivision } = this.state
        if (this.updateRef && this.updateRef.current) {
            this.updateRef.current.setFieldsValue(updateDivision)
        } else {
            this.updateRef = React.createRef()
        }

    }


    divisionUpdateForm = (modalState) => {
        return (
            <Modal
                title="Division Update"
                open={modalState}
                afterOpenChange={() => this.divisionUpdateModalChange()}
                closeIcon={null}
                footer={null}
            >
                <Form
                    layout="vertical"
                    ref={this.updateRef}
                    onFinish={this.updateDivisionConfirm}>
                    <Form.Item name="name" label={"Name"} rules={[{ required: true, message: '*(requried)' }]}>
                        <Input style={{ background: '#f1f1f1' }} placeholder={"Enter name"} />
                    </Form.Item>
                    <Form.Item name="shortCode" label={"Short Code"} rules={[{ required: true, message: '*(requried)' }]}>
                        <Input style={{ background: '#f1f1f1' }} placeholder={"Enter short code"} />
                    </Form.Item>
                    <Form.Item name="sortCode" label={"Sorting Code"} rules={[{ required: true, message: '*(requried)' }]}>
                        <Input style={{ background: '#f1f1f1' }} placeholder={"Enter sorting code"} />
                    </Form.Item>
                    <Form.Item
                        name="active"
                        valuePropName="checked">
                        <Checkbox checked={this.state.updateDivision.active}>Active</Checkbox>
                    </Form.Item>

                    <Form.Item
                        name="enableHomeDelivery"
                        valuePropName="checked">
                        <Checkbox checked={this.state.updateDivision.enableHomeDelivery}>Enable Home Delivery</Checkbox>
                    </Form.Item>

                    <Form.Item style={{ textAlign: 'end', marginTop: 20 }}>
                        <Button style={{ marginRight: '20px' }} onClick={() => this.closeDivisionUpdateForm()}>Cancel</Button>
                        <Button type="primary" htmlType="submit">Update</Button>
                    </Form.Item>
                </Form>
            </Modal>
        )
    }


    closeDivisionUpdateForm = () => {
        this.updateRef.current = null;
        this.setState({
            openUpdateModal: false,
            updateDivision: {}
        })

    }
}
export default compose(withRouter)(DivisionPage)
