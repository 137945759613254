import { compose } from "@reduxjs/toolkit";
import { Col, Input, Row, Select, Form, Button, Divider } from "antd";
import { default as React } from "react";
import withRouter from "../../network/with-router";

import { SvgCrossOutlined } from '../../components/custom-svg'
import { ApiHandler, ApiInstance } from "../../network/network-manager";
import Api from "../../network/api";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import { TitleLevel3 } from "../../components/general-component";
class UpdateOrderStatusPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            trackingNumberList: [],
            orderStatusList: [],
            orderStatus: null,
            isLoading: false
        }
        this.inputRef = React.createRef()
        this.updateRef = React.createRef()
    }

    componentDidMount() {

        this.fetchOrderStatus()
    }

    fetchOrderStatus = async () => {
        let params = {
            'enumType': 'ORDER_STATUS'
        }
        await ApiInstance(Api.enum_filter, HTTP_METHOD.GET, MEDIA_TYPE.JSON, params)
            .then(response => {
                let temp = []
                response.map((res, index) => {
                    temp.push({
                        key: res.key,
                        value: res.value,
                        label: res.value,
                    });
                })
                this.setState({
                    orderStatusList: temp
                })
            })
            .catch(error => { })

    }


    onPressEnter = (event) => {
        const { trackingNumberList } = this.state
        if (event.code === 'Enter') {

            const sanitizedText = event.target.value.replace(/\r?\n/g, ',');
            const substrings = sanitizedText.split(' ');
            const trimmedSubstrings = []
            substrings.map((substring) => {
                let tValue = substring.trim()
                if (tValue !== undefined && tValue !== '' && tValue.length > 8) {
                    trimmedSubstrings.push(tValue)
                }
            })
            this.setState({
                trackingNumberList: [...trackingNumberList, ...trimmedSubstrings],
            })
            this.inputRef.current.setFieldsValue({ searchBox: '' })

        }
    }
    removeFromList = (trackingNumber) => {
        const { trackingNumberList } = this.state
        let newList = []
        trackingNumberList.map(t => {
            if (t !== trackingNumber) {
                newList.push(t)
            }
        })
        this.setState({
            trackingNumberList: newList,
        })
    }

    updateOrderStatus = async () => {
        const { trackingNumberList, orderStatus } = this.state
        const now = new Date();
        const offsetInMilliseconds = 6.5 * 60 * 60 * 1000;
        now.setTime(now.getTime() + offsetInMilliseconds);
        trackingNumberList?.forEach(trackingNumber => {
            let orderObj = {
                status: orderStatus,
                tracking_id: trackingNumber,
                timestamp: now.toISOString()
            }
            ApiHandler({ url: Api.webhook_update_order_status, method: HTTP_METHOD.POST, mediaType: MEDIA_TYPE, JSON, requestData: orderObj })
                .then(response => { })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => { })
        })
        this.setState({ orderStatus: null, trackingNumberList: [] })
    }

    updateWithPhotoUrl = (formData) => {
        const now = new Date();
        const offsetInMilliseconds = 6.5 * 60 * 60 * 1000;
        now.setTime(now.getTime() + offsetInMilliseconds);
        this.setState({ isLoading: true })

        let orderObj = {
            "tracking_id": formData.trackingNumber,
            "timestamp": now.toISOString(),
            "status": "Delivered",
            "delivery_information": {
                "state": "Received by Customer",
                "left_in_safe_place": true,
                "proof": {
                    "signature_uri": "",
                    "image_uris": [formData.photoUrl],
                }
            }

        }
        ApiHandler({ url: Api.webhook_update_order_status, method: HTTP_METHOD.POST, mediaType: MEDIA_TYPE, JSON, requestData: orderObj })
            .then(response => { })
            .catch(error => { })
            .finally(() => {
                this.updateRef?.current.setFieldsValue({ trackingNumber: null, photoUrl: null })
                this.setState({ isLoading: false })
            })
    }
    render() {
        const { trackingNumberList, orderStatusList, orderStatus, isLoading } = this.state
        return (
            <Row gutter={[16, 6]}>
                <Col span={12}>
                    <TitleLevel3 label='Order Status Update' />
                </Col>
                <Col span={12} style={{ textAlign: 'end' }}>
                    <Button onClick={() => this.updateOrderStatus()}>Update Order Status</Button>
                </Col>
                <Col span={24}>
                    <Select
                        className="custom-selector-gray"
                        value={orderStatus}
                        onChange={(value) => this.setState({ orderStatus: value })}
                        size="large"
                        allowClear
                        style={{ width: '100%' }}
                        placeholder="Select order status"
                        options={orderStatusList}
                    />

                </Col>
                <Col span={24}>
                    <Form
                        layout="vertical"
                        ref={this.inputRef}
                        initialValues={{ searchBox: '' }}
                    >

                        <Form.Item name="searchBox">
                            <Input
                                style={{ backgroundColor: '#f1f1f1' }}
                                onPressEnter={this.onPressEnter}
                                placeholder="Paste or type the tracking number"
                            />
                        </Form.Item>
                        <Row>
                            <Col span={24} style={{ marginBottom: 10 }}>
                                {
                                    trackingNumberList && trackingNumberList.map(tracking =>
                                        <span style={{
                                            display: 'inline-flex',
                                            alignItems: 'center',
                                            background: '#f1f1f1',
                                            width: 'fit-content',
                                            padding: '6px 15px',
                                            borderRadius: 100,
                                            marginRight: 10,
                                            marginBottom: 5
                                        }}>
                                            <span>{tracking}</span>
                                            <div style={{ marginLeft: 10, marginTop: 5 }} onClick={() => this.removeFromList(tracking)}><SvgCrossOutlined width={20} height={20} color={'red'} /></div>
                                        </span>
                                    )
                                }
                            </Col>
                        </Row>

                    </Form>
                </Col>

                <Col span={24}>
                    <Divider />
                </Col>
                <Col span={24}>
                    <TitleLevel3 label='Update order delivered status with photo url' />
                </Col>
                <Col span={24}>
                    <Divider />
                </Col>
                <Col span={24}>
                    <Form
                        layout="vertical"
                        ref={this.updateRef}
                        initialValues={{ searchBox: '' }}
                        onFinish={this.updateWithPhotoUrl}
                    >
                        <Form.Item name="trackingNumber">
                            <Input
                                style={{ backgroundColor: '#f1f1f1' }}
                                placeholder="Enter tracking number"
                            />
                        </Form.Item>
                        <Form.Item name="photoUrl">
                            <Input
                                style={{ backgroundColor: '#f1f1f1' }}
                                placeholder="Enter photo url"
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" loading={isLoading} >Update Order Status</Button>
                        </Form.Item>


                    </Form>
                </Col>
            </Row>
        )
    }


}
export default compose(withRouter)(UpdateOrderStatusPage)