
const SearchFilter = (filterData) => {
    
    const searchParams = new URLSearchParams()

    if (filterData) {
        searchParams.append('page', 1);
        for (let key in filterData) {
            if (key !== 'page' && filterData[key] !== null && filterData[key] !== undefined && filterData[key] !== 'null' && filterData[key] !== 'undefined' && filterData[key] !== '') {
                searchParams.append(key, filterData[key])
            }
        }
    }

    let newUrl = `${window.location.pathname}?${searchParams.toString()}`;

    return newUrl
}

export default SearchFilter;