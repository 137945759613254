import { createSlice } from '@reduxjs/toolkit'

export const LocationSlice = createSlice({
    name: 'location',
    initialState: {
        division_collection: [],
        township_collection_map: [],
        treeData: []
    },
    reducers: {
        updateDivisionList: (state, action) => {
            state.division_collection = action.payload
        },
        updateTownShipList: (state, action) => {
            state.township_collection_map = action.payload
        },
        updateTreeData: (state, action) => {
            state.treeData = action.payload
        },
        clearLocation: (state) => {
            state.division_collection = []
            state.township_collection_map = []
            state.treeData = []
        }
    },
})

export const { updateDivisionList, updateTownShipList, updateTreeData,clearLocation } = LocationSlice.actions
export const divisionCollection = (state) => state.location.division_collection
export const townshipCollectionMap = (state) => state.location.township_collection_map
export const treeData = (state) => state.location.treeData
export default LocationSlice.reducer