import { Button, Col, Input, Row } from 'antd';
import React, { Component } from 'react';
import NJVTable from "../../components/NJVTable";
import { TitleLevel3 } from '../../components/general-component';
import Api from '../../network/api';
import SearchFilter from '../../components/searchFilter';
import { ApiHandler } from '../../network/network-manager';
import { MEDIA_TYPE } from '../../network/mediaType';
import { HTTP_METHOD } from '../../network/httpMethod';

export default class ShipperLoyaltyPoint extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filterData: null,
            isSyncing: false,
            currentId: null,
        }
    }

    updateFilterData = (key, value) => {
        this.setState(prev => ({
            filterData: {
                ...prev.filterData,
                [key]: value
            }
        }))
    }

    handleSync = async (value) => {
        this.setState({
            isSyncing: true
        })
        const requestParams = {
            'shipperIds': value
        }

        await ApiHandler({ url: Api.loyalty_sync, method: HTTP_METHOD.PUT, mediaType: MEDIA_TYPE.JSON, requestParams })
            .then((response) => {
                console.log(response);
            }).catch((error) => { })
            .finally(() => {
                this.setState({
                    isSyncing: false
                })
            })
    }
    search = () => {
        const { filterData } = this.state;

        const newUrl = SearchFilter(filterData);
        window.history.pushState({}, '', newUrl)
        this.reFetch(1)
    }

    render() {
        const { filterData, isSyncing, currentId } = this.state

        const columns = [
            {
                title: 'Business Name',
                key: 'businessName',
                dataIndex: 'businessName'
            },
            {
                title: 'Phone Number',
                key: 'phoneNumber',
                dataIndex: 'phoneNumber'
            },
            {
                title: 'Current Month’s Level',
                key: 'currentMonthLevel',
                dataIndex: 'currentMonthLevel',
                align: 'center',
                render: (value) => <p>{value ? value : 'N/A'}</p>
            },
            {
                title: 'Previous Month’s Level',
                key: 'previousMonthLevel',
                dataIndex: 'previousMonthLevel',
                align: 'center',
                render: (value) => <p>{value ? value : 'N/A'}</p>
            },
            {
                title: 'Current Month Point',
                key: 'currentMonthPoint',
                dataIndex: 'currentMonthPoint',
                align: 'center',
                render: (value) => <p>{value ? value : 'N/A'}</p>
            },
            {
                title: 'Previous Month Point',
                key: 'previousMonthPoint',
                dataIndex: 'previousMonthPoint',
                align: 'center',
                render: (value) => <p>{value ? value : 'N/A'}</p>
            },
            {
                title: 'Sync',
                key: 'action',
                align: 'center',
                render: (value, item) => <Button type="primary" style={{ borderRadius: 10 }}
                    loading={currentId === item.userId ? isSyncing : false}
                    onClick={() => {
                        this.handleSync(item?.userId)
                        this.setState({
                            currentId: item?.userId
                        })
                    }}>Sync</Button>
            }
        ]

        return (
            <Row style={{ alignItems: 'center' }} gutter={[20, 20]}>
                <Col span={24} style={{ display: 'flex', alignItems: 'center' }}>
                    <TitleLevel3 label={"Shipper Loyalty Point"} />
                </Col>
                <Col span={6}>
                    <Input
                        onChange={(event) => this.updateFilterData('businessName', event.target.value)}
                        style={{ background: '#f1f1f1', height: 40, marginRight: 8 }}
                        placeholder="Search by Business Name"
                        autoComplete='off'
                    />
                </Col>
                <Col span={6}>
                    <Input
                        onChange={(event) => this.updateFilterData('phoneNumber', event.target.value)}
                        style={{ background: '#f1f1f1', height: 40, marginRight: 8 }}
                        placeholder="Search by Phone Number"
                        autoComplete='off'
                    />
                </Col>
                <Col span={6} />
                <Col span={6}>
                    <Button onClick={() => this.search()} type="primary" style={{ borderRadius: 10, height: 40, width: '100%' }}>Search</Button>
                </Col>
                <Col span={24}>
                    <NJVTable
                        columns={columns}
                        apiUrl={Api.shipper_loyalty}
                        params={filterData}
                        handleRefetch={fetchData => this.reFetch = fetchData}
                    />
                </Col>
            </Row>
        )
    }
}
