import { compose } from "@reduxjs/toolkit";
import React from "react";
import withRouter from "../../network/with-router";
import { ApiGet } from "../../network/network-manager";
import Api from "../../network/api";
import { Breadcrumb, Col, Input, Row, Skeleton } from "antd";
import { TitleLevel3 } from "../../components/general-component";

class LogPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            data: [],
            tempData: [],
            isDataFetching: true
        }
    }

    componentDidMount = () => {
        this.fetchData()
    }

    fetchData = async () => {
        try {
            let list = []
            const response = await ApiGet(Api.driver_activity, null, this.props)
            if (response) {
                const objectList = Object.entries(response).map(([key, value]) => ({
                    id: key,
                    log: value,
                }));
                if (objectList) {
                    objectList.map(obj => {
                        if (obj.log) {
                            let lastindex = obj.log.length - 1
                            const tt = obj.log.substring(4, lastindex);
                            const parts = tt.split(/,\s+/);
                            const jsonObject = parts.reduce((acc, part) => {
                                const [key, value] = part.split('=');
                                acc[key] = value;
                                return acc;
                            }, {});

                            list.push(jsonObject);

                        }

                    })
                }
            }
            this.setState({
                data: list,
                tempData: list
            })

        } catch (error) {

        }

        this.setState({
            isDataFetching: false
        })
    }

    //filter
    handleFilterChange = (e) => {
        const { data, tempData } = this.state
        const value = e.target.value;
        let filteredList = tempData.filter((item) =>
            item.message.toLowerCase().includes(value.toLowerCase())
        );



        this.setState({
            data: filteredList
        })
    };

    render() {
        const { data, isDataFetching } = this.state
        return (
            <Row gutter={[8, 8]}>

                <Col span={24}>
                    {/* <Breadcrumb
                                    items={[
                                        {
                                            title: 'Home',
                                        },
                                        {
                                            title: 'Log'
                                        }

                                    ]}
                                /> */}
                    <TitleLevel3 label={"Log"} />
                </Col>
                <Col span={24}>
                    <Input
                        onChange={this.handleFilterChange}
                        style={{ width: 200, background: '#f1f1f1', height: 40 }}
                        placeholder="Search"
                    />
                </Col>
                <Col span={24}>
                    {
                        isDataFetching ?
                            <Skeleton active />
                            :
                            <>
                                {
                                    data && data.map(item => {
                                        return <div style={{ backgroundColor: '#f1f1f1', padding: 12, borderRadius: 8, marginBottom: 10 }}>
                                            <div>{item.message}</div>
                                        </div>
                                    })
                                }
                            </>
                    }

                </Col>
            </Row>
        )
    }
}

export default compose(withRouter)(LogPage)