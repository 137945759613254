import { compose } from "@reduxjs/toolkit";
import { Breadcrumb, Col, Row, Spin, Upload, message } from "antd";
import React from "react";
import withRouter from "../../network/with-router";

import {
    InboxOutlined
} from '@ant-design/icons';
import { uploadAndDownload } from "../../network/network-manager";
import Api from "../../network/api";

const { Dragger } = Upload;
class OrderPriceUpdate extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isUploading: false
        }
    }

    handleFileUpload = async (file) => {
        try {
            this.setState({
                isUploading: true
            })
            let formData = new FormData()
            formData.append('file', file);
            await uploadAndDownload(Api.order_price_bulk_update, formData, "invalid-tracking-list")
            message.success("Successfully Uploaded")

        } catch (error) {
            if (error && error.response.status === 500) {
                message.destroy()
                message.error("Something went wrong")
            }
        }
        this.setState({
            isUploading: false
        })
    };

    render() {
        const { isUploading } = this.state
        const draggerProps = {
            name: 'file',
            multiple: false,
            showUploadList: false,
            beforeUpload: () => false,
            onChange: (info) => {
                const { file } = info;
                this.handleFileUpload(file);
            },
        };
        return (
            <Row>
                <Col span={24}>
                    <Breadcrumb
                        items={[
                            {
                                title: 'Home',
                            },
                            {
                                title: 'Order Price Bulk Update'
                            }

                        ]}
                    />
                </Col>
                <Col span={24}>
                    <Dragger
                        disabled={isUploading}
                        {...draggerProps}
                        accept={[".xlsx", ".xls"]}
                        maxCount={1}
                        style={{ marginTop: 15 }}>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-hint">
                            {
                                isUploading ?
                                    <div>
                                        <Spin /> <span>uploading...</span>
                                    </div>
                                    :
                                    <span>Upload Excel File Only</span>
                            }

                        </p>
                    </Dragger>
                </Col>
            </Row>
        )
    }
}

export default compose(withRouter)(OrderPriceUpdate)