import { EditOutlined } from "@ant-design/icons";
import { compose } from "@reduxjs/toolkit";
import { Button, Col, Row, Tag } from 'antd';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import NJVTable from "../../../components/NJVTable";
import { TitleLevel3 } from '../../../components/general-component';
import Api from '../../../network/api';
import withRouter from "../../../network/with-router";
import CustomPath from '../../../routes/custom-path';

class CustomerPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {
        const columns = [
            {
                title: 'Full Name',
                key: 'fullName',
                dataIndex: 'fullName',
                render: (_, record) => <>{record?.fullName} <br />{record?.uniqueId}</>
            },
            {
                title: 'Phone Number',
                key: 'phoneNumber',
                dataIndex: 'phoneNumber'
            },
            {
                title: 'Address',
                key: 'address',
                dataIndex: 'address',
                render: (_, record) => <>{record.address && (
                    <>
                        {record?.address} <br />
                    </>
                )
                }{record?.township?.division?.name}/{record?.township?.name}</>
            },
            {
                title: 'Status',
                key: 'active',
                render: (_, { userStatus }) => (
                    <>
                        {
                            (
                                userStatus === "ACTIVE" ? <Tag color="#87d068">{userStatus}</Tag> : <Tag color="#cd201f">{userStatus}</Tag>
                            )
                        }
                    </>
                ),
            },
            {
                title: 'Action',
                key: 'action',
                dataIndex: '',
                textAlign: 'center',
                width: 60,
                render: (customer) => (
                    <Link to={`${CustomPath.customer_update}/${customer.id}`}><Button size={'middle'} type="primary" shape="circle"><EditOutlined /></Button></Link>
                ),
            },
        ]

        return (
            <Row style={{ alignItems: 'center' }} gutter={[20, 20]}>
                <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
                    <TitleLevel3 label={"Customer"} />
                </Col>
                <Col span={24}>
                    <NJVTable
                        columns={columns}
                        apiUrl={Api.customer_filter}
                    />
                </Col>
            </Row>
        )
    }
}

export default compose(withRouter)(CustomerPage)