import { Button, Col, Input, Row, Table, message } from "antd";
import React from "react";
import { compose } from "redux";
import { Constant } from "../../core/constant";
import Api from "../../network/api";
import { ApiFilter, ApiGet } from "../../network/network-manager";
import withRouter from "../../network/with-router";

const labelStyle = { fontSize: 14, fontWeight: '500', color: 'gray' }
class SelectShipper extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isSearchingShipper: false,
            selectedShipper: null,
            columns: [
                {
                    title: 'Shipper Name',
                    key: 'fullName',
                    render: (shipper) => (
                        <span>{shipper.firstName} {shipper.lastName}</span>
                    ),
                },
                {
                    title: 'Phone Number',
                    dataIndex: 'phoneNumber',
                    key: 'phoneNumber'
                },
                {
                    title: 'Action',
                    key: 'action',
                    dataIndex: '',
                    textAlign: 'center',
                    width: 100,
                    render: (shipper) => (
                        <Button size={'middle'} type="primary" shape="square" onClick={() => this.handleSelect(shipper)}>Select</Button>

                    ),
                },
            ],
            pageSize: Constant.pageSize,
            totalPagination: 0,
            page: 1,
            searchName: '',
            searchPhone: '',
            searchRole: '',
            searchUniqueId: '',
            openModal: false,
            openCreateModal: false,
            openUpdateModal: false,
            activeStatus: true,
            updateForm: {},
            updateDivision: {},
            roles: [],

        }
    }

    componentDidMount() {
        // this.fetchFromCache()
    }

    fetchFromCache = async () => {
        const { selectedShipperInfo } = this.props
        if (selectedShipperInfo && selectedShipperInfo.id) {
            const shipperAddress = await this.fetchUserAddress(selectedShipperInfo.id)
            const { onSelect } = this.props
            if (onSelect) {
                onSelect(selectedShipperInfo, shipperAddress)
            }
            this.setState({
                selectedShipper: selectedShipperInfo
            })
        }

    }

    //fetch data
    fetchData = async (pageNumber, value) => {
        this.setState({
            isSearchingShipper: true
        })
        const { page, searchName, searchPhone, pageSize, searchUniqueId } = this.state
        try {
            var params = {
                name: searchName,
                phone: searchPhone,
                uniqueId: searchUniqueId,
                userStatus: 'ACTIVE',
                size: pageSize,
                page: pageNumber ? pageNumber - 1 : page - 1
            }
            const response = await ApiFilter(Api.shipper_filter, params, this.props)
            if (response && response.content) {
                this.setState(prevState => ({
                    data: response.content,
                    totalPagination: response.totalElements,
                    page: pageNumber ? pageNumber : page
                }));
            }
        } catch (error) {
        }
        this.setState({
            isSearchingShipper: false
        })
    }



    changeSearchName = (event) => {
        this.setState({
            searchName: event.target.value
        })
    }

    changeSearchPhone = (event) => {
        this.setState({
            searchPhone: event.target.value
        })
    }


    changeSearchUniqueId = (event) => {
        this.setState({
            searchUniqueId: event.target.value
        })
    }


    search = () => {
        const { page, searchName, searchPhone, searchUniqueId, searchRole } = this.state
        if (searchName !== '' || searchPhone !== '' || searchRole !== '' || searchUniqueId !== '') {
            if (searchName.length > 2 || searchPhone.length > 2 || searchRole.length > 2 || searchUniqueId.length > 2) {
                this.fetchData(page)
            } else {
                message.error("Please enter at least three words")
            }
        } else {
            message.error("Please enter at least one field")
        }
    }
    handlePaginationChange = (pageNumber, pageSize) => {
        this.fetchData(pageNumber);
    }

    handleSelect = async (value) => {
        const { onSelect } = this.props
        if (onSelect) {
            onSelect(value)
        }
        this.setState({
            selectedShipper: value
        })
    }

    // handleSelect = async (value) => {
    //     try {
    //         const shipperAddress = await this.fetchUserAddress(value.id)
    //         const { onSelect } = this.props
    //         if (onSelect) {
    //             onSelect(value, shipperAddress)
    //         }
    //         // this.props.dispatch(upateSelectedShipper(value))
    //         this.setState({
    //             selectedShipper: value
    //         })
    //     } catch (error) {
    //     }


    // }

    // fetchUserAddress = async (id) => {
    //     let userAddress = null
    //     try {
    //         if (id) {
    //             const response = await ApiGet(Api.shipper_address, id, this.props)
    //             if (response) {
    //                 userAddress = response
    //             }
    //         }
    //     } catch (error) {
    //     }
    //     return userAddress
    // }

    confirmShipperRemove = () => {
        const { confirmRemove } = this.props
        if (confirmRemove) {
            confirmRemove()
        }
        this.setState({
            selectedShipper: null
        })
    }

    requestConfirmRemove = () => {
        const { onRemove } = this.props
        if (onRemove) {
            onRemove()
        }
    }
    render() {
        const { columns, data, totalPagination, page, pageSize, selectedShipper, isSearchingShipper } = this.state
        const { removeStatus } = this.props

        if (removeStatus) {
            this.confirmShipperRemove()
        }
        return (
            <>

                <Row gutter={16}>
                    <Col span={6}>
                        <Input
                            onChange={this.changeSearchName}
                            style={{ height: 40, marginRight: 8 }}
                            placeholder="Shipper Name"
                        />
                    </Col>
                    <Col span={6}>
                        <Input
                            onChange={this.changeSearchPhone}
                            style={{ height: 40, marginRight: 8 }}
                            placeholder="Phone Number"
                        />
                    </Col>
                    <Col span={6}>
                        <Input
                            onChange={this.changeSearchUniqueId}
                            style={{ height: 40, marginRight: 8 }}
                            placeholder="Unique Id"
                        />
                    </Col>
                    <Col span={6} className="search">
                        <Button size="large" onClick={() => this.search()} type="primary" style={{ width: '100%' }} loading={isSearchingShipper}>Search</Button>
                    </Col>
                </Row>
                {
                    selectedShipper ?
                        <Row style={{ backgroundColor: 'white', marginTop: 10, padding: '7px 8px', borderRadius: 9, fontSize: 15, display: 'flex', alignItems: 'center' }}>
                            <Col span={6}>
                                <div style={labelStyle}>Shipper Name </div>
                                <span>{selectedShipper.fullName}</span>
                            </Col>
                            <Col span={6}>
                                <div style={labelStyle}>Phone Number</div>
                                <span>{selectedShipper.phoneNumber}</span>
                            </Col>
                            <Col span={12} style={{ textAlign: 'right' }}>
                                <Button type="primary" style={{ background: 'red' }} onClick={() => this.requestConfirmRemove()}>Remove</Button>
                            </Col>
                        </Row>
                        :
                        <>
                            {
                                data && data.length !== 0 ?
                                    <Row style={{ marginTop: 10 }}>
                                        <Col span={24}>
                                            <div>
                                                <Table
                                                    style={{ borderRadius: 10 }}
                                                    pagination={{
                                                        position: 'top' | 'bottom',
                                                        total: totalPagination,
                                                        current: page,
                                                        onChange: this.handlePaginationChange,
                                                        defaultPageSize: pageSize
                                                    }}
                                                    columns={columns} dataSource={data} /></div>
                                        </Col>
                                    </Row>
                                    :
                                    <></>
                            }
                        </>
                }


            </>
        );
    }
}

export default compose(withRouter)(SelectShipper)