import { compose } from "@reduxjs/toolkit";
import { Button, Col, Popconfirm, Row, Skeleton, Table, Tag } from "antd";
import React from "react";
import { TitleLevel3 } from "../../components/general-component";
import { Constant } from "../../core/constant";
import Api from "../../network/api";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import { ApiHandler } from "../../network/network-manager";
import withRouter from "../../network/with-router";
import { updateSos } from "../../redux/profile-slice";

class SOSPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isDataFetching: false,
            pageSize: Constant.pageSize,
            totalPagination: 0,
            page: 1,
            searchValue: [],
            openCreateModal: false,
            openUpdateModal: false,
            activeStatus: true,
            updateForm: {},
            filter_question: ''
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    //fetch data
    fetchData = async (pageNumber, value) => {
        const { page, searchValue, pageSize } = this.state
        this.setState({
            isDataFetching: true
        })
        try {
            var requestParams = {
                size: pageSize,
                name: value ? value : searchValue,
                page: pageNumber ? pageNumber - 1 : page - 1
            }
            const response = await ApiHandler({url: Api.sos_filter,method: HTTP_METHOD.GET,mediaType: MEDIA_TYPE.JSON, requestParams})
            if (response && response.content) {
                this.setState(prevState => ({
                    data: response.content,
                    totalPagination: response.totalElements,
                    page: pageNumber ? pageNumber : page
                }));
            }
        } catch (error) {
        }
        this.setState({
            isDataFetching: false,
            searchValue: value ? value : searchValue
        })
    }

    search = async () => {
        const { filter_question, page, pageSize } = this.state
        this.setState({
            isDataFetching: true
        })
        try {
            var requestParams = {
                size: pageSize,
                question: filter_question,
                page: page - 1
            }
            const response = await ApiHandler({url: Api.sos_filter,method: HTTP_METHOD.GET,mediaType: MEDIA_TYPE.JSON, requestParams})

            if (response && response.content) {
                this.setState(prevState => ({
                    data: response.content,
                    totalPagination: response.totalElements,
                    page: page
                }));
            }
        } catch (error) {
        }
        this.setState({
            isDataFetching: false
        })
    }

    solveSOS = async (sos) => {
        const { data } = this.state
        try {
            sos = { ...sos, status: 'CONFIRM' }
            const response = await ApiHandler({url: Api.sos,method: HTTP_METHOD.PUT,mediaType: MEDIA_TYPE.JSON,requestData: sos})
            if (response) {
                this.fetchSosCount()
                let list = []
                data.map(sosItem => {
                    if (sosItem.id === sos.id) {
                        sosItem.status = 'CONFIRM'
                    }
                    list.push(sosItem)
                })
                this.setState({
                    data: list
                })
            }

        } catch (error) {
        }
    }

    fetchSosCount = async () => {
        try {
            const res = await ApiHandler({url: Api.sos_count,method: HTTP_METHOD.GET,mediaType: MEDIA_TYPE.JSON})
            if (res) {
                this.props.dispatch(updateSos(res.count))
            }
        } catch (error) {
        }
    }

    handlePaginationChange = (pageNumber, pageSize) => {
        this.fetchData(pageNumber);
    };

    changeSearchName = (event) => {
        this.setState({
            filter_question: event.target.value
        })
    }


    render() {
        const { data, totalPagination, page, pageSize, isDataFetching } = this.state
        const getRowClassName = (record, index) => {
            return index % 2 === 0 ? 'blue-row' : 'black-row';
        };
        const columns = [
            {
                title: 'No.',
                key: 'index',
                render: (value, item, index) => <span>{(page - 1) * pageSize + index + 1}</span>,
            },
            {
                title: 'Driver Info',
                key: 'question',
                render: (sos) => (
                    <>
                        {
                            sos.driver ?
                                <>
                                    <div>{sos.driver.fullName}</div>
                                    <div>{sos.driver.phoneNumber}</div>
                                </>
                                :
                                <></>
                        }

                    </>
                ),
            }, {
                title: 'Location',
                key: 'location',
                render: (sos) => (
                    <div>{sos.latitude},{sos.longitude}</div>
                ),
            }, {
                title: 'Assign Township',
                key: 'assignTownship',
                render: (sos) => (
                    <>
                        {
                            sos.driver && sos.driver.assignTownship ?
                                <span>{sos.driver.assignTownship.name}/{sos.driver.assignTownship.division.name}</span>
                                :
                                <></>
                        }
                    </>
                ),
            },
            //  {
            //     title: 'Status',
            //     dataIndex: 'status',
            //     key: 'status',
            // },
            {
                title: 'Action',
                key: 'action',
                render: (sos) => (
                    <>
                        {
                            sos.status === 'PENDING' ?
                                <div>
                                    <Popconfirm
                                        placement="bottomRight"
                                        title={"Confrimation"}
                                        description={"Are you sure to solve this ticket?"}
                                        okText="Yes"
                                        cancelText="No"
                                        onConfirm={() => this.solveSOS(sos)}
                                    >
                                        <Button size={'middle'} type="primary" shape="square">Solve</Button>
                                    </Popconfirm>
                                </div>
                                :
                                <></>
                        }
                        {
                            sos.status === 'CONFIRM' ?
                                <Tag color="#87d068">Solved</Tag>

                                :
                                <></>
                        }
                    </>
                ),
            }
        ]
        return (
            <>
                <Row style={{ marginBottom: "20px" }}>
                    <Col span={1} />
                    <Col span={11} style={{ display: 'flex', alignItems: 'center' }}>
                        <TitleLevel3 label={"SOS"} />
                    </Col>
                </Row>
                {/* <Row style={{ marginBottom: 30 }}>
                    <Col span={1} />
                    <Col span={22} className="custom-btn">
                        <Input
                            onChange={this.changeSearchName}
                            style={{ width: 200, background: '#f1f1f1', height: 40, marginRight: 8 }}
                            placeholder="Search by question"
                        />
                        <Button size="large" onClick={() => this.search()} type="primary" >Search</Button>

                    </Col>
                </Row> */}
                <Row justify='center'>
                    <Col span={1}></Col>
                    <Col span={22}>
                        {
                            isDataFetching ?
                                <Skeleton active />
                                :
                                <Table
                                    style={{ borderRadius: 10 }}
                                    pagination={{
                                        position: 'top' | 'bottom',
                                        total: totalPagination,
                                        current: page,
                                        onChange: this.handlePaginationChange,
                                        defaultPageSize: pageSize
                                    }}
                                    columns={columns} dataSource={data}
                                    rowClassName={getRowClassName} />
                        }
                    </Col>
                    <Col span={1}></Col>
                </Row>

            </>
        );
    }
}


export default compose(withRouter)(SOSPage)