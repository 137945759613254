import { compose } from '@reduxjs/toolkit'
import { Button, Col, DatePicker, Form, Modal, Row, Space, Tag } from 'antd'
import React, { Component } from 'react'
import NJVTable from '../../components/NJVTable'
import { NJVInput } from '../../components/core-component'
import FetchSearchUrl from '../../components/fetchSearchUrl'
import { TitleLevel3 } from '../../components/general-component'
import SearchFilter from '../../components/searchFilter'
import withRouter from '../../network/with-router'
import Api from '../../network/api'
import { NumberOnlyWithLimit } from '../../components/validator'

class WalletsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filterData: {},
            isOpenModal: false,
        }
    }

    componentDidMount() {
        const { filterData } = FetchSearchUrl()
        this.setState({
            filterData
        })
    }

    search = () => {
        const { filterData } = this.state;

        const newUrl = SearchFilter(filterData);
        window.history.pushState({}, '', newUrl)
        this.reFetch(1);
    }

    handleFilter = (key, value) => {
        let { filterData } = this.state

        filterData = {
            ...filterData,
            [key]: value
        }
        this.setState({
            filterData
        })
    }

    render() {
        const { filterData, isOpenModal } = this.state

        const columns = [
            {
                title: 'Name',
                key: 'fullName',
                dataIndex: 'fullName',
                render: (_, record) => <>{record.fullName} <Tag color='#007bff'>{record.userType}</Tag></>
            },
            {
                title: "Wallet's Balance",
                key: 'balance',
                dataIndex: 'balance'
            },
            {
                title: 'Action',
                key: 'action',
                width: 300,
                render: (_, record) => (
                    <Space>
                        <Button type="dashed" onClick={() => { }}>History</Button>
                        <Button type="primary" onClick={() => this.setState({ isOpenModal: true })}>Deposit</Button>
                    </Space>
                )
            }
        ]

        return (
            <>
                <Modal footer={null} open={isOpenModal} onCancel={() => this.setState({ isOpenModal: false })} title={"Deposit to wallet"}>
                    <Form layout='vertical' style={{ marginTop: 30 }}>
                        <Form.Item name="amount" rules={[{ validator: (_, value) => NumberOnlyWithLimit(value, 6) }]}>
                            <NJVInput type='text' name="amount" placeholder="Enter Amount" size="large" style={{ background: '#f1f1f1', height: 40, marginRight: 8 }} />
                        </Form.Item>
                        <Form.Item style={{ textAlign: 'right' }}>
                            <Button type="primary" >Deposit</Button>
                        </Form.Item>
                    </Form>
                </Modal>
                <Row gutter={[16, 16]}>
                    <Col span={24} style={{ display: 'flex', alignItems: 'center' }}>
                        <TitleLevel3 label={"Wallets"} />
                    </Col>
                    <Col span={24}>
                        <Row gutter={[16, 16]} style={{ marginBottom: 20 }}>
                            <Col span={6}>
                                <NJVInput
                                    value={filterData?.fullName}
                                    onChange={(e) => this.handleFilter('fullName', e.target.value)}
                                    style={{ background: '#f1f1f1', height: 40, marginRight: 8 }}
                                    placeholder="Sender Name"
                                    name="senderName"
                                    autoComplete='off'
                                />
                            </Col>
                            <Col span={6}>
                                <NJVInput
                                    value={filterData?.employeeId}
                                    onChange={(e) => this.handleFilter('employeeId', e.target.value)}
                                    style={{ background: '#f1f1f1', height: 40, marginRight: 8 }}
                                    placeholder="Employee ID"
                                    name="employeeId"
                                    autoComplete='off'
                                />
                            </Col>
                            <Col span={6}>
                                <Button onClick={() => this.search()} type="primary" style={{ height: 40, width: 200, marginLeft: 10 }}>Search</Button>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <NJVTable
                            columns={columns}
                            handleRefetch={fetchData => this.reFetch = fetchData}
                            params={filterData}
                            apiUrl={Api.wallets}
                        />
                    </Col>
                </Row>
            </>
        )
    }
}

export default compose(withRouter)(WalletsPage)