import { EditOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row, Skeleton, Space, Table, Tag } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { NJVAddButton } from "../../../components/core-component";
import FetchSearchUrl from "../../../components/fetchSearchUrl";
import { TitleLevel3 } from "../../../components/general-component";
import SearchFilter from "../../../components/searchFilter";
import SearchPagination from "../../../components/searchPagination";
import { Constant } from "../../../core/constant";
import Api from "../../../network/api";
import { ApiFilter } from "../../../network/network-manager";
import withRouter from "../../../network/with-router";
import CustomPath from "../../../routes/custom-path";

class AdminPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pageSize: Constant.pageSize,
            totalPagination: 0,
            page: 1,
            searchName: '',
            searchPhone: '',
            openModal: false,
            openCreateModal: false,
            openUpdateModal: false,
            activeStatus: true,
            updateForm: {},
            updateDivision: {},
            isDataFetching: false
        }
        this.inputRef = React.createRef();
        this.updateRef = React.createRef();
    }

    componentDidMount() {
        const { filterData, urlPage } = FetchSearchUrl()
        const { searchName, searchPhone } = this.state;

        if (urlPage) {
            this.setState({
                searchName: filterData?.name || searchName,
                searchPhone: filterData?.phone || searchPhone,
            }, () => this.fetchData(urlPage))
        }
    }

    fetchData = async (pageNumber) => {
        const { page, searchName, searchPhone, pageSize } = this.state
        this.setState({
            isDataFetching: true
        })
        try {
            var params = {
                name: searchName,
                phone: searchPhone,
                size: pageSize,
                page: pageNumber ? pageNumber - 1 : page - 1
            }
            const response = await ApiFilter(Api.admin_filter, params, this.props)
            if (response && response.content) {
                this.setState({
                    data: response.content,
                    totalPagination: response.totalElements,
                    page: pageNumber ? pageNumber : page
                })
            }
        } catch (error) {
        }
        this.setState({
            isDataFetching: false
        })
    }

    changeSearchName = (event) => {
        this.setState({
            searchName: event.target.value
        })
    }

    changeSearchPhone = (event) => {
        this.setState({
            searchPhone: event.target.value
        })
    }

    search = () => {
        const { searchName, searchPhone } = this.state;
        const filterData = {
            name: searchName,
            phone: searchPhone
        }

        const newUrl = SearchFilter(filterData);

        window.history.pushState({}, '', newUrl)
        this.fetchData(1)
    }

    handlePaginationChange = (pageNumber) => {
        const newUrl = SearchPagination(pageNumber)
        window.history.pushState({}, '', newUrl)
        this.fetchData(pageNumber);
    };


    render() {
        const { data, totalPagination, page, pageSize, isDataFetching, searchName, searchPhone } = this.state
        const currentSearchUrl = window.location.search;

        const getRowClassName = (record, index) => {
            return index % 2 === 0 ? 'blue-row' : 'black-row';
        };


        const columns = [
            {
                title: 'No.',
                key: 'index',
                render: (value, item, index) => <span>{(page - 1) * pageSize + index + 1}</span>,
                width: 5
            },
            {
                title: 'Full Name',
                key: 'fullName',
                render: (admin) => (
                    <span>{admin.firstName} {admin.lastName}</span>
                ),
            },
            {
                title: 'Phone Number',
                dataIndex: 'phoneNumber',
                key: 'phoneNumber'
            },
            {
                title: 'Date Of Birth',
                dataIndex: 'dateOfBirth',
                key: 'dateOfBirth'
            },
            {
                title: 'Gender',
                dataIndex: 'gender',
                key: 'gender'
            },
            {
                title: 'Status',
                key: 'active',
                render: (_, { userStatus }) => (
                    <>
                        {
                            (
                                userStatus === "ACTIVE" ? <Tag color="#87d068">{userStatus}</Tag> : <Tag color="#cd201f">{userStatus}</Tag>
                            )
                        }
                    </>
                ),

            },
            {
                title: 'Action',
                key: 'action',
                dataIndex: '',
                textAlign: 'center',
                width: 60,
                render: (admin) => (
                    <Space>
                        <Link to={`${CustomPath.admin_update}/${admin.id}`} state={{ route: `${CustomPath.admin}${currentSearchUrl}` }}><Button size={'middle'} type="primary" shape="circle"><EditOutlined /></Button></Link>
                        {/* <Button size={'middle'} type="primary" shape="circle" onClick={() => this.deleteAdmin(admin.id)}><DeleteOutlined /></Button> */}
                    </Space>
                ),
            },
        ]

        return (

            <Row style={{ alignItems: 'center' }} gutter={[20, 20]}>
                <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
                    <TitleLevel3 label={"Admin"} />
                </Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                    <Link to={CustomPath.admin_create}><NJVAddButton /></Link>
                </Col>
                <Col span={24}>
                    <Input
                        value={searchName}
                        onChange={this.changeSearchName}
                        style={{ width: 200, background: '#f1f1f1', height: 40, marginRight: 8 }}
                        placeholder="Search by name"
                    />
                    <Input
                        value={searchPhone}
                        onChange={this.changeSearchPhone}
                        style={{ width: 200, background: '#f1f1f1', height: 40, marginRight: 8 }}
                        placeholder="Search by phone"
                    />
                    <Button onClick={() => this.search()} type="primary" style={{ borderRadius: 10, height: 40 }}>Search</Button>
                </Col>
                <Col span={24}>
                    {
                        isDataFetching ?
                            <Skeleton active />
                            :
                            <Table
                                // className="custom-table"
                                style={{ borderRadius: 10 }}
                                pagination={{
                                    position: 'top' | 'bottom',
                                    total: totalPagination,
                                    current: page,
                                    onChange: this.handlePaginationChange,
                                    defaultPageSize: pageSize,
                                    showSizeChanger: false
                                }}
                                columns={columns}
                                dataSource={data}
                                rowClassName={getRowClassName}
                            />
                    }
                </Col>
            </Row>
        );
    }
}

export default compose(withRouter)(AdminPage)