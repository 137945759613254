import { compose } from "@reduxjs/toolkit";
import { Col, Row, Skeleton, Table, Tag } from "antd";
import dayjs from 'dayjs';
import React from "react";
import { Constant } from "../../core/constant";
import Api from "../../network/api";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import { ApiHandler } from "../../network/network-manager";
import withRouter from "../../network/with-router";

class DriverAwbInventoryHistory extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            data: [],
            totalPagination: 0,
            pageSize: Constant.pageSize,
            page: 0,
            isDataFetching: false
        }
    }

    componentDidMount() {
        this.fetchData(0)
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.refresh !== this.props.refresh) {
            if (this.props.refresh) {
                this.setState({ page: 0 }, () => this.fetchData())

            }
        }

    }

    fetchData = async (pageNumber) => {
        this.setState({ isDataFetching: true })
        const { pageSize, page } = this.state
        try {
            var requestParams = {
                size: pageSize,
                page: pageNumber || page
            }
            const response = await ApiHandler({ url: Api.driver_inventory_awb_record, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.FORM_DATA, requestParams, specificId: this.props.id })
            if (response) {
                let temp = response?.content?.map(item => {
                    if (item?.createdDate) {
                        const updateTime = dayjs(item?.createdDate, 'YYYY-MM-DD hh:mm a');
                        item['createdDate'] = updateTime.format('YYYY-MM-DD hh:mm A')
                    }
                    return item
                })
                this.setState({
                    data: temp,
                    totalPagination: response.totalElements,
                    page: pageNumber || page,
                    isDataFetching: false,
                })
            }
        } catch (error) {
            this.setState({
                isDataFetching: false
            })
        }
    }


    handlePaginationChange = (pageNumber, pageSize) => {
        this.fetchData(pageNumber - 1);
    };


    render() {
        const { data, totalPagination, page, pageSize, isDataFetching } = this.state
        const getRowClassName = (record, index) => {
            return index % 2 === 0 ? 'blue-row' : 'black-row';
        };
        const columns = [
            {
                title: 'No.',
                key: 'index',
                render: (value, item, index) => <span>{page * pageSize + index + 1}</span>,
                width: 5
            },
            {
                title: 'Actual Stock',
                dataIndex: 'mainStock',
                key: 'mainStock',
                width: 150
            }, {
                title: 'Driver\'s AWB Stock',
                dataIndex: 'currentStock',
                key: 'currentStock',
                width: 150
            },
            {
                title: 'Different Stock',
                dataIndex: 'differentStock',
                key: 'differentStock',
                width: 150
            }, {
                title: 'Note',
                dataIndex: 'note',
                key: 'note'
            },
            {
                title: 'Created By',
                key: 'createdBy',
                render: (driver) => (

                    <>
                        <div>{driver.modifiedUserName}</div>
                        <div>{driver.createdDate}</div>
                    </>
                )
            }, {
                title: 'Status',
                key: 'status',
                width: 50,
                render: (_, { status }) => (
                    <>
                        {
                            (
                                status === 'STOCK_IN' ? <Tag color="#87d068">Stock In</Tag> : <Tag color="#87d068">Stock Check</Tag>
                            )
                        }
                    </>
                ),

            }
        ]
        return (
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    {
                        isDataFetching ?
                            <Skeleton active />
                            :
                            <Table
                                style={{ borderRadius: 10 }}
                                pagination={{
                                    position: 'top' | 'bottom',
                                    total: totalPagination,
                                    current: page + 1,
                                    onChange: this.handlePaginationChange,
                                    defaultPageSize: pageSize,
                                    showSizeChanger: false
                                }}
                                columns={columns}
                                dataSource={data}
                                rowClassName={getRowClassName} />
                    }
                </Col>
            </Row>
        )
    }



}
export default compose(withRouter)(DriverAwbInventoryHistory)