import dayjs from "dayjs";

export const formatDateDigit = (date, format = "YYYY-MM-DD") => {
    return dayjs(date).format(format);
}

export const formatDateLetter = (date, format = "MMM DD, YYYY") => {
    return dayjs(date).format(format);
}

export const formatDateTime = (date, format = "YYYY-MMM-DD hh:mm A") => {
    return dayjs(date).format(format);
}