import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react'; // Import PersistGate
import './index.css';
import store, { persistor } from './redux/store';
import reportWebVitals from './reportWebVitals';
import Router from './routes/router';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    {/* <Suspense fallback={loadingMarkup}> */}
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router />
      </PersistGate>
    </Provider>
    {/* </Suspense> */}
  </BrowserRouter>
);
reportWebVitals();
