import { compose } from "@reduxjs/toolkit";
import { Col, Row, Skeleton, Table } from "antd";
import { default as React } from "react";
import { TitleLevel3 } from "../../components/general-component";
import Api from "../../network/api";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import { ApiHandler } from "../../network/network-manager";
import withRouter from "../../network/with-router";
import { Constant } from "../../core/constant";

class FeedbackPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pageSize: Constant.pageSize,
            totalPagination: 0,
            page: 1,
            isDataFetching: false,
        }
    }
    componentDidMount() {
        this.fetchData()
    }

    fetchData = async (pageNumber) => {
        const { page, pageSize } = this.state
        this.setState({
            isDataFetching: true
        })
        const requestParams = {
            size: pageSize,
            page: pageNumber ? pageNumber - 1 : page - 1
        }
        await ApiHandler({ url: Api.feedback, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            .then(response => {
                this.setState({
                    data: response.content,
                    totalPagination: response.totalElements,
                    page: pageNumber ? pageNumber : page
                });
            }).catch(error => {
            }).finally(() => {
                this.setState({
                    isDataFetching: false,
                })
            })
    }

    handlePaginationChange = (pageNumber) => {
        this.fetchData(pageNumber);
    };


    render() {
        const { data, isDataFetching, totalPagination, page, pageSize } = this.state
        const getRowClassName = (record, index) => {
            return index % 2 === 0 ? 'blue-row' : 'black-row';
        };
        const columns = [
            {
                title: 'No.',
                key: 'index',
                render: (value, item, index) => <span>{index + 1}</span>,
                width: 50
            },
            {
                title: 'Feedback',
                key: 'feedback',
                render: (feedback) => (
                    <>
                        {
                            feedback?.feedback &&
                            <>
                                <div style={{ fontSize: 14, fontWeight: '500', color: 'gray' }}>Feedback</div>
                                <div style={{ marginBottom: 10 }}>{feedback.feedback}</div>
                            </>
                        }
                        {
                            feedback?.suggestion &&
                            <>
                                <div style={{ fontSize: 14, fontWeight: '500', color: 'gray' }}>Suggestion</div>
                                <div style={{ marginBottom: 10 }}>{feedback.suggestion}</div>
                            </>
                        }
                        {
                            feedback?.feedback &&
                            <>
                                <div style={{ fontSize: 14, fontWeight: '500', color: 'gray' }}>Bug reports</div>
                                <div>{feedback.bugReports}</div>
                            </>
                        }
                    </>
                )
            },
            {
                title: 'Rating',
                key: 'rating',
                dataIndex: 'rating',
                width: 100
            },
            {
                title: 'Feedback User Info',
                key: 'action',
                textAlign: 'center',
                width: 250,
                render: (feedback) => (
                    <>
                        {
                            feedback?.fullName && <div style={{ marginBottom: 3 }}>{feedback.fullName}</div>
                        }
                        {
                            feedback?.businessName && <div style={{ marginBottom: 3 }}>{feedback.businessName}</div>
                        }
                        {
                            feedback?.phoneNumber && <div>{feedback.phoneNumber}</div>
                        }
                    </>

                )
            },
        ]
        return (
            <Row gutter={[16, 16]}>
                <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
                    <TitleLevel3 label={"Feedback"} />
                </Col>
                <Col span={24}>
                    {
                        isDataFetching ?
                            <Skeleton active />
                            :
                            <Table
                                pagination={{
                                    position: 'top' | 'bottom',
                                    total: totalPagination,
                                    current: page,
                                    onChange: this.handlePaginationChange,
                                    defaultPageSize: pageSize,
                                    showSizeChanger: false
                                }}
                                columns={columns} dataSource={data}
                                rowClassName={getRowClassName} />
                    }
                </Col>
            </Row>
        );
    }
}

export default compose(withRouter)(FeedbackPage)