import { EditOutlined } from "@ant-design/icons";
import { compose } from "@reduxjs/toolkit";
import { Button, Card, Col, Input, Row, Select, Skeleton, Space, Table, message } from "antd";
import React from "react";
import { SvgRightArrowFill } from "../../../components/custom-svg";
import Theme from "../../../components/theme";
import { Constant } from "../../../core/constant";
import Api from "../../../network/api";
import { ApiFilter, ApiPost, ApiPut } from "../../../network/network-manager";
import withRouter from "../../../network/with-router";
class PricingGroupZoneToZonSetup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            zone_collection: [],
            zone_price_collection: [],
            pageSize: Constant.pageSize,
            totalPagination: 0,
            page: 1,
            searchValue: [],
            fetchUserTypes: [],
            deleteItem: null,
            zonePriceObj: {},
            isEditing: false,
            isDataFetching: true,
            pring_group_id: props.pricingGroupId
        }
    }

    componentDidMount() {
        this.fetchData()
        this.fetchZonePrice()
    }

    handleUserInput = (key, value) => {
        const { zonePriceObj } = this.state
        let obj = { ...zonePriceObj }

        if (key === 'price') {
            obj = { ...obj, [key]: value.target.value }
        } else {
            obj = { ...obj, [key]: value }
        }
        this.setState({
            zonePriceObj: obj
        })
    }

    fetchData = async (pageNumber) => {
        try {
            const response = await ApiFilter(Api.zone_filter, null, this.props)
            if (response) {
                this.setState(prevState => ({
                    zone_collection: response,

                }));
            }
        } catch (error) {
        }
    }
    fetchZonePrice = async () => {
        const { pring_group_id } = this.state
        try {
            this.setState({
                isDataFetching: true
            })
            var params = {
                pricingGroupId: pring_group_id
            }
            const response = await ApiFilter(Api.zone_price_filter, params, this.props)
            if (response) {
                this.setState({
                    zone_price_collection: response
                })
            }
        } catch (error) {
        }
        this.setState({
            isDataFetching: false
        })
    }

    saveZonePrice = async () => {
        let { zonePriceObj, pring_group_id } = this.state
        try {
            if (zonePriceObj) {
                if (zonePriceObj.fromZoneId === null || zonePriceObj.fromZoneId === undefined || zonePriceObj.toZoneId === null || zonePriceObj.toZoneId === undefined) {
                    message.destroy()
                    message.error("Please select zone")
                    return
                } else if (zonePriceObj.price === null || zonePriceObj.price === undefined || zonePriceObj.price === 0) {
                    message.destroy()
                    message.error("Please enter price")
                    return
                }
            } else {
                message.destroy()
                message.error("Field must not empty!")
                return
            }

            const numberPattern = /^[0-9]*\.?[0-9]*$/;
            if (!zonePriceObj.price || !numberPattern.test(zonePriceObj.price)) {
                message.destroy()
                message.error("Invalid price")
                return
            }



            if (zonePriceObj.id !== null && zonePriceObj.id !== undefined) {
                const response = await ApiPut(Api.zone_price_create, zonePriceObj.id, zonePriceObj, this.props)
                if (response) {
                    this.setState({
                        zonePriceObj: null,
                        isEditing: false
                    })
                }
            } else {
                zonePriceObj = { ...zonePriceObj, pricingGroupId: pring_group_id }
                const response = await ApiPost(Api.zone_price_create, zonePriceObj, this.props)
                if (response) {
                    this.setState({
                        zonePriceObj: null
                    })
                }
            }
            this.fetchZonePrice()
        } catch (error) {
            console.log(error)
        }


    }

    editZone = (zone) => {
        const zonePrice = { 'fromZoneId': zone.fromZoneId, 'toZoneId': zone.toZoneId, 'price': zone.price, 'id': zone.id }
        this.setState({
            zonePriceObj: zonePrice,
            isEditing: true
        })
    }

    handlePaginationChange = (pageNumber, pageSize) => {
        this.setState({ page: pageNumber })
    };

    render() {
        const { isEditing, totalPagination, isDataFetching, page, pageSize, zonePriceObj, zone_collection, zone_price_collection } = this.state
        const getRowClassName = (record, index) => {
            return index % 2 === 0 ? 'blue-row' : 'black-row';
        };

        const columns = [
            {
                title: 'No.',
                key: 'index',
                render: (value, item, index) => <span>{(page - 1) * pageSize + index + 1}</span>,
                width: 5
            },
            {
                title: 'From Zone',
                dataIndex: 'fromZoneName',
                key: 'fromZoneName',
            }, {
                title: 'To Zone',
                dataIndex: 'toZoneName',
                key: 'toZoneName',
            }, {
                title: 'Price',
                dataIndex: 'price',
                key: 'price',
            },
            {
                title: 'Action',
                key: 'action',
                dataIndex: '',
                textAlign: 'center',
                width: 60,
                render: (zone) => (
                    <Space>
                        <Button size={'middle'} type="primary" shape="circle" onClick={() => this.editZone(zone)}><EditOutlined /></Button>
                    </Space>
                ),
            },
        ]
        return (
            <>
                <Row gutter={[16, 16]}>
                    <Col span={24}>

                    </Col>
                    <Col span={24} >
                        <Card
                            style={{ backgroundColor: Theme.colors.card_bg_color, boxShadow: 'none' }}
                            bordered={false}
                            title={null}
                        >
                            <Row gutter={[16, 16]}>
                                <Col span={7}>
                                    <h4 style={{ margin: 0 }}>From Zone</h4>
                                </Col>
                                <Col span={1}></Col>
                                <Col span={7}>
                                    <h4 style={{ margin: 0 }}>To Zone</h4>
                                </Col>
                                <Col span={6}>
                                    <h4 style={{ margin: 0 }}>Price</h4>
                                </Col>
                                <Col span={3}></Col>
                            </Row>

                            <Row gutter={[16, 16]} style={{ alignItems: 'center' }}>
                                <Col span={7}>
                                    <Select
                                        className="my-location-selector"
                                        placeholder={"Choose from zone"}
                                        value={zonePriceObj ? zonePriceObj.fromZoneId : null}
                                        onChange={(value) => this.handleUserInput('fromZoneId', value)}
                                        size="large"
                                        style={{ width: '100%' }}
                                        options={
                                            zone_collection && zone_collection.map(zone => {
                                                return {
                                                    value: zone.id,
                                                    label: zone.zoneName,
                                                    id: 'fromZone'
                                                }
                                            })
                                        }
                                    />

                                </Col>
                                <Col span={1} style={{ textAlign: 'center' }}>
                                    <SvgRightArrowFill width={20} height={20} color={"#000000"} />
                                </Col>
                                <Col span={7}>
                                    <Select
                                        value={zonePriceObj ? zonePriceObj.toZoneId : null}
                                        className="my-location-selector"
                                        placeholder={"Choose to zone"}
                                        onChange={(value) => this.handleUserInput('toZoneId', value)}
                                        size="large"
                                        style={{ width: '100%' }}
                                        options={
                                            zone_collection && zone_collection.map(zone => {
                                                return {
                                                    value: zone.id,
                                                    label: zone.zoneName,
                                                    id: 'toZone'
                                                }
                                            })
                                        }
                                    />

                                </Col>

                                <Col span={6}>
                                    <Input
                                        value={zonePriceObj ? zonePriceObj.price : null}
                                        onChange={(value) => this.handleUserInput('price', value)} placeholder={"Enter price"} />
                                </Col>
                                <Col span={3}>
                                    <Button
                                        size="large"
                                        type="primary"
                                        style={{ width: '100%' }}
                                        onClick={() => this.saveZonePrice()}
                                    >
                                        {
                                            isEditing ? <span>Update</span> : <span>Create</span>
                                        }
                                    </Button>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col span={24}>
                        {
                            isDataFetching ?
                                <Skeleton active />
                                :
                                <Table
                                    style={{ borderRadius: 10 }}
                                    pagination={{
                                        position: 'top' | 'bottom',
                                        total: totalPagination,
                                        current: page,
                                        onChange: this.handlePaginationChange,
                                        defaultPageSize: pageSize,
                                        showSizeChanger: false
                                    }}
                                    columns={columns}
                                    dataSource={zone_price_collection}
                                    rowClassName={getRowClassName} />
                        }

                    </Col>
                </Row>
            </>
        )
    }
}

export default compose(withRouter)(PricingGroupZoneToZonSetup)