import { EyeOutlined, InboxOutlined } from "@ant-design/icons";
import { compose } from "@reduxjs/toolkit";
import { Button, Card, Col, DatePicker, Form, Input, Modal, Row, Select, Skeleton, Space, Spin, Table, Upload, message } from "antd";
import dayjs from "dayjs";
import React from "react";
import { FormLabelOptional, TitleLevel3 } from "../../components/general-component";
import { NVMCompOrderDetail } from "../../components/nvm-comp-order-detail";
import { Constant } from "../../core/constant";
import Api from "../../network/api";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import { ApiHandler, uploadAndDownload } from "../../network/network-manager";
import withRouter from "../../network/with-router";
import SearchPagination from "../../components/searchPagination";
import FetchSearchUrl from "../../components/fetchSearchUrl";
import SearchFilter from "../../components/searchFilter";
import { NJVInput } from "../../components/core-component";
import Theme from "../../components/theme";
const { Dragger } = Upload;
const { Option } = Select;

class RTSOrderPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            order_history_collection: [],
            historyMap: {},
            titleList: [],

            totalPage: 0,
            pageSize: Constant.pageSize,
            page: 1,
            filterData: {},
            orderStatusList: [],
            current_order_detail: {},

            openUploadExcelModal: false,
            openOrderDetailModal: false,
            openManualRTSModal: false,
            isDataFetching: false,
            isUploading: false,
            creatingRTS: false,
        }
        this.createRef = React.createRef()
    }

    componentDidMount() {
        const { urlPage } = FetchSearchUrl()

        if (urlPage) {
            this.fetchData(urlPage)
        }
    }

    fetchData = async (pageNumber) => {
        let { pageSize, page, filterData } = this.state
        this.setState({
            isDataFetching: true
        })
        try {
            let params = {
                page: pageNumber ? pageNumber - 1 : page - 1,
                size: pageSize
            }
            if (filterData) {
                if ((filterData.fromDate && !filterData.toDate) || (!filterData.fromDate && filterData.toDate)) {
                    message.error("Invalid date")
                    return
                }
                if (filterData.fromDate > filterData.toDate) {
                    message.error("Invalid date")
                    return
                }
                if (filterData.fromDate) {
                    filterData = { ...filterData, 'startDate': filterData.fromDate.format("YYYY-MM-DD") }
                }
                if (filterData.toDate) {
                    filterData = { ...filterData, 'endDate': filterData.toDate.format("YYYY-MM-DD") }
                }
                params = {
                    ...params,
                    ...filterData
                }
            }
            const response = await ApiHandler({ url: Api.order_rts, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams: params })
            if (response && response.content) {
                let updatedList = []
                response.content.map((orderHistory) => {
                    if (orderHistory.createdDate) {
                        orderHistory = { ...orderHistory, uploadDate: dayjs(orderHistory.createdDate).format('YYYY-MM-DD HH:MM A') }
                    }
                    orderHistory = { ...orderHistory, key: orderHistory.id, trackingNumber: orderHistory.order.trackingNumber }
                    updatedList.push(orderHistory)
                })
                this.setState({
                    data: updatedList,
                    totalPage: response.totalElements,
                    page: pageNumber ? pageNumber : page

                })
            }
        } catch (error) {
        }
        this.setState({
            isDataFetching: false
        })
    }

    handleFileUpload = async (file) => {
        try {
            this.setState({
                isUploading: true
            })
            let formData = new FormData()
            formData.append('file', file);

            await uploadAndDownload(Api.order_rts_excel_upload, formData, "invalid-rts-tracking-list")
            message.success("Successfully Uploaded")
            this.fetchData()
        } catch (error) {
            if (error && error.response.status === 500) {
                message.destroy()
                message.error("Something went wrong")
            }
        }
        this.setState({
            isUploading: false,
            openUploadExcelModal: false
        })
    };

    closeModal = () => {
        if (this.state.isUploading) {
            message.error("You can not close at this time")
        } else {
            this.setState({
                openUploadExcelModal: false
            })
        }
    }

    updateFilterData = (key, value) => {
        let { filterData } = this.state
        filterData = {
            ...filterData,
            [key]: value
        }
        this.setState({
            filterData: filterData
        })
    }

    search = () => {
        const { filterData } = this.state;

        const newUrl = SearchFilter(filterData);
        window.history.pushState({}, '', newUrl)
        this.fetchData(1)
    }

    handlePaginationChange = (pageNumber) => {
        const newUrl = SearchPagination(pageNumber)
        window.history.pushState({}, '', newUrl)
        this.fetchData(pageNumber);
    };

    submitRTSRequest = async (data) => {
        this.setState({ creatingRTS: true })
        await ApiHandler({ url: Api.order_rts_single, method: HTTP_METHOD.POST, mediaType: MEDIA_TYPE.JSON, requestParams: { 'trackingNumber': data.trackingNumber } })
            .then(() => {
                this.createRef?.current.setFieldsValue({ 'trackingNumber': null })
                this.setState({ openManualRTSModal: false, creatingRTS: false })
            })
            .catch(() => { })
    }

    render() {
        const { isDataFetching, pageSize, totalPage, page, data, openUploadExcelModal, isUploading, openOrderDetailModal, current_order_detail, openManualRTSModal, creatingRTS } = this.state
        const draggerProps = {
            name: 'file',
            multiple: false,
            showUploadList: false,
            beforeUpload: () => false,
            onChange: (info) => {
                const { file } = info;
                this.handleFileUpload(file);
            },
        };

        const getRowClassName = (record, index) => {
            return index % 2 === 0 ? 'blue-row' : 'black-row';
        };
        const columns = [
            {
                title: 'No.',
                key: 'index',
                render: (value, item, index) => <span>{(page - 1) * pageSize + index + 1}</span>,
                width: 5
            },
            {
                title: 'Tracking Number',
                dataIndex: 'trackingNumber',
                key: 'trackingNumber',
            },
            {
                title: 'Upload Date',
                key: 'uploadDate',
                dataIndex: 'uploadDate',

            },
            {
                title: 'Status',
                key: 'status',
                dataIndex: 'status',
            },
            {
                title: 'Action',
                key: 'action',
                dataIndex: '',
                textAlign: 'center',
                width: 10,
                render: (orderHistory) => (
                    <Space>
                        <Button size={'middle'} type="primary" shape="circle" onClick={() => this.setState({ current_order_detail: orderHistory.order, openOrderDetailModal: true })}><EyeOutlined /></Button>
                    </Space>
                ),
            }
        ]
        return (
            <>
                <Modal
                    title={"RTS Order Detail"}
                    centered
                    open={openOrderDetailModal}
                    footer={null}
                    onCancel={() => this.setState({ current_order_detail: {}, openOrderDetailModal: false })}
                    className='custom-modal'
                >
                    <NVMCompOrderDetail data={current_order_detail} />
                </Modal>
                <Modal
                    title={"Create Manual RTS"}
                    centered
                    open={openManualRTSModal}
                    footer={null}
                    onCancel={() => this.setState({ openManualRTSModal: false })}
                    className='custom-modal'
                >
                    <Form
                        ref={this.createRef}
                        layout="vertical"
                        onFinish={this.submitRTSRequest}>
                        <Form.Item name="trackingNumber" label="Tracking Number" rules={[{ required: true, message: "(*required)" }]}>
                            <NJVInput bgcolor={Theme.colors.input_bg_color} placeholder="Enter tracking number" />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" loading={creatingRTS}>Create</Button>
                        </Form.Item>
                    </Form>
                </Modal>
                <Modal
                    title={"Upload RTS Excel file"}
                    centered
                    open={openUploadExcelModal}
                    footer={null}
                    onCancel={() => this.closeModal()}
                    className='custom-modal'
                >
                    <Dragger
                        disabled={isUploading}
                        {...draggerProps}
                        accept={[".xlsx", ".xls"]}
                        maxCount={1}
                        style={{ marginTop: 15 }}>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-hint">
                            {
                                isUploading ?
                                    <div>
                                        <Spin /> <span> Uploading...</span>
                                    </div>
                                    :
                                    <span>Upload Excel File Only</span>
                            }

                        </p>
                    </Dragger>
                </Modal>
                <Row gutter={[16, 16]}>
                    <Col span={24} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <TitleLevel3 label={"RTS Order"} />
                        <Button type='primary' onClick={() => this.setState({ openUploadExcelModal: true })}>Upload RTS Order</Button>
                    </Col>
                    <Col span={24}>
                        <Card
                            style={{ backgroundColor: "#f5f5f5", boxShadow: 'none', paddingTop: 15 }}
                            bordered={false}
                        >
                            <Row gutter={[16, 16]}>
                                <Col span={6}>
                                    <FormLabelOptional label="Tracking Number" />
                                    <Input size="large" placeholder={"Tracking Number"} onChange={(event) => this.updateFilterData('trackingNumber', event.target.value)} />
                                </Col>
                                <Col span={6}>
                                    <FormLabelOptional label="From Date" />
                                    <DatePicker
                                        size="large"
                                        onChange={(value) => this.updateFilterData('fromDate', value)}
                                        style={{ width: '100%' }}
                                        placeholder="From Date" />
                                </Col>
                                <Col span={6}>
                                    <FormLabelOptional label="To Date" />
                                    <DatePicker
                                        size="large"
                                        onChange={(value) => this.updateFilterData('toDate', value)}
                                        style={{ width: '100%' }}
                                        placeholder="To Date" />
                                </Col>
                                <Col span={6}>
                                    <FormLabelOptional label="Status" />
                                    <Select
                                        onChange={(value) => this.updateFilterData('status', value)}
                                        size="large"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        placeholder="Select  status">
                                        <Option value={"PENDING"}>PENDING</Option>
                                        <Option value={"REJECT"}>REJECT</Option>
                                        <Option value={"CONFIRM"}>CONFIRM</Option>
                                    </Select>
                                </Col>
                                <Col span={18} />
                                <Col span={6}>
                                    <Button
                                        size="large"
                                        style={{ width: '100%' }}
                                        type="primary" onClick={() => this.search()}>
                                        Search
                                    </Button>
                                </Col>
                            </Row>
                        </Card>
                    </Col>

                    {
                        isDataFetching ?
                            <Col span={24}>
                                <Skeleton active />
                            </Col>
                            :
                            <Col span={24}>
                                <Table
                                    style={{ borderRadius: 10 }}

                                    pagination={{
                                        position: 'top' | 'bottom',
                                        total: totalPage,
                                        current: page,
                                        onChange: this.handlePaginationChange,
                                        defaultPageSize: pageSize,
                                        showSizeChanger: false
                                    }}
                                    columns={columns}
                                    dataSource={data}
                                    rowClassName={getRowClassName}
                                // expandable={{
                                //     expandedRowRender: (orderHistory) => (
                                //         <Row gutter={[16, 16]}>
                                //             <Col span={12}>
                                //                 <h4 style={{ marginBottom: 5, marginTop: 1 }}>Sender</h4>
                                //                 <div>{orderHistory.senderName}</div>
                                //                 <div>{orderHistory.senderPhoneNumber}</div>
                                //                 <div>
                                //                     {orderHistory.fromTownship.division ?
                                //                         <span>{orderHistory.fromTownship.division.name}</span>
                                //                         : <></>
                                //                     } / {orderHistory.fromTownship.name}</div>
                                //                 <div>{orderHistory.fromAddress}</div>
                                //             </Col>
                                //             <Col span={12}>
                                //                 <h4 style={{ marginBottom: 5, marginTop: 1 }}>Receiver</h4>
                                //                 <div>{orderHistory.recipientName}</div>
                                //                 <div>{orderHistory.recipientPhoneNumber}</div>
                                //                 <div>{orderHistory.toTownship.division ?
                                //                     <span>{orderHistory.toTownship.division.name}</span>
                                //                     : <></>
                                //                 } / {orderHistory.toTownship.name}</div>
                                //                 <div>{orderHistory.toAddress}</div>
                                //             </Col>
                                //             <Col span={24}>
                                //                 Note : {orderHistory.note}
                                //             </Col>
                                //         </Row>
                                //     )
                                // }}
                                />
                            </Col>

                    }
                </Row>
            </>
        )
    }
}

export default compose(withRouter)(RTSOrderPage)