import { PlusOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Card, Checkbox, Col, DatePicker, Form, Image, Input, Modal, Radio, Row, Select, Upload, message } from "antd";
import dayjs from 'dayjs';
import React from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import Theme from "../../../components/theme";
import { AdminPasswordValidator } from "../../../components/validator";
import Api from "../../../network/api";
import { ApiFilter, ApiGet, ApiPutWithFormData } from "../../../network/network-manager";
import withRouter from "../../../network/with-router";
import CustomPath from "../../../routes/custom-path";

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

class PartnershipAdminUpdate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            roleData: [],
            adminAccount: {},
            previewImage: null,
            previewTitle: null,
            fileList: [],
            isLoading: false,
            previewOpen: false
        };
        this.inputRef = React.createRef()
    }

    componentDidMount() {
        this.fetchData()
        this.fetchRole()
    }

    fetchRole = async () => {
        try {
            var params = {
                page: 0,
                size: 100
            }
            const response = await ApiFilter(Api.role_filter, params, this.props)
            if (response && response.content) {
                this.setState({
                    roleData: response.content
                });
            }
        } catch (error) {
        }
    }

    fetchData = async () => {
        try {
            const response = await ApiGet(Api.partnership_admin, this.props.params.id, this.props)
            if (response) {
                this.setState(prevState => ({
                    data: response,
                }));
                let admin;
                if (response.dateOfBirth) {
                    const dob = dayjs(response.dateOfBirth, 'YYYY-MM-DD');
                    admin = { ...response, dateOfBirth: dob }
                } else {
                    admin = { ...response }
                }
                if (response.userStatus === 'ACTIVE') {
                    admin = { ...admin, active: true }
                } else {
                    admin = { ...admin, active: false }
                }
                if (admin.role) {
                    admin = { ...admin, role: admin.role.id }
                }
                if (admin.accounts) {
                    admin.accounts.map((account) => {
                        if (account.accountType === "PHONE") {
                            admin = { ...admin, primaryPhoneNumber: account.primaryPhoneNumber, password: account.password }
                            this.setState({
                                adminAccount: account
                            })
                        }
                    })
                }
                if (admin.roles) {
                    let roles = []
                    admin.roles.map((role) => {
                        roles.push(role.id)
                    })
                    admin = { ...admin, roles }
                }
                this.inputRef.current.setFieldsValue(admin);
            }
        } catch (error) {

        }
    }

    updateGender = (event) => {
        this.setState({
            gender: event.target.value
        })
    }

    handlePhoneNumberChange = (e) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/[^0-9]/g, '');
        this.inputRef.current.setFieldsValue({ primaryPhoneNumber: numericValue })
    };


    updatePartnerAdmin = async (values) => {
        const { adminAccount, data } = this.state
        this.setState({
            isLoading: true
        })
        try {

            let formData = new FormData()
            formData.append('id', data.id);
            formData.append('firstName', values.firstName);
            formData.append('lastName', values.lastName);
            if (values.lastName) {
                formData.append('fullName', values.firstName + " " + values.lastName);
            } else {
                formData.append('fullName', values.firstName);
            }
            formData.append('specialCode', values.specialCode)
            if (values.dateOfBirth)
                formData.append('dateOfBirth', values.dateOfBirth);
            if (values.gender)
                formData.append('gender', values.gender);

            formData.append('userStatus', values.active ? "ACTIVE" : "INACTIVE");
            formData.append('phoneNumber', values.primaryPhoneNumber);
            formData.append('accounts[0].id', adminAccount.id);
            formData.append('accounts[0].loginId', values.primaryPhoneNumber);
            formData.append('accounts[0].primaryPhoneNumber', values.primaryPhoneNumber);
            formData.append('accounts[0].password', values.password);
            formData.append('accounts[0].accountType', "PHONE");
            formData.append('role.id', values.role);
            if (values.photoUrl && values.photoUrl.file && values.photoUrl.file.originFileObj) {
                formData.append('storeLogo', values.photoUrl.file.originFileObj)
            }
            const response = await ApiPutWithFormData(Api.partnership_admin, formData)
            if (response.status === 200) {
                this.props.navigate(CustomPath.partnership_admin)
            }
        } catch (error) {

        }
        this.setState({
            isLoading: false
        })
    }

    disabledDate = (current) => {
        const currentDate = dayjs();
        const eighteenYearsAgo = currentDate.subtract(18, 'year');
        return current && current > eighteenYearsAgo;
    };

    currentDate = () => {
        const date = dayjs();
        return date.subtract(18, 'year');
    }

    handleChange = (uploadObject) => {
        if (this.checkValidFileSize(uploadObject.file)) {
            this.setState({
                fileList: uploadObject.fileList
            })
        }

    }

    handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        this.setState({
            previewImage: file.url || file.preview,
            previewOpen: true,
            previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
        })
    };
    checkValidFileSize = (file) => {
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
            message.destroy()
            message.error('Image must smaller than 5MB!');
        }
        return isLt2M;
    };

    render() {
        const { navigate } = this.props
        const { roleData, isLoading, fileList, previewOpen, previewImage, previewTitle, data } = this.state
        return (
            <>

                <Modal
                    open={previewOpen}
                    title={previewTitle}
                    footer={null}
                    onCancel={() => this.setState({ previewOpen: false })}>
                    <img
                        alt="example"
                        style={{
                            width: '100%',
                        }}
                        src={previewImage}
                    />
                </Modal>
                <Breadcrumb
                    items={[
                        {
                            title: 'Home',
                        },
                        {
                            title: <Link to={CustomPath.partnership_admin}>Partnership Admin</Link>,
                        },
                        {
                            title: 'Update',
                        }
                    ]}
                    style={{ marginBottom: "20px" }}
                />
                <Card
                    style={{ backgroundColor: Theme.colors.card_bg_color, boxShadow: 'none', marginBottom: "20px" }}
                    bordered={false}
                    title={"Partnership Admin Form Update"}
                    extra={<Button type="primary" onClick={() => navigate(CustomPath.partnership_admin)}>Back</Button>}
                >

                    <Form
                        layout="vertical"
                        ref={this.inputRef}
                        onFinish={this.updatePartnerAdmin}>
                        <Row gutter={[16, 16]}>
                            <Col span={12} >
                                <Form.Item name="specialCode" label={"Unique Id"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <Input placeholder={"Enter unique id"} />
                                </Form.Item>
                            </Col>
                            <Col span={12} />
                            <Col span={12} >
                                <Form.Item name="firstName" label={"First Name"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <Input placeholder={"Enter first name"} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="lastName" label={"Last Name"}>
                                    <Input placeholder={"Enter last name"} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="primaryPhoneNumber" label={"Phone Number"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <Input placeholder={"979xxxxxxxx"} onChange={this.handlePhoneNumberChange} />
                                </Form.Item>
                            </Col>
                            <Col span={12} >
                                <Form.Item name="password" label={"Password"} rules={[{ required: true, message: '*(requried)' }, { validator: AdminPasswordValidator }]} style={{ cursor: 'pointer' }}>
                                    <Input.Password size="small" placeholder="Enter password" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="gender" label={"Gender"}>
                                    <Select
                                        size="large"
                                        style={{
                                            width: '100%',
                                        }}
                                        placeholder="Select gender"
                                        options={[
                                            {
                                                label: 'Male',
                                                value: 'MALE',
                                            },
                                            {
                                                label: 'Female',
                                                value: 'FEMALE',
                                            },
                                        ]}
                                    />
                                </Form.Item>

                            </Col>
                            <Col span={12}>
                                <Form.Item name="dateOfBirth" label={"Date Of Birth"} style={{ cursor: 'pointer' }} className="custom-date-picker">
                                    <DatePicker
                                        disabledDate={this.disabledDate}
                                        allowClear={false}
                                        size="large" style={{ width: '100%', cursor: 'pointer' }} format={'YYYY-MM-DD'} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="active"
                                    valuePropName="checked">
                                    <Checkbox>Active</Checkbox>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                {
                                    data && data.logo ?
                                        <Image
                                            src={data.logo}
                                            width={200}
                                            style={{ borderRadius: 10 }}
                                        />
                                        :
                                        <Form.Item name="photoUrl" label={"Upload Store Logo"}>
                                            <Upload
                                                accept={["image/png", "image/jpg", "image/jpeg"]}
                                                listType="picture-card"
                                                fileList={fileList}
                                                multiple={false}
                                                maxCount={1}
                                                action={null}
                                                onPreview={this.handlePreview}
                                                onChange={this.handleChange}

                                            >
                                                {fileList.length >= 1 ? null :
                                                    <div>
                                                        <PlusOutlined />
                                                        <div
                                                            style={{
                                                                marginTop: 8,
                                                            }}
                                                        >
                                                            Upload
                                                        </div>
                                                    </div>}
                                            </Upload>
                                        </Form.Item>
                                }
                            </Col>
                            {
                                roleData && roleData.length !== 0 ?
                                    <Col span={24}>
                                        <Form.Item name="role" label={"Role"}>
                                            <Radio.Group
                                                style={{
                                                    width: '100%',
                                                }}
                                            >
                                                <Row>
                                                    {
                                                        roleData.map((value) =>
                                                            <Col span={24} style={{ marginBottom: 5 }}>
                                                                <Radio value={value.id}>{value.roleTypeName}</Radio>
                                                            </Col>
                                                        )

                                                    }
                                                </Row>
                                            </Radio.Group>

                                        </Form.Item>
                                    </Col>
                                    :
                                    <></>
                            }
                        </Row>

                        <Form.Item style={{ textAlign: 'end' }}>
                            {/* <Button style={{ marginRight: '20px' }}>Reset</Button> */}
                            <Button type="primary" htmlType="submit" loading={isLoading}>Update</Button>
                        </Form.Item>
                    </Form>
                </Card>
            </>
        );
    }
}

export default compose(withRouter)(PartnershipAdminUpdate)