import { compose } from '@reduxjs/toolkit';
import { Button, Card, Col, DatePicker, Divider, Drawer, Image, Input, Row, Skeleton, Table, Tag } from 'antd';
import dayjs from 'dayjs';
import React, { Component } from 'react';
import { TitleLevel3 } from '../../components/general-component';
import { Constant } from '../../core/constant';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiHandler, downloadExcel } from '../../network/network-manager';
import withRouter from '../../network/with-router';
import FetchSearchUrl from '../../components/fetchSearchUrl';
import SearchPagination from '../../components/searchPagination';
import SearchFilter from '../../components/searchFilter';

const NJVLabel = ({ label }) => {
    return (
        <>
            <div style={{ fontSize: 13, fontWeight: '600', color: 'gray' }}>{label}</div>
        </>)
}

class InsuranceReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            insuranceDetail: null,
            filterData: null,
            pageSize: Constant.pageSize,
            totalPagination: 0,
            page: 1,
            loading: { isDataFetching: false, isDownloading: false }
        }
    }

    componentDidMount = () => {
        const { urlPage } = FetchSearchUrl();

        if (urlPage) {
            this.fetchData(urlPage);
        }
    }

    fetchData = async (pageNumber) => {
        const { page, pageSize, filterData } = this.state;
        this.setState(
            {
                loading: {
                    isDataFetching: true
                }
            }
        )
        let requestParams = {
            'policyStartDate': filterData?.policyStartDate && dayjs(filterData?.policyStartDate).format('YYYY-MM-DD'),
            'trackingNumber': filterData?.trackingNumber,
            'senderName': filterData?.senderName,
            'page': pageNumber ? pageNumber - 1 : page - 1,
            'size': pageSize,
        }
        await ApiHandler({ url: Api.insurance_filter, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            .then((response) => {
                this.setState({
                    data: response.content,
                    totalPagination: response.totalElements,
                    page: pageNumber ? pageNumber : page
                })
            }
            ).catch(() => { })
            .finally(() => this.setState(
                {
                    loading: {
                        isDataFetching: false
                    }
                }
            ))
    }

    handlePaginationChange = (pageNumber) => {
        const newUrl = SearchPagination(pageNumber)
        window.history.pushState({}, '', newUrl)
        this.fetchData(pageNumber)
    };

    search = () => {
        const { filterData } = this.state;

        const newUrl = SearchFilter(filterData);
        window.history.pushState({}, '', newUrl)
        this.fetchData(1)
    }

    handleFilter = (key, value) => {
        this.setState(prev => ({
            filterData: {
                ...prev.filterData,
                [key]: value
            }
        }))
    }

    downloadInsuranceExcel = async () => {
        let { filterData, page, pageSize } = this.state
        this.setState(
            {
                loading: {
                    isDownloading: true
                }
            }
        )
        let params = {
            page: page - 1,
            size: pageSize
        }
        if (filterData) {
            params = {
                ...params,
                'policyStartDate': filterData?.policyStartDate && dayjs(filterData?.policyStartDate).format('YYYY-MM-DD'),
                'trackingNumber': filterData?.trackingNumber,
                'senderName': filterData?.senderName
            }
        }
        await downloadExcel(Api.download_insurance_excel, params, "Insurance List")
            .then(() => { })
            .catch(() => {
            }).finally(() => {
                this.setState(
                    {
                        loading: {
                            isDataFetching: false
                        }
                    }
                )
            })
    }

    render() {
        const { data, totalPagination, page, pageSize, loading, isDrawerOpen, insuranceDetail } = this.state

        const getRowClassName = (record, index) => {
            return index % 2 === 0 ? 'blue-row' : 'black-row';
        };

        const columns = [
            {
                title: 'No.',
                key: 'index',
                render: (value, item, index) => <span>{(page - 1) * pageSize + index + 1}</span>,
                width: 5
            },
            {
                title: 'Tracking Number',
                key: 'trackingNumber',
                dataIndex: 'trackingNumber'
            },
            {
                title: 'Sender Name',
                key: 'senderName',
                dataIndex: 'senderName'
            },
            {
                title: 'Sender Address',
                key: 'senderAddress',
                dataIndex: 'senderAddress',
            },
            {
                title: 'Total Quantity',
                key: 'totalQuantity',
                dataIndex: 'totalQuantity',
                align: 'center',
            },
            {
                title: 'Policy Start Date',
                key: 'createdDate',
                dataIndex: 'createdDate',
                align: 'center',
                render: (value) => dayjs(value).format('YYYY-MM-DD')
            },
            {
                title: 'Sum Insured',
                key: 'sumInsured',
                dataIndex: 'sumInsured',
                align: 'center',
            },
            {
                title: 'Insurance Fees',
                key: 'insuranceFees',
                dataIndex: 'insuranceFees',
                align: 'center',
            },
            {
                title: 'Claim Status',
                key: 'claimStatus',
                dataIndex: 'claimStatus',
                align: 'center',
                render: (value) => value && <Tag color={`${value === 'CANCELLED' ? '#f50' : value === 'GREEN' ? '#87d068' : value === 'PENDING' ? 'gray' : '#2db7f5'}`}>{value}</Tag>
            },
            {
                title: 'Detail',
                key: 'detail',
                dataIndex: '',
                textAlign: 'center',
                align: 'center',
                render: (record) => <Button type="primary" onClick={() => this.setState({ isDrawerOpen: true, insuranceDetail: record })}>View</Button>
            },
        ]

        return (
            <>
                <Drawer
                    width="30%"
                    height={500}
                    closable={false}
                    onClose={() => this.setState({ isDrawerOpen: false })}
                    open={isDrawerOpen}
                    extra={
                        <Button onClick={() => this.setState({ isDrawerOpen: false })}>Close</Button>
                    }
                >
                    <Card style={{ marginBottom: 30, padding: 10 }}>
                        <Row>
                            <Col span={12}>
                                <NJVLabel label="Receiver Name" />
                                <div>{insuranceDetail?.receiverName}</div>
                            </Col>
                            <Col span={12}>
                                <NJVLabel label="Receiver Address" />
                                <div>{insuranceDetail?.receiverAddress}</div>
                            </Col>
                            {
                                insuranceDetail?.parcelCondition &&
                                <>
                                    <Col span={24}>
                                        <Divider style={{ margin: '13px 0px' }} />
                                    </Col>
                                    <Col span={12}>
                                        <NJVLabel label="Parcel Condition" />
                                        <div>{insuranceDetail?.parcelCondition}</div>
                                    </Col>
                                </>
                            }
                            {
                                insuranceDetail?.claimReason &&
                                <Col span={12}>
                                    <NJVLabel label="Claim Reason" />
                                    <div>{insuranceDetail?.claimReason}</div>
                                </Col>
                            }
                            {
                                insuranceDetail?.claimRequestImageList.length > 0 &&
                                <>
                                    <Col span={24}>
                                        <Divider style={{ margin: '13px 0px' }} />
                                    </Col>
                                    <Col span={24}>
                                        <NJVLabel label="Photos" />
                                        {
                                            insuranceDetail?.claimRequestImageList?.map((img) => <Image src={img} width={60} height={60} alt="claim photos" style={{ objectFit: 'cover' }} />)
                                        }
                                    </Col>
                                </>
                            }
                        </Row>
                    </Card>
                    {
                        insuranceDetail?.insuranceItems.length > 0 && (
                            <Row
                                style={{ padding: 10, borderRadius: 8, backgroundColor: '#f1f1f1', marginBottom: 0.7 }}>
                                <Col span={2}>
                                    <span>No.</span>
                                </Col>
                                <Col span={14} style={{ textAlign: 'center' }}>
                                    <span>Product Name</span>
                                </Col>
                                <Col span={3}>
                                    <span>Price</span>
                                </Col>
                                <Col span={5}>Quantity</Col>
                            </Row>
                        )
                    }
                    <>
                        {
                            insuranceDetail?.insuranceItems.map((item, index) => {

                                return (
                                    <Row
                                        key={index}
                                        style={{ padding: 10, borderRadius: 8, backgroundColor: '#f1f1f1', marginBottom: 1, textAlign: 'center' }}>
                                        <Col span={2}>
                                            <span>{index + 1}</span>
                                        </Col>
                                        <Col span={14}>
                                            <span>{item.name}</span>
                                        </Col>
                                        <Col span={3}>
                                            <span>{item.price}</span>
                                        </Col>
                                        <Col span={5}>
                                            <span>{item.quantity}</span>
                                        </Col>
                                    </Row>
                                )
                            })
                        }
                    </>
                </Drawer>
                <Row style={{ alignItems: 'center' }} gutter={[20, 20]}>
                    <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
                        <TitleLevel3 label={"Insurance Report"} />
                    </Col>
                    <Col span={12} style={{ display: 'flex', justifyContent: 'right' }}>
                        <Button size="large" loading={loading.isDownloading}
                            onClick={() => this.downloadInsuranceExcel()} type="primary" style={{ backgroundColor: 'green' }}>Export Excel</Button>
                    </Col>
                    <Col span={24}>
                        <Row gutter={[16, 16]} style={{ marginBottom: 20 }}>
                            <Col span={6}>
                                <Input
                                    onChange={(e) => this.handleFilter('trackingNumber', e.target.value)}
                                    style={{ background: '#f1f1f1', height: 40, marginRight: 8 }}
                                    placeholder="Tracking ID"
                                    name="id"
                                    autoComplete='off'
                                />
                            </Col>
                            <Col span={6}>
                                <Input
                                    onChange={(e) => this.handleFilter('senderName', e.target.value)}
                                    style={{ background: '#f1f1f1', height: 40, marginRight: 8 }}
                                    placeholder="Sender Name"
                                    name="senderName"
                                    autoComplete='off'
                                />
                            </Col>
                            <Col span={6}>
                                <DatePicker style={{ background: '#f1f1f1', height: 40, width: '100%' }} onChange={(value) => this.handleFilter('policyStartDate', value)} />
                            </Col>
                            <Col span={6}>
                                <Button onClick={() => this.search()} type="primary" style={{ borderRadius: 10, height: 40, width: '100%' }}>Search</Button>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        {
                            loading.isDataFetching ? <Skeleton active /> :
                                <Table
                                    pagination={{
                                        position: 'top' | 'bottom',
                                        total: totalPagination,
                                        current: page,
                                        onChange: this.handlePaginationChange,
                                        defaultPageSize: pageSize,
                                        showSizeChanger: false
                                    }}
                                    columns={columns}
                                    dataSource={data}
                                    rowClassName={getRowClassName}
                                />
                        }
                    </Col>
                </Row>
            </>
        )
    }
}

export default compose(withRouter)(InsuranceReport)


