import { createSlice } from '@reduxjs/toolkit'

export const profileSlice = createSlice({
    name: 'profile',
    initialState: {
        fullName: '',
        sosCount: 0,
        roleType: null,
        roleName: '',
    },
    reducers: {
        updateUserName: (state, action) => {
            state.fullName = action.payload
        },
        updateRoleType: (state, action) => {
            state.roleType = action.payload
        },
        updateRoleName: (state, action) => {
            state.roleName = action.payload
        },
        updateSos: (state, action) => {
            state.sosCount = action.payload
        },
        clearProfile: (state) => {
            state.shipperApprovalCount = 0
            state.roleType = null
        }
    },
})

export const { updateProfile, clearProfile, updateUserName, updateSos, updateApproval, updateRoleType ,updateRoleName} = profileSlice.actions
export const fullName = (state) => state.profile.fullName
export const sosCount = (state) => state.profile.sosCount
export const roleType = (state) => state.profile.roleType
export const roleName = (state) => state.profile.roleName
export default profileSlice.reducer
