import { createSlice } from '@reduxjs/toolkit'
import { LOC_clear_token } from '../core/custom-local-storage'

export const cookieSlice = createSlice({
    name: 'cookie',
    initialState: {
        currentRoute: '',
    },
    reducers: {
        updateCurrentRoute: (state, action) => {
            state.currentRoute = action.payload
        },
        clearCookie: (state) => {
            state.currentRoute = ''
            LOC_clear_token()
        }
    },
})

export const { updateCurrentRoute, clearCookie } = cookieSlice.actions
export const currentRoute = (state) => state.cookie.currentRoute
export default cookieSlice.reducer
