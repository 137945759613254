import React from "react";
import { compose } from "@reduxjs/toolkit";
import { Button, Col, Row } from "antd";
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { Link } from "react-router-dom";
import { SvgArrowLeftFilled, SvgLocateOutlined, SvgMinusFilled, SvgPlusFilled } from "../../components/custom-svg";
import withRouter from "../../network/with-router";
import CustomPath from "../../routes/custom-path";
import { v4 as uuidv4 } from 'uuid';

const mapBoxAccessToken = process.env.REACT_APP_MAP_BOX_TOKEN

class DriverLocationLogDetail extends React.PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            data: props.location.state?.data || {},
            isDataFetching: true,
            lng: 0,
            lat: 0,
            zoom: 18,
            map: null
        }
        this.mapContainer = React.createRef()
    }
    componentDidMount() {
        const { data } = this.state
        if (data && data.locations) {
            mapboxgl.accessToken = mapBoxAccessToken
            const map = new mapboxgl.Map({
                container: this.mapContainer,
                style: 'mapbox://styles/mapbox/streets-v12',
                zoom: 20,
                projection: 'globe'
            });
            map.on('load', () => {
                this.setState({ map });
                const firstLocation = data?.locations[0]
                // map.setCenter([firstLocation.lng, firstLocation.lat]);
                map.setCenter([-122.483696, 37.833818])



                this.setState({
                    lat: firstLocation.lat,
                    lng: firstLocation.lng
                })
                let waypoints = [];




                // data?.locations?.map((location, index) => {
                //     console.log(location)
                //     if (index === 0) {
                //         waypoints.push([location.lng, location.lat])
                //     }
                // })
                // this.fetchRoute([[96.206339, 16.816519], [96.206181, 16.816404]]);
                // this.fetchRoute([[96.206181, 16.816404], [96.206237, 16.816320]]);
                // this.fetchRoute([[96.206237, 16.816320], [96.206220, 16.816290]]);
                // this.fetchRoute([[96.206220, 16.816290], [96.206144, 16.816405]]);
                // this.fetchRoute([[96.206144, 16.816405], [96.206024, 16.816576]]);

                map.addSource('route', {
                    type: 'geojson',
                    data: {
                        type: 'Feature',
                        properties: {},
                        geometry: {
                            type: 'LineString',
                            coordinates: [
                                [-122.483696, 37.833818],
                                [-122.483482, 37.833174],
                                [-122.483396, 37.8327],
                                [-122.483568, 37.832056],
                                [-122.48404, 37.831141],
                                [-122.48404, 37.830497],
                                [-122.483482, 37.82992],
                                [-122.483568, 37.829548],
                                [-122.48507, 37.829446],
                                [-122.4861, 37.828802],
                                [-122.486958, 37.82931],
                                [-122.487001, 37.830802],
                                [-122.487516, 37.831683],
                                [-122.488031, 37.832158],
                                [-122.488889, 37.832971],
                                [-122.489876, 37.832632],
                                [-122.490434, 37.832937],
                                [-122.49125, 37.832429],
                                [-122.491636, 37.832564],
                                [-122.492237, 37.833378],
                                [-122.493782, 37.833683]
                            ]
                        }
                    }
                });

                map.addLayer({
                    id: 'route',
                    type: 'line',
                    source: 'route',
                    layout: {
                        'line-join': 'round',
                        'line-cap': 'round'
                    },
                    paint: {
                        'line-color': '#888',
                        'line-width': 8
                    }
                });

            })

            // const route = {
            //     type: 'Feature',
            //     properties: {},
            //     geometry: {
            //         type: 'LineString',
            //         coordinates: [[96.2027589177123,16.82116391471989], [96.206181, 16.816404], [96.206237, 16.816320], [96.206220, 16.816290], [96.206144, 16.816405], [96.206024, 16.816576]]
            //     }
            // };



            // map.on('load', function () {
            //     map.addSource('route', {
            //         type: 'geojson',
            //         data: route
            //     })

            //     map.addLayer({
            //         id: 'route',
            //         type: 'line',
            //         source: 'route',
            //         // layout: {
            //         //     'line-join': 'round',
            //         //     'line-cap': 'round'
            //         // },
            //         paint: {
            //             'line-color': 'black',
            //             'line-width': 3
            //         }
            //     });


            // });
            map.addControl(new mapboxgl.NavigationControl(), 'top-right');

            new mapboxgl.Marker()
                .setLngLat([96.177311, 16.787592])
                .addTo(map);




        } else {
            mapboxgl.accessToken = mapBoxAccessToken
            const map = new mapboxgl.Map({
                container: this.mapContainer,
                style: 'mapbox://styles/mapbox/streets-v11',
            });
            map.on('load', () => {
                this.setState({ map });
            })
            new mapboxgl.Marker()
                .setLngLat([-74.5, 40])
                .addTo(map);
        }


    }
    componentWillUnmount() {
        if (this.state.map) {
            this.state.map.remove();
        }
    }
    fly = () => {
        if (this.state.map) {
            this.state.map.flyTo({
                center: [this.state.lng, this.state.lat],
                essential: true
            });
        }
    }
    fetchRoute = async (waypoints) => {
        try {
            const coordinates = waypoints.map(point => point.join(',')).join(';');
            fetch(`https://api.mapbox.com/directions/v5/mapbox/driving/${coordinates}?geometries=geojson&access_token=${mapBoxAccessToken}`)
                .then(response => response.json())
                .then(data => {
                    console.log(data)
                    const route = data.routes[0].geometry;
                    this.state.map.addLayer({
                        id: uuidv4(),
                        type: 'line',
                        source: {
                            type: 'geojson',
                            data: {
                                type: 'Feature',
                                geometry: route
                            }
                        },
                        paint: {
                            'line-width': 3,
                            'line-color': 'black',
                        }
                    });
                    const startCoordinates = data.waypoints[0].location;
                    const endCoordinates = data.waypoints[data.waypoints.length - 1].location;

                    const endMarkerElement = document.createElement('div');
                    const endSvgElement = document.createElementNS("http://www.w3.org/2000/svg", "svg");
                    endSvgElement.setAttribute('width', '24');
                    endSvgElement.setAttribute('height', '24');
                    endSvgElement.innerHTML = `<path d="m19.071 17.213a10.141 10.141 0 0 0 0-14.259 9.94 9.94 0 0 0 -14.142 0 10.14 10.14 0 0 0 .026 14.284l3.584 3.339a4.92 4.92 0 0 0 6.9.018zm-5.729-2.564a3.023 3.023 0 0 1 -2.683 0l-2-1a2.986 2.986 0 0 1 -1.659-2.684v-2.965a2.986 2.986 0 0 1 1.658-2.685l2-1a3.014 3.014 0 0 1 2.683 0l2 1a2.986 2.986 0 0 1 1.659 2.685v2.966a2.986 2.986 0 0 1 -1.658 2.684zm1.607-6.955a1 1 0 0 1 .051.306v2.966a.994.994 0 0 1 -.553.895l-2 1a1.006 1.006 0 0 1 -.895 0l-2-1a.994.994 0 0 1 -.552-.896v-2.965a1 1 0 0 1 .051-.3l2.18 1.09a1.72 1.72 0 0 0 1.538 0zm5.051 15.306a1 1 0 0 1 -1 1h-14a1 1 0 0 1 0-2h14a1 1 0 0 1 1 1z" fill='red'/>`;
                    endMarkerElement.appendChild(endSvgElement);

                    const startMarkerElement = document.createElement('div');
                    const startSvgElement = document.createElementNS("http://www.w3.org/2000/svg", "svg");
                    startSvgElement.setAttribute('width', '24');
                    startSvgElement.setAttribute('height', '24');
                    startSvgElement.innerHTML = `<path d="M11,1H3A3,3,0,0,0,0,4V14H14V4A3,3,0,0,0,11,1ZM4,11H2V4H4Zm4,0H6V4H8Zm2,0V4h2v7H10m6,0V5h3.559A3,3,0,0,1,22.4,7.051L23.721,11ZM0,18V16H16V13h8v5Zm.058,2c-.587,3.954,5.472,3.952,4.884,0Zm7,0c-.587,3.954,5.472,3.952,4.884,0Zm10,0c-.587,3.954,5.472,3.952,4.884,0Z"  fill='red'/>`;
                    startMarkerElement.appendChild(startSvgElement);

                    // new mapboxgl.Marker(startMarkerElement).setLngLat(startCoordinates).addTo(this.state.map);
                    // new mapboxgl.Marker(endMarkerElement).setLngLat(endCoordinates).addTo(this.state.map);
                });
        } catch (error) {

        }
    }
    zoomIn() {
        const { map } = this.state;
        map.zoomIn();
    }
    zoomOut() {
        const { map } = this.state;
        map.zoomOut();
    }
    render() {
        const { data } = this.state
        return (
            <Row gutter={[16, 16]}>
                <Col span={24} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Link to={CustomPath.driver_location_log}>
                            <Button type="primary" shape="circle" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: 10 }}>
                                <SvgArrowLeftFilled width={20} height={20} color={'#fff'} />
                            </Button>
                        </Link>

                        <span style={{ fontSize: 18, fontWeight: '600' }}>{`${data?.driver?.specialCode} (${data?.driver?.fullName}) - ${data?.driver?.phoneNumber} (${data?.driver?.assignTownship?.division?.name}/${data?.driver?.assignTownship?.name})`}</span>
                    </div>
                    <span style={{ fontSize: 18, fontWeight: '600' }}>{data?.date}</span>

                </Col>
                <Col span={24}>
                    <div
                        ref={el => (this.mapContainer = el)}
                        style={{ width: '100%', height: '70vh', borderRadius: 5 }} />
                    <div style={{ position: 'absolute', bottom: 20, right: 20 }}>
                        <Button type="primary" shape="circle" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 5 }} onClick={() => this.zoomIn()}><SvgPlusFilled width={25} height={25} color={'#fff'} /></Button>
                        <Button type="primary" shape="circle" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={() => this.zoomOut()}><SvgMinusFilled width={25} height={25} color={'#fff'} /></Button>
                    </div>
                    <div style={{ position: 'absolute', top: 20, right: 20 }}>
                        <Button type="primary" shape="circle" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={() => this.fly()}><SvgLocateOutlined width={20} height={20} color={'#fff'} /></Button>
                    </div>
                </Col>
            </Row>
        )
    }
}

export default compose(withRouter)(DriverLocationLogDetail)