import { SvgA, SvgC, SvgD, SvgH, SvgL, SvgN, SvgP, SvgR, SvgS } from "../components/custom-svg";

export const GetListFromEnumObject = (enumlist) => {
    let temp = []
    enumlist.map((res, index) => {
        temp.push({
            key: index + 1,
            value: res.key,
            label: res.value,
        });
    })
    return temp
}

export const GenerateSvgIconBaseOnName = (name) => {
    const firstWord = name.charAt(0);
    let svgIcon;
    if (firstWord === 'A') {
        svgIcon = <SvgA width={25} height={25} color={'gray'} />
    } else if (firstWord === 'A') {
        svgIcon = <SvgA width={25} height={25} color={'gray'} />
    }else if (firstWord === 'B') {
        svgIcon = <SvgA width={25} height={25} color={'gray'} />
    } else if (firstWord === 'C') {
        svgIcon = <SvgC width={25} height={25} color={'gray'} />
    } else if (firstWord === 'D') {
        svgIcon = <SvgD width={25} height={25} color={'gray'} />
    } else if (firstWord === 'E') {
        svgIcon = <SvgA width={25} height={25} color={'gray'} />
    } else if (firstWord === 'F') {
        svgIcon = <SvgA width={25} height={25} color={'gray'} />
    } else if (firstWord === 'G') {
        svgIcon = <SvgA width={25} height={25} color={'gray'} />
    } else if (firstWord === 'H') {
        svgIcon = <SvgH width={25} height={25} color={'gray'} />
    } else if (firstWord === 'I') {
        svgIcon = <SvgA width={25} height={25} color={'gray'} />
    } else if (firstWord === 'J') {
        svgIcon = <SvgA width={25} height={25} color={'gray'} />
    } else if (firstWord === 'K') {
        svgIcon = <SvgA width={25} height={25} color={'gray'} />
    } else if (firstWord === 'L') {
        svgIcon = <SvgL width={25} height={25} color={'gray'} />
    } else if (firstWord === 'M') {
        svgIcon = <SvgA width={25} height={25} color={'gray'} />
    } else if (firstWord === 'N') {
        svgIcon = <SvgN width={25} height={25} color={'gray'} />
    } else if (firstWord === 'O') {
        svgIcon = <SvgA width={25} height={25} color={'gray'} />
    } else if (firstWord === 'P') {
        svgIcon = <SvgP width={25} height={25} color={'gray'} />
    } else if (firstWord === 'Q') {
        svgIcon = <SvgA width={25} height={25} color={'gray'} />
    } else if (firstWord === 'R') {
        svgIcon = <SvgR width={25} height={25} color={'gray'} />
    } else if (firstWord === 'S') {
        svgIcon = <SvgS width={25} height={25} color={'gray'} />
    } else if (firstWord === 'T') {
        svgIcon = <SvgA width={25} height={25} color={'gray'} />
    } else if (firstWord === 'U') {
        svgIcon = <SvgA width={25} height={25} color={'gray'} />
    } else if (firstWord === 'V') {
        svgIcon = <SvgA width={25} height={25} color={'gray'} />
    } else if (firstWord === 'W') {
        svgIcon = <SvgA width={25} height={25} color={'gray'} />
    } else if (firstWord === 'X') {
        svgIcon = <SvgA width={25} height={25} color={'gray'} />
    } else if (firstWord === 'Y') {
        svgIcon = <SvgA width={25} height={25} color={'gray'} />
    } else if (firstWord === 'Z') {
        svgIcon = <SvgA width={25} height={25} color={'gray'} />
    }
    return (svgIcon)
}

