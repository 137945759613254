import React, { Component } from 'react'
import NJVTable from '../../components/NJVTable'
import { formatDateDigit, formatDateTime } from '../../components/format'
import { Button, Col, DatePicker, Row, Tag } from 'antd'
import { TitleLevel3 } from '../../components/general-component'
import { NJVInput, NJVSelect } from '../../components/core-component'
import Theme from '../../components/theme'
import withRouter from '../../network/with-router'
import { compose } from '@reduxjs/toolkit'
import FetchSearchUrl from '../../components/fetchSearchUrl'
import SearchFilter from '../../components/searchFilter'
import dayjs from 'dayjs'
import Api from '../../network/api'
const { RangePicker } = DatePicker

const colorOfType = (value) => {
  return value === 'TRANSFER' ? '#007bff' : value === 'DEPOSIT' ? '#28a745' : value === 'WITHDRAWAL' ? '#dc3545' : null;
}

const transaction_collection = [
  {
    value: 'DEPOSIT', label: 'Deposit',
  },
  {
    value: 'WITHDRAWAL', label: 'Withdrawl'
  },
  {
    value: 'TRANSFER', label: 'Transfer'
  }
]

class TransactionHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterData: {}
    }
  }

  componentDidMount() {
    const { filterData } = FetchSearchUrl()

    let formattedFilterData = { ...filterData };

    if (filterData?.fromDate && filterData?.toDate) {
      formattedFilterData.fromDate = dayjs(filterData?.fromDate)
      formattedFilterData.toDate = dayjs(filterData?.toDate)
    }
    if (filterData['fromDate-toDate']) {
      formattedFilterData['fromDate-toDate'] = [dayjs(filterData?.fromDate), dayjs(filterData?.toDate)];
    }

    this.formattedParmas(filterData)
    this.setState({
      filterData: formattedFilterData
    })
  }

  formattedParmas = (filterData) => {

    let params = {};
    if (filterData) {
      params = {
        'fromDate': (filterData['fromDate-toDate'] && filterData.fromDate) && formatDateDigit(filterData?.fromDate),
        'toDate': (filterData['fromDate-toDate'] && filterData.toDate) && formatDateDigit(filterData?.toDate),
        'fromDate-toDate': filterData['fromDate-toDate'] && `${formatDateDigit(filterData['fromDate-toDate'][0])}&${formatDateDigit(filterData['fromDate-toDate'][1])}`,
        'senderName': filterData?.senderName,
        'walletTransactionType': filterData?.walletTransactionType
      }
    }
    this.setState({
      params
    })
  }

  search = () => {
    const { filterData } = this.state;

    let formattedFilterData = { ...filterData };
    if (filterData?.fromDate) {
      formattedFilterData.fromDate = formatDateDigit(filterData.fromDate);
    }
    if (filterData?.toDate) {
      formattedFilterData.toDate = formatDateDigit(filterData.toDate);
    }

    if (filterData?.['fromDate-toDate']) {
      formattedFilterData['fromDate-toDate'] = `${formatDateDigit(filterData['fromDate-toDate'][0])}&${formatDateDigit(filterData['fromDate-toDate'][1])}`;
    }
    else {
      if (!filterData.fromDate) {
        formattedFilterData.fromDate = null;
      }
      if (!filterData.toDate) {
        formattedFilterData.toDate = null;
      }
    }

    const newUrl = SearchFilter(formattedFilterData);
    window.history.pushState({}, '', newUrl)
    this.reFetch(1);
  }

  handleFilter = (key, value) => {
    let { filterData } = this.state

    filterData = {
      ...filterData,
      [key]: value
    }

    if (key === 'fromDate-toDate' && value) {
      filterData = { ...filterData, fromDate: value[0], toDate: value[1], 'fromDate-toDate': value }
    } else {
      filterData = { ...filterData, 'fromDate': null, 'toDate': null }
    }

    if (key === 'walletTransactionType') {
      filterData = {
        ...filterData,
        walletTransactionType: value?.value
      }
    }

    this.setState({
      filterData
    })
    this.formattedParmas(filterData)
  }

  render() {
    const { filterData, params } = this.state

    const columns = [
      {
        title: 'From',
        key: 'from',
        render: (_, transaction) => (
          <>
            {
              transaction?.senderDTO?.fullName && <><span style={{ fontSize: 14, fontWeight: '500', color: 'gray' }}>{transaction.senderDTO.fullName}</span> <br /></>
            }
            {
              transaction?.senderDTO?.phoneNumber && <><span>{transaction.senderDTO.phoneNumber}</span> <br/></>
            }
            {
              transaction?.senderDTO?.userType && <span>{transaction.senderDTO.userType}</span>
            }
          </>
        )
      },
      {
        title: 'To',
        key: 'to',
        render: (transaction) => (
          <>
            {
              transaction?.receiverDTO?.fullName && <><span style={{ fontSize: 14, fontWeight: '500', color: 'gray' }}>{transaction.receiverDTO.fullName}</span> <br/></>
            }
            {
              transaction?.receiverDTO?.phoneNumber && <><span>{transaction.receiverDTO.phoneNumber}</span> <br/></>
            }
            {
              transaction?.receiverDTO?.userType && <span>{transaction.receiverDTO.userType}</span>
            }
          </>
        )
      },
      {
        title: 'Amount',
        key: 'amount',
        dataIndex: 'amount',
        align: 'center',
        width: 100
      },
      {
        title: 'Transaction Type',
        key: 'walletTransactionType',
        dataIndex: 'walletTransactionType',
        align: 'center',
        width: 250,
        render: (type) => <Tag color={colorOfType(type)}>{type}</Tag>
      },
      {
        title: 'Date',
        key: 'date',
        dataIndex: 'createdDate',
        render: (value) => formatDateTime(value)
      }
    ]

    return (
      <Row gutter={[16, 16]}>
        <Col span={24} style={{ display: 'flex', alignItems: 'center' }}>
          <TitleLevel3 label={"Wallet Transaction History"} />
        </Col>
        <Col span={24}>
          <Row gutter={[16, 16]} style={{ marginBottom: 20 }}>
            <Col span={6}>
              <NJVInput
                value={filterData?.businessName}
                onChange={(e) => this.handleFilter('senderName', e.target.value)}
                style={{ background: '#f1f1f1', height: 40, marginRight: 8 }}
                placeholder="Sender Name"
                name="senderName"
                autoComplete='off'
              />
            </Col>
            <Col span={6}>
              <NJVSelect
                size="large"
                allowClear
                placeholder="Select Transaction Type"
                style={{ width: '100%' }}
                bgcolor={Theme.colors.input_bg_color}
                onChange={(_, object) => this.handleFilter('walletTransactionType', object)}
                options={
                  transaction_collection && transaction_collection?.map((type, index) => {
                    return {
                      key: index,
                      value: type.value,
                      label: type.label
                    }
                  })
                }
              />
            </Col>
            <Col span={6}>
              <RangePicker size="large" style={{ width: '100%', background: '#f5f5f5' }}
                value={filterData?.['fromDate-toDate']}
                allowClear
                onChange={(value) => this.handleFilter('fromDate-toDate', value)}
              />
            </Col>
            <Col span={6}>
              <Button onClick={() => this.search()} type="primary" style={{ height: 40, width: 200, marginLeft: 10 }}>Search</Button>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <NJVTable
            columns={columns}
            handleRefetch={fetchData => this.reFetch = fetchData}
            params={params}
            apiUrl={Api.wallet_transactions}
          />
        </Col>
      </Row>
    )
  }
}

export default compose(withRouter)(TransactionHistory)