import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyBgp6l0YqSsUcISnMip9QUIsb5vwtLUAcQ",
    authDomain: "admin-portal-c9703.firebaseapp.com",
    projectId: "admin-portal-c9703",
    storageBucket: "admin-portal-c9703.appspot.com",
    messagingSenderId: "684349790997",
    appId: "1:684349790997:web:5e696b21dd109ce75be71c",
    measurementId: "G-LHLWXH3H6G"
}

const app = initializeApp(firebaseConfig)

export const messaging = getMessaging(app);