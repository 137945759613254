import { Breadcrumb, Button, Card, Checkbox, Col, Form, Input, Row, Select, message } from "antd";
import dayjs from 'dayjs';
import React from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import Theme from "../../../components/theme";
import { AdminPasswordValidator, NumberAndDecimelPointValidatorNullable } from "../../../components/validator";
import Api from "../../../network/api";
import { HTTP_METHOD } from "../../../network/httpMethod";
import { MEDIA_TYPE } from "../../../network/mediaType";
import { ApiHandler } from "../../../network/network-manager";
import withRouter from "../../../network/with-router";
import CustomPath from "../../../routes/custom-path";

const { TextArea } = Input

class PartnershipStoreForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            parentData: props.location.state?.data || {},
            data: [],
            roleOptions: [],
            currentDate: '',
            fileList: [],
            singleFile: {},
            isLoading: false,
            division_collection: [],
            township_collection_map: {},
            township_collection: [],
        };
        this.inputRef = React.createRef()

    }

    componentDidMount() {
        const date = dayjs();
        this.setState({ currentDate: date.subtract(18, 'year') })
        this.fetchAllDivision()
    }

    handlePhoneNumberChange = (e) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/[^0-9]/g, '');
        this.inputRef.current.setFieldsValue({ primaryPhoneNumber: numericValue })
    }

    fetchAllDivision = async () => {
        try {
            const response = await ApiHandler({ url: Api.division_get_all, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON })
            if (response) {
                let divisionList = []
                let townshipMap = {}
                response.map(division => {
                    divisionList.push(division)
                    townshipMap[division.id] = division.townshipDTOList
                })
                this.setState({
                    division_collection: divisionList,
                    township_collection_map: townshipMap
                })
                this.fetchData(townshipMap)
            }
        } catch (error) {
        }
    }

    savePartnershipAdmin = async (values) => {
        const { parentData } = this.state
        this.setState({
            isLoading: true
        })
        try {
            if (values.primaryPhoneNumber.length > 12 || values.primaryPhoneNumber.length < 5) {
                message.destroy()
                message.error("Invalid Phone Number")
                return
            }
            if (values.dateOfBirth === undefined) {
                const date = this.currentDate()
                values = { ...values, dateOfBirth: date.format("YYYY-MM-DD") }
            }

            if (!values.latitude && values.longitude) {
                message.destroy()
                message.error("Enter latitude")
                return
            } else if (values.latitude && !values.longitude) {
                message.destroy()
                message.error("Enter longitude")
                return
            }

            let requestData = {
                'firstName': values.firstName,
                'fullName': values.firstName,
                'specialCode': values.storeCode,
                'userStatus': values.active ? "ACTIVE" : "INACTIVE",
                'address1': values.address,
                'phoneNumber': values.primaryPhoneNumber,
                'approvalStatus': 'APPROVED',
                'latitude': values.latitude ? values.latitude : null,
                'longitude': values.longitude ? values.longitude : null,
                'township': {
                    'id': values.townshipId,
                },
                "accounts": [
                    {
                        "loginId": values.storeCode,
                        "primaryPhoneNumber": values.primaryPhoneNumber,
                        "password": values.password
                    }
                ],
                'partnership': {
                    id: parentData.partnershipAdminId
                }
            }
            const response = await ApiHandler({ url: Api.partnership_store, method: HTTP_METHOD.POST, mediaType: MEDIA_TYPE.JSON, requestData })
            if (response.status === 200) {
                this.gotoStore()
            }
        } catch (error) {
        }
        this.setState({
            isLoading: false
        })
    }

    gotoStore = () => {
        const { parentData } = this.state
        let data = {
            id: parentData.partnershipAdminId,
            name: parentData.partnershipAdminName
        }
        this.props.navigate(
            CustomPath.partnership_store,
            { state: { data: data } }
        );
    }


    disabledDate = (current) => {
        const eighteenYearsAgo = dayjs().subtract(18, 'year');
        return current && current > eighteenYearsAgo;
    };

    currentDate = () => {
        return dayjs().subtract(18, 'year')
    }

    handleDivisionSelect = (value) => {
        const { township_collection_map } = this.state
        this.setState({
            township_collection: township_collection_map[value],
        })
        this.inputRef.current.setFieldsValue({ townshipId: null });
    }


    render() {
        const { navigate } = this.props
        const { isLoading, division_collection, township_collection } = this.state
        return (
            <>
                <Breadcrumb
                    items={[
                        {
                            title: 'Home',
                        },
                        {
                            title: <Link to={CustomPath.partnership_admin}>Partnership Admin</Link>,
                        },
                        {
                            title: <Link to={CustomPath.partnership_store}>Store</Link>,
                        },
                        {
                            title: 'Form',
                        }
                    ]}
                    style={{ marginBottom: "20px" }}
                />
                <Card
                    style={{ backgroundColor: Theme.colors.card_bg_color, boxShadow: 'none', marginBottom: "20px" }}
                    bordered={false}
                    title={"Partnership Store Form"}
                    extra={<Button type="primary" onClick={() => this.gotoStore()}>Back</Button>}
                >
                    <Form
                        layout="vertical"
                        ref={this.inputRef}
                        initialValues={{ gender: 'MALE', active: true }}
                        onFinish={this.savePartnershipAdmin}
                    >
                        <Row gutter={[16, 16]}>
                            <Col span={12} >
                                <Form.Item name="firstName" label={"Store Name"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <Input placeholder={"Enter Store name"} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="primaryPhoneNumber"
                                    label={"Phone Number"}
                                >
                                    <Input placeholder={"979xxxxxxxx"} />
                                </Form.Item>
                                {/* rules={[
                                                { required: true, message: '*(requried)' }
                                            ]} */}
                                {/* onChange={this.handlePhoneNumberChange} */}
                            </Col>
                            <Col span={12} >
                                <Form.Item name="storeCode" label={"Store Code"} rules={[{ required: true, message: '*(requried)' },
                                {
                                    pattern: /^[a-zA-Z0-9]+$/, message: 'Store Code must contain only letters and numbers'
                                }
                                ]}>
                                    <Input placeholder={"Enter store code"} />
                                </Form.Item>
                            </Col>
                            <Col span={12} >
                                <Form.Item name="password" label={"Password"}
                                    rules={[{ required: true, message: '*(requried)' },
                                    { validator: AdminPasswordValidator }]} style={{ cursor: 'pointer' }}>
                                    <Input.Password size="small" placeholder="Enter password" />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item name="divisionId" label={"Division/State"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <Select
                                        className="my-location-selector"
                                        placeholder={"Select Division"}
                                        onChange={this.handleDivisionSelect}
                                        size="large"
                                        options={
                                            division_collection && division_collection.map((division) => {
                                                return {
                                                    value: division.id,
                                                    label: division.name,
                                                    type: 'division'
                                                }
                                            })
                                        }
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item name="townshipId" label={"Township/City"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <Select
                                        className="my-location-selector"
                                        placeholder={"Select township"}
                                        onChange={this.handleTownshipSelect}
                                        size="large"
                                        options={
                                            township_collection && township_collection.map((township) => {
                                                return {
                                                    value: township.id,
                                                    label: township.name,
                                                    type: 'division'
                                                }
                                            })
                                        }
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={24} >
                                <Form.Item name="address" label={"Store Address"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <TextArea
                                        style={{ height: 60 }} placeholder={"Enter store address"} />
                                </Form.Item>
                            </Col>
                            <Col span={12} >
                                <Form.Item name="latitude" label={"Latitude"} rules={[{ validator: NumberAndDecimelPointValidatorNullable }]}>
                                    <Input placeholder={"Enter lattitude"} />
                                </Form.Item>
                            </Col>
                            <Col span={12} >
                                <Form.Item name="longitude" label={"Longitude"} rules={[{ validator: NumberAndDecimelPointValidatorNullable }]}>
                                    <Input placeholder={"Enter longitude"} />
                                </Form.Item>
                            </Col>

                            <Col span={24}>
                                <Form.Item
                                    name="active"
                                    valuePropName="checked">
                                    <Checkbox>Active</Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>



                        <Form.Item style={{ textAlign: 'end' }}>
                            <Button type="primary" htmlType="submit" loading={isLoading}>Create</Button>
                        </Form.Item>
                    </Form>
                </Card>
            </>
        );
    }
}

export default compose(withRouter)(PartnershipStoreForm)