import { PlusOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Card, Checkbox, Col, DatePicker, Form, Input, Modal, Row, Select, Upload, message } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import Theme from "../../../components/theme";
import { AdminPasswordValidator, CharacterLimit, NumberOnly, PhoneNumberValidator } from "../../../components/validator";
import Api from "../../../network/api";
import { HTTP_METHOD } from "../../../network/httpMethod";
import { MEDIA_TYPE } from "../../../network/mediaType";
import { ApiFilter, ApiHandler } from "../../../network/network-manager";
import withRouter from "../../../network/with-router";
import CustomPath from "../../../routes/custom-path";

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const { TextArea } = Input
const EMPLOYMENT_TYPE = [
    {
        value: 'Permanent',
        label: 'Permanent'
    },
    {
        value: 'Contract',
        label: 'Contract'
    }
]
const RANK_TYPE = [
    {
        value: 1,
        label: 1
    },
    {
        value: 2,
        label: 2
    },
    {
        value: 3,
        label: 3
    },
    {
        value: 4,
        label: 4
    },
    {
        value: 5,
        label: 5
    },
    {
        value: 6,
        label: 6
    },
    {
        value: 7,
        label: 7
    },
    {
        value: 8,
        label: 8,
    },
    {
        value: 9,
        label: 9
    },
    {
        value: 10,
        label: 10
    }

]
class EmployeeForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],

            previewImage: null,
            previewTitle: null,
            roleData: [],
            roleOptions: [],
            currentDate: '',
            profileFileList: [],
            isLoading: false,
            previewOpen: false,
            division_collection: [],
            township_collection_map: {},
            township_collection: [],
            department_collection: [],
            team_collection: [],
        };
        this.inputRef = React.createRef()
    }

    componentDidMount = async () => {
        await Promise.all([
            this.fetchAllDivision(),
            this.fetchData()
        ])


    }

    fetchData = async () => {
        await ApiHandler({ url: Api.department_filter, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON })
            .then(response => {
                let temp = []
                response.forEach((res) => {
                    temp.push({
                        key: res.id,
                        value: res.id,
                        label: res.name,
                    });
                });

                this.setState({
                    department_collection: temp,
                });
            }).catch(error => {
            }).finally(() => {
            })
    }

    fetchTeamData = async (id) => {

        await ApiHandler({ url: Api.team_filter, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, specificId: id })
            .then(response => {
                let temp = []
                response.forEach((res) => {
                    temp.push({
                        key: res.id,
                        value: res.id,
                        label: res.name,
                    });
                });

                this.setState({
                    team_collection: temp,
                });
            })
            .catch(error => { })
            .finally(() => { })

    }




    fetchAllDivision = async () => {
        try {
            const response = await ApiFilter(Api.division_get_all, null, this.props)
            if (response) {
                let divisionList = []
                let townshipMap = {}
                response.forEach(division => {
                    divisionList.push(division)
                    townshipMap[division.id] = division.townshipDTOList
                })
                this.setState({
                    division_collection: divisionList,
                    township_collection_map: townshipMap
                })
                this.fetchData(townshipMap)
            }
        } catch (error) {
        }
    }

    handleDivisionSelect = (value) => {
        const { township_collection_map } = this.state
        this.setState({
            township_collection: township_collection_map[value],
        })
        this.inputRef.current.setFieldsValue({ townshipId: null });
    }


    saveEmployee = async (values) => {
        this.setState({
            isLoading: true
        })
        if (values.password !== values.confirmPassword) {
            message.destroy()
            message.error("Confirm password does not match")
            this.setState({
                isLoading: false
            })
            return
        }
        let formData = new FormData()
        formData.append('firstName', values.firstName);
        formData.append('lastName', values.lastName);
        formData.append('fullName', values.firstName + " " + values.lastName);
        formData.append("phoneNumber", values.phoneNumber)
        formData.append("emailAddress", values.emailAddress)
        formData.append("address1", values.address1)
        formData.append("township.id", values.townshipId)
        formData.append('approvalStatus', 'APPROVED');
        formData.append('userStatus', values.active ? "ACTIVE" : "INACTIVE");
        formData.append("gender", values.gender)
        formData.append("dateOfBirth", values.dateOfBirth.format("YYYY-MM-DD"))

        formData.append("employeeId", values.prefix + values.employeeId)
        formData.append("position", values.position)
        formData.append("workLocation", values.workLocation)
        formData.append("employmentType", values.employmentType)
        formData.append("rank", values.rank)
        formData.append("joinedDate", values.joinedDate.format("YYYY-MM-DD"))
        formData.append("note", values.note || '')
        formData.append("team.id", values.team)

        formData.append('accounts[0].loginId', values.phoneNumber);
        formData.append('accounts[0].password', values.password);
        formData.append('accounts[0].accountType', "PHONE");

        if (values?.profileUrl?.file) {
            formData.append('profile', values.profileUrl.file)
        }



        await ApiHandler({ url: Api.employee, method: HTTP_METHOD.POST, mediaType: MEDIA_TYPE.FORM_DATA, requestData: formData })
            .then(() => { this.props.navigate(CustomPath.employee) })
            .catch(() => { })
            .finally(() => {
                this.setState({
                    isLoading: false
                })
            })
    }



    handleChange = (type, uploadObject) => {

        if (this.checkValidFileSize(uploadObject.file)) {
            if (type === 'cover') {
                this.setState({
                    coverFileList: uploadObject.fileList
                })
            } else {
                this.setState({
                    profileFileList: uploadObject.fileList
                })
            }
        }


    }
    handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        this.setState({
            previewImage: file.url || file.preview,
            previewOpen: true,
            previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
        })
    };
    checkValidFileSize = (file) => {
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
            message.destroy()
            message.error('Image must smaller than 5MB!');
        }
        return isLt2M;
    };

    onFinishFailed = () => {
        message.destroy()
        message.error('Please fill required fields');
    }



    render() {
        const { isLoading, profileFileList, previewOpen, previewImage, previewTitle, division_collection, township_collection, department_collection, team_collection } = this.state
        return (
            <>
                <Modal
                    open={previewOpen}
                    title={previewTitle}
                    footer={null}
                    onCancel={() => this.setState({ previewOpen: false })}>
                    <img
                        alt="example"
                        style={{
                            width: '100%',
                        }}
                        src={previewImage}
                    />
                </Modal>
                <Breadcrumb
                    items={[
                        {
                            title: 'Home',
                        },
                        {
                            title: <Link to={CustomPath.employee}>Employee</Link>,
                        },
                        {
                            title: 'Form',
                        }
                    ]}
                    style={{ marginBottom: "20px" }}
                />
                <Card
                    style={{ backgroundColor: Theme.colors.card_bg_color, boxShadow: 'none', marginBottom: "20px" }}
                    bordered={false}
                    title={"Employee Form"}
                    extra={<Link to={CustomPath.employee}><Button type="primary">Back</Button></Link>}
                >
                    <Form
                        ref={this.inputRef}
                        layout="vertical"
                        initialValues={{ active: true, prefix: 'NVM' }}
                        onFinish={this.saveEmployee}
                        onFinishFailed={this.onFinishFailed}
                    >
                        <Row gutter={[24, 16]}>
                            <Col span={24}>
                                <Form.Item name="profileUrl" label={"Upload Profile"}>
                                    <Upload
                                        accept={["image/png", "image/jpg", "image/jpeg"]}
                                        listType="picture-circle"
                                        fileList={profileFileList}
                                        multiple={false}
                                        maxCount={1}
                                        action={null}
                                        onPreview={this.handlePreview}
                                        onChange={(obj) => this.handleChange('profile', obj)}
                                        beforeUpload={() => false}
                                    >
                                        {profileFileList.length >= 1 ? null :
                                            <div>
                                                <PlusOutlined />
                                                <div
                                                    style={{
                                                        marginTop: 8,
                                                    }}
                                                >
                                                    Upload
                                                </div>
                                            </div>}
                                    </Upload>
                                </Form.Item>
                            </Col>

                            <Col span={12} >
                                <Form.Item name="firstName" label={"First Name"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <Input placeholder={"Enter first name"} />
                                </Form.Item>
                            </Col>
                            <Col span={12} >
                                <Form.Item name="lastName" label={"Last Name"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <Input placeholder={"Enter last name"} />
                                </Form.Item>
                            </Col>
                            <Col span={12} >
                                <Form.Item name="phoneNumber" label={"Phone Number"} rules={[
                                    { required: true, message: '*(requried)' },
                                    { validator: PhoneNumberValidator }]}>
                                    <Input placeholder={"Enter phone number"} />
                                </Form.Item>
                            </Col>
                            <Col span={12} >
                                <Form.Item name="emailAddress" label={"Email Address"}
                                    rules={[
                                        {
                                            type: 'email',
                                            message: 'Invalid email address',
                                        }]}>
                                    <Input placeholder={"Enter email address"} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="password" label={"Password"}
                                    rules={[{ required: true, message: '*(requried)' },
                                    { validator: AdminPasswordValidator }]} style={{ cursor: 'pointer' }}>
                                    <Input.Password size="small" placeholder="Enter password" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="confirmPassword" label={"Confirm Password"}
                                    rules={[{ required: true, message: '*(requried)' },
                                    { validator: AdminPasswordValidator }]} style={{ cursor: 'pointer' }}>
                                    <Input.Password size="small" placeholder="Enter confirm password" />
                                </Form.Item>
                            </Col>
                            <Col span={12} >
                                <Form.Item name="dateOfBirth" label={"Date Of Birth"} style={{ cursor: 'pointer' }} className="custom-date-picker">
                                    <DatePicker
                                        allowClear={false}
                                        size="large" style={{ width: '100%', cursor: 'pointer' }} format={'YYYY-MM-DD'} />
                                </Form.Item>
                            </Col>
                            <Col span={12} >
                                <Form.Item name="gender" label={"Gender"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <Select
                                        size="large"
                                        style={{
                                            width: '100%',
                                        }}
                                        placeholder="Select gender"
                                        options={[
                                            {
                                                label: 'Male',
                                                value: 'MALE',
                                            },
                                            {
                                                label: 'Female',
                                                value: 'FEMALE',
                                            },
                                        ]}
                                    />
                                </Form.Item>

                            </Col>
                            <Col span={12}>
                                <Form.Item name="divisionId" label={"Division/State"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <Select
                                        className="my-location-selector"
                                        placeholder={"Select Division"}
                                        onChange={this.handleDivisionSelect}
                                        size="large"
                                        options={
                                            division_collection && division_collection.map((division) => {
                                                return {
                                                    value: division.id,
                                                    label: division.name,
                                                    type: 'division'
                                                }
                                            })
                                        }
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item name="townshipId" label={"Township/City"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <Select
                                        className="my-location-selector"
                                        placeholder={"Select township"}
                                        onChange={this.handleTownshipSelect}
                                        size="large"
                                        options={
                                            township_collection && township_collection.map((township) => {
                                                return {
                                                    value: township.id,
                                                    label: township.name,
                                                    type: 'division'
                                                }
                                            })
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="address1" label={"Address"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <TextArea
                                        style={{ height: 60 }} placeholder={"Enter address"} />
                                </Form.Item>
                            </Col>
                            <Col span={12} />
                            <Col span={12} >
                                <Row>
                                    <Col span={3}>
                                        <Form.Item name="prefix" label={"."}>
                                            <Input style={{ fontSize: 17, fontWeight: '600', textAlign: 'center' }} placeholder={"Enter employee ID"} disabled />
                                        </Form.Item>
                                    </Col>
                                    <Col span={21}>
                                        <Form.Item name="employeeId" label={"Employee ID"} rules={[{ required: true, message: '*(requried)' }, {
                                            validator: NumberOnly
                                        }]}>
                                            <Input placeholder={"Enter employee ID"} />

                                        </Form.Item>
                                    </Col>
                                </Row>

                            </Col>
                            <Col span={12} >
                                <Form.Item name="position" label={"Position"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <Input placeholder={"Enter position"} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="rank" label={"Rank"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <Select options={RANK_TYPE} size="large" style={{ width: '100%' }} placeholder="Select rank" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="employmentType" label={"Employment Type"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <Select options={EMPLOYMENT_TYPE} size="large" style={{ width: '100%' }} placeholder="Select employment type" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="department" label={"Department"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <Select onChange={(event) => this.fetchTeamData(event)} options={department_collection} size="large" style={{ width: '100%' }} placeholder="Select department" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="team" label={"Team"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <Select options={team_collection} size="large" style={{ width: '100%' }} placeholder="Select team" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="workLocation" label={"Work Location"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <Input placeholder={"Enter work location"} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="joinedDate" label={"Joined Date"} style={{ cursor: 'pointer' }} className="custom-date-picker">
                                    <DatePicker
                                        allowClear={false}
                                        placeholder="Joined Date"
                                        size="large" style={{ width: '100%', cursor: 'pointer' }} format={'YYYY-MM-DD'} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name="note" label={"Note"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <TextArea
                                        style={{ height: 60 }} placeholder={"Enter note"} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="active"
                                    valuePropName="checked">
                                    <Checkbox>Active</Checkbox>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" loading={isLoading}>Create</Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </>
        );
    }
}

export default compose(withRouter)(EmployeeForm)