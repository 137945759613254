import { Button, Col, Modal, Row, Skeleton, Table } from "antd";
import React from "react";
import { compose } from "redux";
import { Constant } from "../../../core/constant";
import Api from "../../../network/api";
import { ApiDelete, ApiFilter } from "../../../network/network-manager";
import withRouter from "../../../network/with-router";
import CustomPath from "../../../routes/custom-path";


class PricingGroupZonePage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pageSize: Constant.pageSize,
            totalPagination: 0,
            page: 1,
            searchValue: [],
            fetchUserTypes: [],
            deleteItem: null,
            nearbyTypes: [],
            filter_name: '',
            filter_type: 'ALL',
            isDataFetching: false,
            pring_group_id: props.pricingGroupId,
            pricing_group_route: props.pricingGroupRoute
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = async (pageNumber) => {
        const { page, filter_name, pageSize } = this.state
        this.setState({
            isDataFetching: true
        })
        try {

            var params = {
                size: pageSize,
                page: pageNumber ? pageNumber - 1 : page - 1,
                name: filter_name
            }
            const response = await ApiFilter(Api.zone_filter, params, this.props)
            if (response) {
                this.setState(prevState => ({
                    data: response,
                    totalPagination: response.totalElements,
                    page: pageNumber ? pageNumber : page
                }));
            }
        } catch (error) {

        }
        this.setState({
            isDataFetching: false
        })
    }


    handleSearchName = (event) => {
        if (event.target.name === 'name') {
            this.setState({ filter_name: event.target.value })
        }
    }

    handleSelect = (value) => {
        this.setState({ filter_type: value })
    }

    handlePaginationChange = (pageNumber, pageSize) => {

        this.fetchData(pageNumber);
    };

    deleteNearby = (id) => {
        this.setState({
            deleteItem: id,
            openModal: true
        })
    }
    deleteNearbyConfirm = async () => {
        const { deleteItem } = this.state
        try {
            await ApiDelete(Api.nearby, deleteItem, this.props)
        } catch (error) {

        }
        this.setState({
            openModal: false,
            deleteItem: ''
        });
        this.fetchData()
    }

    navigateToPGZoneToZoneSetup = (zoneId) => {
        const data = {
            zoneId: zoneId,
            pricingGroupId: this.state.pring_group_id,
            pricingGroupRoute: this.state.pricing_group_route
        }
        this.props.navigate(
            CustomPath.pricing_group_zone_same_city_price_setup,
            { state: { data: data } }
        );
    }

    render() {
        const { data, totalPagination, page, pageSize, openModal, isDataFetching } = this.state
        const getRowClassName = (record, index) => {
            return index % 2 === 0 ? 'blue-row' : 'black-row';
        };
        const columns = [
            {
                title: 'No.',
                key: 'index',
                render: (value, item, index) => <span>{(page - 1) * pageSize + index + 1}</span>,
                width: 5
            },
            {
                title: 'Zone Name',
                dataIndex: 'zoneName',
                key: 'zoneName',
            }, {
                title: 'Description',
                dataIndex: 'description',
                key: 'description',
            },
            {
                title: 'Action',
                key: 'action',
                dataIndex: '',
                textAlign: 'center',
                width: 60,
                render: (zone) => (
                    <Button onClick={() => this.navigateToPGZoneToZoneSetup(zone.id)} size={'middle'} style={{ paddingLeft: 10, paddingRight: 10 }} type="primary" shape="circle">Setup Same City Price</Button>
                ),
            },
        ]

        return (
            <>
                <Modal
                    title="Delete"
                    open={openModal}
                    onOk={() => this.deleteNearbyConfirm()}
                    onCancel={() => this.hideModal()}
                    okText="Confirm"
                    cancelText="Cancel"
                >
                    <p>Are you sure to delete?</p>
                </Modal>

                <Row gutter={[16, 16]}>
                    {/* <Col span={6}>
                        <Input
                            onChange={this.handleSearchName}
                            style={{ background: '#f1f1f1', height: 40, marginRight: 8 }}
                            placeholder="Search by name"
                            name="name"
                        />
                    </Col>
                    <Col span={3} className="search">
                        <Button onClick={() => this.fetchData()} type="primary" style={{ borderRadius: 10, height: 40, width: '100%' }}>Search</Button>
                    </Col> */}
                    <Col span={24}>
                        {
                            isDataFetching ?
                                <Skeleton active />
                                :
                                <Table
                                    style={{ borderRadius: 10 }}
                                    pagination={{
                                        position: 'top' | 'bottom',
                                        total: totalPagination,
                                        current: page,
                                        onChange: this.handlePaginationChange,
                                        defaultPageSize: pageSize,
                                        showSizeChanger: false

                                    }}
                                    columns={columns}
                                    dataSource={data}
                                    rowClassName={getRowClassName}
                                />
                        }
                    </Col>
                </Row>
            </>
        );
    }
}

export default compose(withRouter)(PricingGroupZonePage)