import { EditOutlined } from "@ant-design/icons";
import { Button, Col, Input, Modal, Row, Skeleton, Space, Table } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { NJVAddButton } from "../../components/core-component";
import { TitleLevel3 } from "../../components/general-component";
import { Constant } from "../../core/constant";
import Api from "../../network/api";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import { ApiHandler } from "../../network/network-manager";
import withRouter from "../../network/with-router";
import CustomPath from "../../routes/custom-path";
import SearchFilter from "../../components/searchFilter";
import FetchSearchUrl from "../../components/fetchSearchUrl";
import SearchPagination from "../../components/searchPagination";

class ZonePage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pageSize: Constant.pageSize,
            totalPagination: 0,
            page: 1,
            searchValue: [],
            fetchUserTypes: [],
            deleteItem: null,
            nearbyTypes: [],
            filter_name: '',
            filter_type: 'ALL',
            isDataFetching: false
        }
    }

    componentDidMount() {
        const { filterData, urlPage } = FetchSearchUrl()
        const { filter_name } = this.state;

        if (urlPage) {
            this.setState({
                filter_name: filterData?.name || filter_name
            }, () => this.fetchData(urlPage))
        }
    }

    fetchData = async (pageNumber) => {
        const { page, filter_name, pageSize } = this.state
        this.setState({
            isDataFetching: true
        })
        const requestParams = {
            size: pageSize,
            page: pageNumber ? pageNumber - 1 : page - 1,
            name: filter_name
        }
        await ApiHandler({ url: Api.zone_filter, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            .then(response => {
                this.setState({
                    data: response,
                    totalPagination: response.totalElements,
                    page: pageNumber ? pageNumber : page
                });
            }).catch(error => {

            }).finally(() => {
                this.setState({
                    isDataFetching: false
                })
            })
    }


    handleSearchName = (event) => {
        if (event.target.name === 'name') {
            this.setState({ filter_name: event.target.value })
        }
    }

    handleSelect = (value) => {
        this.setState({ filter_type: value })
    }

    search = () => {
        const { filter_name } = this.state;

        const filterData = {
            name: filter_name
        }

        const newUrl = SearchFilter(filterData);
        window.history.pushState({}, '', newUrl)
        this.fetchData(1)
    }

    handlePaginationChange = (pageNumber) => {
        const newUrl = SearchPagination(pageNumber)
        window.history.pushState({}, '', newUrl)
        this.fetchData(pageNumber);
    };

    deleteNearby = (id) => {
        this.setState({
            deleteItem: id,
            openModal: true
        })
    }
    deleteNearbyConfirm = async () => {
        const { deleteItem } = this.state
        try {
            await ApiHandler({ url: Api.nearby, method: HTTP_METHOD.DELETE, mediaType: MEDIA_TYPE.JSON, specificId: deleteItem })
        } catch (error) {

        }
        this.setState({
            openModal: false,
            deleteItem: ''
        });
        this.fetchData()
    }

    render() {
        const { data, totalPagination, page, pageSize, openModal, isDataFetching, filter_name } = this.state

        const currentSearchUrl = window.location.search;

        const getRowClassName = (record, index) => {
            return index % 2 === 0 ? 'blue-row' : 'black-row';
        };

        const columns = [
            {
                title: 'No.',
                key: 'index',
                render: (value, item, index) => <span>{(page - 1) * pageSize + index + 1}</span>,
                width: 5
            },
            {
                title: 'Zone Name',
                dataIndex: 'zoneName',
                key: 'zoneName',
            }, {
                title: 'Description',
                dataIndex: 'description',
                key: 'description',
            },
            {
                title: 'Action',
                key: 'action',
                dataIndex: '',
                textAlign: 'center',
                width: 60,
                render: (zone) => (
                    <Space>
                        <Link to={`${CustomPath.zone_update}/${zone.id}`} state={{ route: `${CustomPath.zone}${currentSearchUrl}` }}><Button size={'middle'} type="primary" shape="circle"><EditOutlined /></Button></Link>
                        {/* <Link to={`${CustomPath.zone_same_city_price_setup}/${zone.id}`}><Button size={'middle'} style={{ paddingLeft: 10, paddingRight: 10 }} type="primary" shape="circle">Setup Same City Price</Button></Link> */}
                    </Space>
                ),
            },
        ]

        return (
            <>
                <Modal
                    title="Delete"
                    open={openModal}
                    onOk={() => this.deleteNearbyConfirm()}
                    onCancel={() => this.hideModal()}
                    okText="Confirm"
                    cancelText="Cancel"
                >
                    <p>Are you sure to delete?</p>
                </Modal>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <TitleLevel3 label={"Zone"} />
                    </Col>
                    <Col span={6}>
                        <Input
                            value={filter_name}
                            onChange={this.handleSearchName}
                            style={{ background: '#f1f1f1', height: 40, marginRight: 8 }}
                            placeholder="Search by name"
                            name="name"
                        />
                    </Col>
                    <Col span={3} className="search">
                        <Button onClick={() => this.search()} type="primary" style={{ borderRadius: 10, height: 40, width: '100%' }}>Search</Button>
                    </Col>
                    <Col span={15} style={{ textAlign: 'right' }}>
                        <Link to={CustomPath.zone_create}><NJVAddButton /></Link>
                    </Col>
                    <Col span={24}>
                        {
                            isDataFetching ?
                                <Skeleton active />
                                :
                                <Table
                                    style={{ borderRadius: 10 }}
                                    pagination={{
                                        position: 'top' | 'bottom',
                                        total: totalPagination,
                                        current: page,
                                        onChange: this.handlePaginationChange,
                                        defaultPageSize: pageSize,
                                        showSizeChanger: false

                                    }}
                                    columns={columns}
                                    dataSource={data}
                                    rowClassName={getRowClassName}
                                />
                        }
                    </Col>
                </Row>
            </>
        );
    }
}

export default compose(withRouter)(ZonePage)