import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { compose } from "@reduxjs/toolkit";
import { Button, Col, Input, Modal, Row, Skeleton, Space, Table, Tabs, Tag } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { NJVAddButton } from "../../components/core-component";
import { TitleLevel3 } from "../../components/general-component";
import { Constant } from "../../core/constant";
import Api from "../../network/api";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import { ApiHandler } from "../../network/network-manager";
import withRouter from "../../network/with-router";
import CustomPath from "../../routes/custom-path";

const tabsItem = [
    {
        key: 'SHIPPER',
        label: 'Shipper',
    }, {
        key: 'LOYALTY',
        label: 'Loyaty',
    }, {
        key: 'DRIVER',
        label: 'Driver',
    }, {
        key: 'CUSTOMER',
        label: 'Customer',
    },
];
class FAQPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isDataFetching: false,
            pageSize: Constant.pageSize,
            totalPagination: 0,
            page: 1,
            searchValue: [],
            openModal: false,
            openCreateModal: false,
            openUpdateModal: false,
            activeStatus: true,
            updateForm: {},
            filter_question: '',
            filter_data: { 'faqTargetType': 'SHIPPER', 'question': null }
        }
    }
    componentDidMount() {
        this.fetchData()
    }

    deleteFaq = (id) => {
        this.setState({
            openModal: true,
            deleteItem: id
        });
    }

    deleteFAQConfirm = async () => {
        const { deleteItem } = this.state
        try {
            await ApiHandler({ url: Api.faq, method: HTTP_METHOD.DELETE, mediaType: MEDIA_TYPE.JSON, specificId: deleteItem, customMessage: "Your FAQ has been deleted." })
        } catch (error) {

        }
        this.setState({
            openModal: false,
            deleteItem: ''
        });
        this.fetchData()
    }

    fetchData = async (pageNumber) => {

        const { page, pageSize, filter_data } = this.state
        this.setState({
            isDataFetching: true
        })
        const requestParams = {
            size: pageSize,
            page: pageNumber ? pageNumber - 1 : page - 1,
            ...filter_data
        }
        await ApiHandler({ url: Api.faq_filter, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            .then(response => {
                this.setState({
                    data: response.content,
                    totalPagination: response.totalElements,
                    page: pageNumber ? pageNumber : page
                });
            }).catch(error => {
            }).finally(() => {
                this.setState({
                    isDataFetching: false,
                })
            })
    }

    handlePaginationChange = (pageNumber) => {
        this.fetchData(pageNumber);
    };

    changeSearchName = (event) => {
        let { filter_data } = this.state
        filter_data['question'] = event.target.value
        this.setState({
            filter_data
        })
    }
    onTabChange = (event) => {
        let { filter_data } = this.state
        filter_data['faqTargetType'] = event
        this.setState({ filter_data }, () =>
            this.fetchData(1))
    }

    render() {
        const { data, totalPagination, page, pageSize, openModal, isDataFetching } = this.state
        const getRowClassName = (record, index) => {
            return index % 2 === 0 ? 'blue-row' : 'black-row';
        };
        const columns = [
            {
                title: 'No.',
                key: 'index',
                render: (value, item, index) => <span>{(page - 1) * pageSize + index + 1}</span>,
                width: 5
            },
            {
                title: 'Question',
                dataIndex: 'question',
                key: 'question',
            }, {
                title: 'Answer',
                dataIndex: 'answer',
                key: 'answer',
            }, {
                title: 'FAQ Type',
                dataIndex: 'faqType',
                key: 'faqType',
            }, {
                title: 'Status',
                key: 'active',
                render: (_, { active }) => (
                    <>
                        {
                            (
                                active ? <Tag color="#87d068">Active</Tag> : <Tag color="#cd201f">Inactive</Tag>
                            )
                        }
                    </>
                ),

            }, {
                title: 'Action',
                key: 'action',
                dataIndex: '',
                textAlign: 'center',
                width: 60,
                render: (faq) => (
                    <Space>
                        <Link to={`${CustomPath.faq_update}/${faq.id}`}><Button size={'middle'} type="primary" shape="circle"><EditOutlined /></Button></Link>
                        <Button size={'middle'} type="primary" shape="circle" onClick={() => this.deleteFaq(faq.id)}><DeleteOutlined /></Button>

                    </Space>
                ),
            },
        ]
        return (
            <>
                <Modal
                    title="Delete"
                    open={openModal}
                    onOk={() => this.deleteFAQConfirm()}
                    onCancel={() => this.hideModal()}
                    okText="Confirm"
                    cancelText="Cancel"
                >
                    <p>Are you sure to delete?</p>
                </Modal>
                <Row gutter={[16, 16]}>
                    <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
                        <TitleLevel3 label={"FAQs"} />
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        {/* <Link to={CustomPath.faq_sorting} style={{ marginRight: 8 }}><Button>Sort Faq</Button></Link> */}
                        <Link to={CustomPath.faq_create}><NJVAddButton /></Link>
                    </Col>
                    <Col span={24}>
                        <Tabs defaultActiveKey="1" items={tabsItem} onChange={this.onTabChange} />
                    </Col>
                    <Col span={6}>
                        <Input
                            onChange={this.changeSearchName}
                            style={{ background: '#f1f1f1', height: 40, marginRight: 8 }}
                            placeholder="Search by question"
                        />
                    </Col>
                    <Col span={4}>
                        <Button size="large" onClick={() => this.fetchData()} type="primary" >Search</Button>
                    </Col>
                    <Col span={24}>
                        {
                            isDataFetching ?
                                <Skeleton active />
                                :
                                <Table
                                    // className="custom-table"
                                    style={{ borderRadius: 10 }}
                                    pagination={{
                                        position: 'top' | 'bottom',
                                        total: totalPagination,
                                        current: page,
                                        onChange: this.handlePaginationChange,
                                        defaultPageSize: pageSize
                                    }}
                                    columns={columns} dataSource={data}
                                    rowClassName={getRowClassName} />
                        }
                    </Col>
                </Row>
            </>
        );
    }
}


export default compose(withRouter)(FAQPage)