import { compose } from "@reduxjs/toolkit";
import { Avatar, Button, Card, Col, Drawer, Flex, Form, List, message, Radio, Row, Skeleton, Tag } from "antd";
import React from "react";
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TitleLevel3 } from "../../components/general-component";
import Api from "../../network/api";
import { HTTP_METHOD } from "../../network/httpMethod";
import { ApiHandler } from "../../network/network-manager";
import withRouter from "../../network/with-router";

import { CloseOutlined, EditOutlined, PlusCircleFilled, PlusOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import { NJVBackButton, NJVInput, NJVSelect } from "../../components/core-component";
import Theme, { Colors } from "../../components/theme";
import { StatusFlagOptions } from "../../core/select-option-constant";
import { MEDIA_TYPE } from "../../network/mediaType";
import CustomPath from "../../routes/custom-path";

const countOptions = [
    {
        value: 1,
        label: '1',
    },
    {
        value: 2,
        label: '2'
    },
    {
        value: 3,
        label: '3'
    },
    {
        value: 4,
        label: '4'
    },
    {
        value: 5,
        label: '5'
    }
]
const rankMap = {
    10: { value: 10, label: 'Rank 10' },
    9: { value: 9, label: 'Rank 9' },
    8: { value: 8, label: 'Rank 8' },
    7: { value: 7, label: 'Rank 7' },
    6: { value: 6, label: 'Rank 6' },
    5: { value: 5, label: 'Rank 5' },
    4: { value: 4, label: 'Rank 4' },
    3: { value: 3, label: 'Rank 3' },
    2: { value: 2, label: 'Rank 2' },
    1: { value: 1, label: 'Rank 1' },
}
const rankMapAsMap = new Map(Object.entries(rankMap).map(([key, value]) => [Number(key), value]));



const ItemTypes = {
    BOX: 'box',
};

function DraggableItem({ data }) {
    const [{ isDragging }, drag] = useDrag({
        type: ItemTypes.BOX,
        item: { data },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    return (
        <Card
            ref={drag}
            tyle={{ opacity: isDragging ? 0.5 : 1 }}
            style={{ padding: 10, marginBottom: 5, }}

            className="card-shadow">
            <Flex
                justify="space-between"
                align="center">
                <div>
                    <div>{data?.fullName}</div>
                    <div>{data?.position}</div>
                </div>
            </Flex>

        </Card>
    );
}

function DropZone({ children, id, onDrop, dropZoneId }) {
    const [{ isOver }, drop] = useDrop({
        accept: ItemTypes.BOX,
        drop: (item) => { onDrop(item) },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        }),
    });
    const styledChildren = React.Children.map(children, (child) =>
        React.cloneElement(child, {
            style: {
                ...child.props.style, // Preserve original styles
                backgroundColor: isOver && dropZoneId === id ? 'lightgreen' : 'white',        // Apply additional styles
            },
        })
    );
    return (
        <div
            ref={drop}
            style={{
                width: '100%',
                borderRadius: '15px',
            }}
        >
            {styledChildren}
        </div>
    );
}

class OrgChartUserAssignPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            orgStructureData: this.props.location.state ? this.props.location.state.data : {},
            fullName: null,
            employeeData: [],
            rankData: [],
            editingOrgProcessStageData: null,
            orgProcessStageData: [],
            orgProcessStageMap: new Map(),
            orgProcessStageUserMap: new Map(),
            dropZoneId: null,
            openCreateFormDrawer: false,
            openUpdateFormDrawer: false,
            openingDropZone: false,
            participantUpdating: false,
            is_fetching_process_stage: false,
            is_fetching_employee: false,
        }
        this.inputRef = React.createRef();
        this.updateRef = React.createRef();

    }
    componentDidMount = () => {
        Promise.all([
            this.fetchProcessStageData()
        ])
    }

    fetchParticipantData = async (orgProcessStagesId, rank) => {
        const { pageSize, fullName, orgProcessStageUserMap } = this.state
        this.setState({
            is_fetching_employee: true
        })
        let params = {
            size: pageSize,
            page: 0,
            rank,
            orgProcessStagesId,
            fullName: fullName || null
        }
        await ApiHandler({ url: Api.org_chart_participant_unassigned_participants, method: HTTP_METHOD.GET, requestParams: params })
            .then(response => {
                if (response.content && response.content.length > 0) {
                    let tempParticipants = []
                    let existingParticipants = orgProcessStageUserMap.get(orgProcessStagesId)
                    const existingParticipantMap = new Map()
                    existingParticipants && existingParticipants.forEach(existingParticipant => {
                        existingParticipantMap.set(existingParticipant.id, existingParticipant.id)
                    })
                    if (existingParticipantMap) {
                        response.content.forEach(participant => {
                            if (!existingParticipantMap.has(participant.id)) {
                                tempParticipants.push(participant)
                            }
                        })
                    } else {
                        tempParticipants = response.content
                    }
                    console.log(tempParticipants)

                    this.setState({ employeeData: tempParticipants })
                }


            }).catch((err) => { console.log(err) })
            .finally(() => {
                this.setState({
                    is_fetching_employee: false
                })
            })
    }

    fetchProcessStageData = async () => {
        const { orgStructureData } = this.state
        this.setState({
            is_fetching_process_stage: true
        })
        await ApiHandler({ url: Api.org_chart_process_stage, method: HTTP_METHOD.GET, specificId: orgStructureData.orgStructureId })
            .then(response => {
                const orgProcessStageMap = new Map()
                const orgProcessStageUserMap = new Map()
                response.forEach(item => {
                    orgProcessStageMap.set(item.id, item)
                    orgProcessStageUserMap.set(item.id, item.employeeDTOS || [])
                })
                this.setState({
                    orgProcessStageData: response,
                    orgProcessStageMap,
                    orgProcessStageUserMap
                })
            }).catch(() => { })
            .finally(() => {
                this.setState({
                    is_fetching_process_stage: false
                })
            })
    }

    createProcessStage = async (formData) => {
        let { orgProcessStageData, orgStructureData } = this.state
        const requestData = {
            ...formData,
            orgStructure: {
                id: orgStructureData.orgStructureId
            }
        }
        await ApiHandler({ url: Api.org_chart_process_stage, method: HTTP_METHOD.POST, mediaType: MEDIA_TYPE.JSON, requestData })
            .then(response => {
                orgProcessStageData.push(response.data)
                const sortedList = orgProcessStageData.sort((a, b) => b.rank - a.rank);
                this.inputRef?.current?.setFieldsValue({ rank: null, minimnumApprovalCount: null, minimnumRejectCount: null, statusFag: null })
                this.setState({ openCreateFormDrawer: false, orgProcessStageData: sortedList })
            })
            .catch(err => { })
            .finally(() => { })
    }

    updateProcessStage = async (formData) => {
        let { orgProcessStageData, orgStructureData, editingOrgProcessStageData } = this.state
        const requestData = {
            id: editingOrgProcessStageData.id,
            rank: editingOrgProcessStageData.rank,
            minimnumApprovalCount: formData.minimnumApprovalCount,
            minimnumRejectCount: formData.minimnumRejectCount,
            statusFlag: formData.statusFlag,
            orgStructure: {
                id: orgStructureData.orgStructureId
            }
        }
        await ApiHandler({ url: Api.org_chart_process_stage, method: HTTP_METHOD.PUT, mediaType: MEDIA_TYPE.JSON, requestData })
            .then(response => {
                let updatedOrgProcess = orgProcessStageData.map(orgProcessStage => {
                    if (orgProcessStage.id === response.data.id) {
                        orgProcessStage['minimnumApprovalCount'] = response.data.minimnumApprovalCount
                        orgProcessStage['minimnumRejectCount'] = response.data.minimnumRejectCount
                        orgProcessStage['statusFlag'] = response.data.statusFlag
                    }
                    return orgProcessStage;
                })

                const sortedList = updatedOrgProcess.sort((a, b) => b.rank - a.rank);
                this.updateRef?.current?.setFieldsValue({ rank: null, minimnumApprovalCount: null, minimnumRejectCount: null })
                this.setState({ openUpdateFormDrawer: false, orgProcessStageData: sortedList, editingOrgProcessStageData: null })
            })
            .catch(err => { })
            .finally(() => { })
    }

    openDropZone = (orgProcessStage) => {
        this.fetchParticipantData(orgProcessStage.id, orgProcessStage.rank)
        this.setState({ dropZoneId: orgProcessStage.id, openingDropZone: true })
    }
    closeDropZone = async () => {
        let { dropZoneId, orgProcessStageUserMap, orgProcessStageData } = this.state
        let employeeList = [];
        orgProcessStageUserMap.get(dropZoneId)?.map(item => employeeList.push(item.id))
        this.setState({
            participantUpdating: true
        })
        const requestData = {
            orgProcessStageId: dropZoneId,
            userIds: employeeList
        }
        await ApiHandler({ url: Api.org_chart_participant, method: HTTP_METHOD.PUT, mediaType: MEDIA_TYPE.JSON, requestData })
            .then(response => {
                this.setState({ dropZoneId: null, openingDropZone: false, employeeData: [] })
            })
            .catch(err => { })
            .finally(() => { this.setState({ participantUpdating: false, employeeList: [], fullName: null }) })

        if (employeeList.length === 0) {
            let temp = orgProcessStageData?.map((processStage) => ({
                ...processStage,
                statusFlag: processStage.id === dropZoneId ? 'INACTIVE' : processStage.statusFlag
            }))
            this.setState({ orgProcessStageData: temp })
        }

    }

    handleDrop = (dropEvent, id) => {
        let { orgProcessStageUserMap, dropZoneId, employeeData } = this.state

        if (id !== dropZoneId) {
            message.error("You cannot drop here")
            return;
        }
        if (orgProcessStageUserMap.has(id)) {
            let userList = orgProcessStageUserMap.get(id)
            userList.push(dropEvent.data)
            orgProcessStageUserMap.set(id, userList)
        } else {
            let userList = [dropEvent.data]
            orgProcessStageUserMap.set(id, userList)
        }
        let tempEmployeeData = []
        employeeData?.forEach(item => {
            if (item.id !== dropEvent.data.id) {
                tempEmployeeData.push(item)
            }
        })
        this.setState({ orgProcessStageUserMap, employeeData: tempEmployeeData })
    }

    onRemoveParticipant = (orgProcessStageId, participantId) => {
        let { orgProcessStageUserMap, employeeData } = this.state
        if (orgProcessStageUserMap.has(orgProcessStageId)) {
            let userList = orgProcessStageUserMap.get(orgProcessStageId)
            let temp = []
            userList && userList.forEach(user => {
                if (participantId !== user.id) {
                    temp.push(user)
                } else {
                    employeeData.push(user)
                }
            })
            orgProcessStageUserMap.set(orgProcessStageId, temp)

            this.setState({ orgProcessStageUserMap, employeeData })
        }
    }

    searchParticipantByName = () => {
        const { dropZoneId, orgProcessStageMap } = this.state
        const orgProcessStage = orgProcessStageMap.get(dropZoneId);
        this.fetchParticipantData(orgProcessStage.id, orgProcessStage.rank)

    }

    onOpenCreateRankDrawer = () => {
        const { orgProcessStageData } = this.state
        let tempRankMap = rankMapAsMap
        orgProcessStageData.forEach(orgProcessStage => {
            if (tempRankMap.has(orgProcessStage.rank)) {
                tempRankMap.delete(orgProcessStage.rank);
            }
        })
        let tempList = []
        rankMapAsMap.forEach((value, key) => {
            tempList.push(value)
        });
        const sortedList = tempList.sort((a, b) => b.value - a.value);
        this.setState({ rankData: sortedList, openCreateFormDrawer: true })
    }
    onCloseCreateRankDrawer = () => {
        this.setState({ openCreateFormDrawer: false, rankData: [] })
    }

    onOpenUpdateOrgProcessStageDrawer = (orgProcessStage) => {
        this.setState({ openUpdateFormDrawer: true, editingOrgProcessStageData: orgProcessStage },
            () => {
                this.updateRef?.current?.setFieldsValue(orgProcessStage);
            }
        )
    }
    onCloseUpdateOrgProcessStageDrawer = () => {
        this.setState({ openUpdateFormDrawer: false })
    }




    render() {
        const { employeeData, openCreateFormDrawer, orgProcessStageData, orgProcessStageUserMap, dropZoneId, openingDropZone, participantUpdating,
            is_fetching_process_stage, is_fetching_employee, orgStructureData, rankData, openUpdateFormDrawer } = this.state
        return (
            <>
                <Drawer
                    title={"Create Process Stage"}
                    width="30%"
                    closable={false}
                    onClose={() => this.onCloseCreateRankDrawer()}
                    open={openCreateFormDrawer}
                    extra={
                        <Button type="primary" onClick={() => this.setState({ openCreateFormDrawer: false })}>Close</Button>
                    }
                >
                    <Form
                        layout="vertical"
                        ref={this.inputRef}
                        initialValues={{ statusFlag: 'ACTIVE' }}
                        onFinish={this.createProcessStage}>
                        <Form.Item name="rank" label="Rank" rules={[{ required: true, message: '*(requried)' }]}>
                            <NJVSelect
                                size="large"
                                placeholder="Select rank"
                                bgcolor={Theme.colors.input_bg_color}
                                options={rankData} />
                        </Form.Item>
                        <Form.Item name="minimnumApprovalCount" label="Mininmum Approval Count" rules={[{ required: true, message: '*(requried)' }]}>
                            <NJVSelect
                                size="large"
                                placeholder="Select minimum approval count"
                                bgcolor={Theme.colors.input_bg_color}
                                options={countOptions} />
                        </Form.Item>
                        <Form.Item name="minimnumRejectCount" label="Minimum Reject Count" rules={[{ required: true, message: '*(requried)' }]}>
                            <NJVSelect
                                size="large"
                                placeholder="Select minimum reject count"
                                bgcolor={Theme.colors.input_bg_color}
                                options={countOptions} />
                        </Form.Item>
                        <Form.Item name="statusFlag" label="Status">
                            <Radio.Group
                                options={StatusFlagOptions}
                            />
                        </Form.Item>
                        <Form.Item >
                            <Button type="primary" htmlType="submit" loading={this.state.isLoading}>Create</Button>
                        </Form.Item>
                    </Form>
                </Drawer >
                <Drawer
                    title={"Update Process Stage"}
                    width="30%"
                    closable={false}
                    onClose={() => this.onCloseUpdateOrgProcessStageDrawer()}
                    open={openUpdateFormDrawer}
                    extra={
                        <Button type="primary" onClick={() => this.onCloseUpdateOrgProcessStageDrawer()}>Close</Button>
                    }
                >
                    <Form
                        layout="vertical"
                        ref={this.updateRef}
                        onFinish={this.updateProcessStage}>
                        <Form.Item name="minimnumApprovalCount" label="Mininmum Approval Count" rules={[{ required: true, message: '*(requried)' }]}>
                            <NJVSelect
                                size="large"
                                placeholder="Select minimum approval count"
                                bgcolor={Theme.colors.input_bg_color}
                                options={countOptions} />
                        </Form.Item>
                        <Form.Item name="minimnumRejectCount" label="Minimum Reject Count" rules={[{ required: true, message: '*(requried)' }]}>
                            <NJVSelect
                                size="large"
                                placeholder="Select minimum reject count"
                                bgcolor={Theme.colors.input_bg_color}
                                options={countOptions} />
                        </Form.Item>

                        <Form.Item name="statusFlag" label="Status">
                            <Radio.Group
                                options={StatusFlagOptions}
                            />
                        </Form.Item>
                        <Form.Item >
                            <Button type="primary" htmlType="submit" loading={this.state.isLoading}>Update</Button>
                        </Form.Item>
                    </Form>
                </Drawer >  

                <DndProvider backend={HTML5Backend}>
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <TitleLevel3 label={"Org Chart Approval User Assignment"} /><br /><br />
                            <Button icon={<PlusCircleFilled />} type="primary" onClick={() => this.onOpenCreateRankDrawer()} >Create New Process Stage</Button>
                            {/* <Flex align="center"></Flex> */}

                        </Col>
                        <Col span={12} style={{ textAlign: 'right' }}>
                            <Link to={CustomPath.org_chart}><NJVBackButton /></Link>
                        </Col>
                        <Col span={8}>
                            <Card style={{ padding: 10 }}
                                className="card-shadow"
                            >
                                <div>{orgStructureData.name}</div>
                                <Tag>{orgStructureData.orgTicketType}</Tag>
                            </Card>
                        </Col>
                        <Col span={16} />

                        {
                            is_fetching_process_stage && <Col span={24}><Skeleton active /></Col>
                        }
                        {
                            !is_fetching_process_stage && <Col span={openingDropZone ? 14 : 24} >
                                <List
                                    itemLayout="horizontal"
                                    dataSource={orgProcessStageData}
                                    renderItem={(item, index) => (
                                        <List.Item key={`list-item-${item.id}`}>
                                            <DropZone key={`dropzone-${item.id}`} id={item.id} onDrop={(drop) => this.handleDrop(drop, item.id)} dropZoneId={dropZoneId}>
                                                <Card style={{ padding: 10 }}
                                                    className="card-shadow"
                                                >
                                                    <Flex justify="space-between" align="center">
                                                        <Flex align="center">
                                                            <div style={{ fontSize: 16, fontWeight: '600' }}>Rank {item.rank}</div>
                                                            <Button type="primary" icon={<EditOutlined />} style={{ marginLeft: 10 }}
                                                                onClick={() => this.onOpenUpdateOrgProcessStageDrawer(item)} />
                                                        </Flex>
                                                        {
                                                            openingDropZone && dropZoneId === item.id && <Button type="primary" loading={participantUpdating} style={{ backgroundColor: 'green' }} icon={<SaveOutlined />} onClick={() => this.closeDropZone()} />
                                                        }
                                                        {
                                                            !openingDropZone && item.statusFlag === 'ACTIVE' &&
                                                            <Button type="primary" icon={<PlusOutlined />} onClick={() => this.openDropZone(item)} />
                                                        }
                                                        {
                                                            item.statusFlag === 'INACTIVE' && <Tag color={Colors.red}>Inactive</Tag>
                                                        }
                                                    </Flex>
                                                    {
                                                        orgProcessStageUserMap.get(item.id) &&
                                                        <div style={{ marginTop: 15 }}>
                                                            {
                                                                orgProcessStageUserMap.get(item.id)?.map(user => {
                                                                    return (
                                                                        <div key={user.id} style={{ display: 'inline-flex', alignItems: 'center', backgroundColor: Theme.colors.card_bg_color, borderRadius: 100, padding: 5, marginBlock: 5, marginRight: 5 }}>
                                                                            <Avatar
                                                                                style={{
                                                                                    backgroundColor: 'gray',
                                                                                    verticalAlign: 'middle',
                                                                                }}
                                                                                size={35}
                                                                            >
                                                                                U
                                                                            </Avatar>
                                                                            <span style={{ marginLeft: 5, marginRight: 10 }}>{user.fullName}</span>
                                                                            {
                                                                                openingDropZone && dropZoneId === item.id &&
                                                                                <Button size='small' type="primary" style={{ backgroundColor: 'red' }} icon={<CloseOutlined />} onClick={() => this.onRemoveParticipant(item.id, user.id)} />
                                                                            }
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    }
                                                </Card>
                                            </DropZone>
                                        </List.Item>
                                    )}
                                />
                            </Col>
                        }
                        <Col span={openingDropZone ? 10 : 0}>
                            <Row style={{ marginTop: 12 }}>
                                <Col span={19}>
                                    <NJVInput bgcolor={Theme.colors.input_bg_color} placeholder="Search by name" onChange={(event) => this.setState({ fullName: event.target.value })} />
                                </Col>
                                <Col span={1} />
                                <Col span={4}>
                                    <Button type="primary" size="large" icon={<SearchOutlined />} onClick={() => this.searchParticipantByName()}>
                                        Search
                                    </Button>
                                </Col>

                            </Row>
                            {
                                is_fetching_employee && <Skeleton active />
                            }
                            {
                                !is_fetching_employee && <List
                                    style={{ marginTop: 10 }}
                                    itemLayout="horizontal"
                                    dataSource={employeeData}
                                    renderItem={(item, index) => (
                                        <div key={`list-item-${item.id}`} style={{ width: '100%' }}>
                                            <DraggableItem key={item.id} data={item} />
                                        </div>
                                    )}
                                />
                            }
                        </Col>
                    </Row>
                </DndProvider >
            </>
        )
    }
}
export default compose(withRouter)(OrgChartUserAssignPage)