
const SearchPagination = (page) => {
    const urlParam = new URLSearchParams(window.location.search);
    const urlPage = urlParam.get('page');
    let newUrl;
    if (urlPage) {
        const currentUrl = window.location.href;
        let replace_url = currentUrl.toString();

        newUrl = replace_url.replace(/page=\d+/, `page=${page}`);
    } else {
        urlParam.set('page', page);
        const currentUrl = new URL(window.location.href);
        newUrl = `${currentUrl.pathname}?${urlParam.toString()}`;
    }

    return newUrl
}

export default SearchPagination;