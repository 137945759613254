import { Card, Col, Row, Tag } from "antd";
import React from 'react';
import { NJVSpacer } from "./core-component";


export const NVMCompOrderDetail = (props) => {
    const { data } = props;


    const NJVLabel = ({ label }) => {
        return (
            <>
                <span style={{ fontSize: 13, fontWeight: '600', color: 'gray' }}>{label}</span>
                <br />
            </>)

    }

    const NJVValue = ({ value }) => {
        return (
            <>
                <span style={{ fontSize: 16, fontWeight: '500', color: 'black' }}>{value}</span>
                <br />
            </>)

    }
    return (
        <>
            <Row>
                {/* <Col lg={6} md={6} xs={1} sm={1} /> */}
                {/* <Col lg={12} md={6} xs={22} sm={22}> */}
                <Col span={24}>
                    <>
                        {
                            data ?
                                <div>
                                    {/* <Row>
                                                    <Col span={24}>
                                                        <Card
                                                            style={{ backgroundColor: "#f5f5f5", boxShadow: 'none' }}
                                                            bordered={false}>
                                                            <NJVLabel label="Tracking Number" />
                                                            <NJVValue value={data.trackingNumber} />
                                                            <NJVSpacer height={30} />
                                                            {
                                                                data.fromAddress && data.fromTownship ?
                                                                    <>
                                                                        <NJVLabel label="Pickup Address" />
                                                                        <NJVValue value={`${data.fromAddress}, ${data.fromTownship.name}, ${data.fromTownship.name} `} />
                                                                    </>
                                                                    :
                                                                    <></>
                                                            }


                                                        </Card>
                                                    </Col>
                                                </Row>
                                                <NJVSpacer height={20} /> */}
                                    <Row>
                                        <Col span={24}>
                                            <Card
                                                style={{ backgroundColor: "#f5f5f5", boxShadow: 'none' }}
                                                bordered={false}>
                                                <NJVLabel label="Tracking Number" />
                                                <NJVValue value={data.trackingNumber} />
                                                <NJVSpacer height={30} />
                                                <NJVLabel label="Sender Name" />
                                                <NJVValue value={`${data.senderName}`} />
                                                <NJVSpacer height={30} />
                                                <NJVLabel label="Sender Phone Number" />
                                                <NJVValue value={data.senderPhoneNumber} />
                                                <NJVSpacer height={30} />
                                                {
                                                    data.fromAddress && data.fromTownship ?
                                                        <>
                                                            <NJVLabel label="Sender Address" />
                                                            <NJVValue value={`${data.fromAddress}, ${data.fromTownship.name}, ${data.fromTownship.division.name}`} />
                                                        </>
                                                        :
                                                        <></>
                                                }
                                            </Card>
                                        </Col>
                                    </Row>
                                    <NJVSpacer height={20} />
                                    <Row>
                                        <Col span={24}>
                                            <Card
                                                style={{ backgroundColor: "#f5f5f5", boxShadow: 'none' }}
                                                bordered={false}>
                                                <NJVLabel label="Receiver Name" />
                                                <NJVValue value={`${data.recipientName}`} />
                                                <NJVSpacer height={30} />
                                                <NJVLabel label="Receiver Phone Number" />
                                                <NJVValue value={data.recipientPhoneNumber} />
                                                <NJVSpacer height={30} />
                                                {
                                                    data.toAddress && data.toTownship ?
                                                        <>
                                                            <NJVLabel label="Receiver Address" />
                                                            <NJVValue value={`${data.toAddress}, ${data.toTownship.name}, ${data.toTownship.division.name}`} />
                                                        </>
                                                        :
                                                        <></>
                                                }
                                            </Card>
                                        </Col>
                                    </Row>
                                    <NJVSpacer height={20} />
                                    <Row>
                                        <Col span={24}>
                                            <Card
                                                style={{ backgroundColor: "#f5f5f5", boxShadow: 'none' }}
                                                bordered={false}>
                                                <NJVSpacer height={30} />
                                                <NJVSpacer height={30} />
                                                <NJVLabel label="Pickup Time" />
                                                <NJVValue value={`${data.pickupDate}`} />
                                                <NJVSpacer height={30} />
                                                <NJVLabel label="OrderType" />
                                                <NJVValue value={`${data.orderType}`} />
                                                {
                                                    data.paymentOption ?
                                                        <>
                                                            <NJVSpacer height={30} />
                                                            <NJVLabel label="Payment Option" />
                                                            <NJVValue value={`${data.paymentOption}`} />
                                                        </>
                                                        :
                                                        <></>

                                                }
                                                <NJVSpacer height={30} />
                                                {
                                                    data && data.note ?
                                                        <>
                                                            <NJVSpacer height={30} />
                                                            <NJVLabel label="Note" />
                                                            <NJVValue value={`${data.note}`} />
                                                        </>
                                                        :
                                                        <></>
                                                }
                                                <NJVLabel label="COD Amount" />
                                                <NJVValue value={`${data.codAmount ? data.codAmount : 0} MMK`} />
                                                <NJVSpacer height={30} />
                                                <NJVLabel label="Note" />
                                                <NJVValue value={`${data.note ? data.note : '------'}`} />
                                                <NJVSpacer height={30} />
                                                {
                                                    data && data.updateDeliveryCharges ?
                                                        <>
                                                            <NJVLabel label="Updated Delivery Charges" />
                                                            <NJVValue value={`${data.updateDeliveryCharges}`} />
                                                            <NJVSpacer height={30} />
                                                        </> :
                                                        <></>
                                                }
                                                {
                                                    data && data.updateCodAmount ?
                                                        <>
                                                            <NJVLabel label="Updated Cod Amount" />
                                                            <NJVValue value={`${data.updateCodAmount}`} />
                                                            <NJVSpacer height={30} />
                                                        </> :
                                                        <></>
                                                }

                                                {/* {
                                                    orderSteps && orderSteps.length != 0 ?
                                                        <>
                                                            <NJVLabel label="Status" />
                                                            <NJVSpacer height={10} />
                                                            <Steps
                                                                className="step-full-width-title"
                                                                size="small"
                                                                current={currentStep}
                                                                direction="vertical"
                                                                items={orderSteps}
                                                            />
                                                        </>
                                                        :
                                                        <></>
                                                } */}


                                                {
                                                    data.comment ?
                                                        <>
                                                            <NJVSpacer height={30} />
                                                            <NJVLabel label="Fail Reason" />
                                                            <NJVValue value={data.comment} />
                                                        </>
                                                        :
                                                        <></>
                                                }

                                                {/* <NJVSpacer height={30} />
                                                <div style={{ display: 'flex' }}>
                                                    <di style={{ marginRight: 10 }}>
                                                        <NJVLabel label="Product Image" />
                                                        {
                                                            data.productImageUrl ?

                                                                <Image
                                                                    style={{
                                                                        marginTop: 5,
                                                                        borderRadius: 9,
                                                                        cursor: 'pointer',
                                                                        padding: 10,
                                                                        border: '1px solid grey'
                                                                    }}
                                                                    onClick={() => this.setState({ viewPhoto: true })}
                                                                    src={data.productImageUrl}
                                                                    // preview={false}
                                                                    width={200}
                                                                />
                                                                :
                                                                <div
                                                                    style={{
                                                                        marginTop: 5,
                                                                        borderRadius: 9,
                                                                        cursor: 'pointer',
                                                                        padding: 10,
                                                                        backgroundColor: '#f1f1f1',
                                                                        border: '1px solid #f1f1f1',
                                                                        width: 200,
                                                                        height: 200,
                                                                        display: 'flex',
                                                                        justifyContent: 'center',
                                                                        alignItems: 'center'
                                                                    }}
                                                                >
                                                                    <span style={{ fontWeight: '600', color: 'grey' }}>No Image</span>
                                                                </div>
                                                        }
                                                    </di>
                                                    <div>
                                                        <NJVLabel label="Package Image" />
                                                        {
                                                            data.packageImageUrl ?
                                                                <Image
                                                                    style={{
                                                                        marginTop: 5,
                                                                        borderRadius: 9,
                                                                        cursor: 'pointer',
                                                                        padding: 10,
                                                                        border: '1px solid grey'
                                                                    }}
                                                                    onClick={() => this.setState({ viewPhoto: true })}
                                                                    src={data.packageImageUrl}
                                                                    // preview={false}
                                                                    width={200}
                                                                />
                                                                :
                                                                <div
                                                                    style={{
                                                                        marginTop: 5,
                                                                        borderRadius: 9,
                                                                        cursor: 'pointer',
                                                                        padding: 10,
                                                                        backgroundColor: '#f1f1f1',
                                                                        border: '1px solid #f1f1f1',
                                                                        width: 200,
                                                                        height: 200,
                                                                        display: 'flex',
                                                                        justifyContent: 'center',
                                                                        alignItems: 'center'
                                                                    }}
                                                                >
                                                                    <span style={{ fontWeight: '600', color: 'grey' }}>No Image</span>
                                                                </div>
                                                        }


                                                    </div>
                                                </div>
                                                <br />
                                                <NJVLabel label="Bus Gate Voucher" />
                                                {
                                                    data.busGateVoucherImagePath ?
                                                        <Image
                                                            style={{
                                                                marginTop: 5,
                                                                borderRadius: 9,
                                                                cursor: 'pointer'
                                                            }}
                                                            onClick={() => this.setState({ viewPhoto: true })}
                                                            src={data.busGateVoucherImagePath}
                                                            // preview={false}
                                                            width={200}
                                                        />
                                                        :
                                                        <div
                                                            style={{
                                                                marginTop: 5,
                                                                borderRadius: 9,
                                                                cursor: 'pointer',
                                                                padding: 10,
                                                                backgroundColor: '#f1f1f1',
                                                                border: '1px solid #f1f1f1',
                                                                width: 200,
                                                                height: 200,
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center'
                                                            }}
                                                        >
                                                            <span style={{ fontWeight: '600', color: 'grey' }}>No Image</span>
                                                        </div>
                                                }


                                                <br />
                                                {
                                                    data.lat && data.lng ?
                                                        <Button type="primary" style={{ marginTop: 20 }}>View Location</Button>
                                                        :
                                                        <>
                                                            <div>No bus gate location available.</div>
                                                        </>
                                                } */}

                                            </Card>
                                        </Col>
                                    </Row>
                                </div>
                                :
                                <></>
                        }
                    </>
                </Col>

            </Row>
        </>

    );
}