import { Breadcrumb, Button, Card, Checkbox, Col, DatePicker, Form, Input, Row, Select } from "antd";
import dayjs from 'dayjs';
import React from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { FormLabel, FormLabelOptional } from "../../../components/general-component";
import Theme from "../../../components/theme";
import { NRCValidator, NumberOnlyValidator, PhoneNumberValidator, RestrictPhoneNumberValidator, ShipperPasswordValidator } from "../../../components/validator";
import { SHIPPER_BUSINESS_TYPE, nrcCode_map, nrcNo_collection, payment_type_collection } from "../../../core/constant";
import Api from "../../../network/api";
import { ApiFilter, ApiPost } from "../../../network/network-manager";
import withRouter from "../../../network/with-router";
import CustomPath from "../../../routes/custom-path";
import { NJVSearchFilterOption } from "../../../components/core-component";

const { Option } = Select
const { TextArea } = Input

class ShipperForm extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            data: [],
            roleData: [],
            roleOptions: [],
            division_collection: [],
            township_collection_map: {},
            township_collection: [],
            product_category_collection: [],
            nrcDivisionNumber: null,
            nrctownshipCode: null,
            nrcCitizen: null,
            townshipCode_collection: [],
            currentTownship: {},
            is_loading: false

        };
        this.inputRef = React.createRef()

    }

    componentDidMount() {
        // this.fetchRole()
        this.fetchAllDivision()
        this.fetchProductCategory()
    }

    updateGender = (event) => {
        this.setState({
            gender: event.target.value
        })
    }

    fetchAllDivision = async () => {
        try {
            const response = await ApiFilter(Api.division_get_all, null, this.props)
            if (response) {
                let divisionList = []
                let townshipMap = {}
                response.map(division => {
                    divisionList.push(division)
                    townshipMap[division.id] = division.townshipDTOList
                })
                this.setState({
                    division_collection: divisionList,
                    township_collection_map: townshipMap
                })
                this.fetchData(townshipMap)
            }
        } catch (error) {
        }
    }

    fetchProductCategory = async () => {
        try {
            var params = {
                page: 0,
                size: 100
            }
            const response = await ApiFilter(Api.product_category_filter, params, this.props)
            if (response && response.content) {
                this.setState({
                    product_category_collection: response.content
                })
            }
        } catch (error) { }


    }
    handleOnCodeChange = (value) => {
        this.setState({
            townshipCode_collection: nrcCode_map[value],
            nrctownshipCode: null,
            nrcDivisionNumber: value
        })
    }
    handleOnTownShipCodeChange = (value) => {

        this.setState({
            nrctownshipCode: value
        })
    }

    handleDivisionSelect = (value) => {
        const { township_collection_map } = this.state
        this.setState({
            township_collection: township_collection_map[value],
        })
        this.inputRef.current.setFieldsValue({ townshipId: null });
    }

    handleTownshipSelect = (value) => {
        const { township_collection } = this.state
        let currentTownship = {}
        township_collection.map(township => {
            if (township.id === value) {
                currentTownship = township
                return
            }
        })
        if (currentTownship)
            this.inputRef.current.setFieldsValue({ businessTownshipCode: currentTownship.shortCode })
        this.setState({
            currentTownship: currentTownship
        })

    }

    handlePhoneNumberChange = (e) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/[^0-9]/g, '');
        this.inputRef.current.setFieldsValue({ primaryPhoneNumber: numericValue })
    };


    saveShipper = async (values) => {
        const { nrcDivisionNumber, nrctownshipCode, nrcCitizen, currentTownship } = this.state
        this.setState({
            is_loading: true
        })
        try {
            if (values.dateOfBirth === undefined) {
                const date = this.currentDate()
                values = { ...values, dateOfBirth: date.format("YYYY-MM-DD") }
            }
            let businessName = values.businessName + " (" + values.tag + ")"
            if (currentTownship && currentTownship.shortCode)
                businessName = businessName + " (" + currentTownship.shortCode + ")"
            const response = await ApiPost(Api.shipper,
                {
                    "firstName": values.firstName,
                    "lastName": values.lastName,
                    "dateOfBirth": values.dateOfBirth,
                    "osGuid": values.osGuid,
                    "phoneNumber": values.primaryPhoneNumber,
                    "gender": values.gender,
                    'emailAddress': values.emailAddress,
                    'shipperApprovedStatus': 'APPROVED',
                    "userStatus": values.active ? "ACTIVE" : "INACTIVE",
                    "address1": values.address1,
                    "note": values.note,
                    "salePersonCode": values.salePersonCode,
                    "ninjaCoreOldShipperId": values.ninjaCoreOldShipperId,
                    "accounts": [
                        {
                            "loginId": values.primaryPhoneNumber,
                            "primaryPhoneNumber": values.primaryPhoneNumber,
                            "password": values.password,
                            "accountType": "PHONE"
                        }
                    ],
                    'business': {
                        'businessName': businessName,
                        'ecommerceRegNumber': values.ecommerceRegNumber,
                        'businessOwnerNRC': (nrcDivisionNumber && nrctownshipCode && nrcCitizen && values.nrcNo) ? nrcDivisionNumber + "/" + nrctownshipCode + "(" + nrcCitizen + ")" + values.nrcNo : null,
                        'facebookPageLink': values.facebookPageLink,
                        'paymentType': values.paymentType,
                        'accountOwnerName': values.accountOwnerName,
                        'paymentNumber': values.paymentNumber,
                        'productCategory': {
                            'id': values.productCategory
                        }
                    },
                    'township': {
                        'id': values.townshipId
                    }
                }, this.props
            )
            if (response.status === 200) {
                this.props.navigate(CustomPath.shipper)
            }
        } catch (error) {
        }
        this.setState({
            is_loading: false
        })
    }

    disabledDate = (current) => {
        const currentDate = dayjs();
        const eighteenYearsAgo = currentDate.subtract(18, 'year');
        return current && current > eighteenYearsAgo;
    };

    currentDate = () => {
        const date = dayjs();
        return date.subtract(18, 'year');
    }

    render() {
        const { navigate } = this.props
        const { is_loading, division_collection, township_collection, product_category_collection, nrcCitizen, townshipCode_collection, nrcDivisionNumber, nrctownshipCode, currentTownship } = this.state
        return (
            <>
                <Breadcrumb
                    items={[
                        {
                            title: 'Home',
                        },
                        {
                            title: <Link to={CustomPath.shipper}>Shipper</Link>,
                        },
                        {
                            title: 'Form',
                        }
                    ]}
                    style={{ marginBottom: "20px" }}
                />
                <Card
                    style={{ backgroundColor: Theme.colors.card_bg_color, boxShadow: 'none', marginBottom: "20px" }}
                    bordered={false}
                    title={"Shipper Form"}
                    extra={<Button type="primary" onClick={() => navigate(CustomPath.shipper)}>Back</Button>}
                >
                    <Form
                        layout="vertical"
                        ref={this.inputRef}
                        initialValues={{ active: true }}
                        onFinish={this.saveShipper}>
                        <Row gutter={16}>
                            {/* <Col span={12}>
                                        <Form.Item name="uniqueId" label={"Shipper unique id"} rules={[{ required: true, message: '*(requried)' }]}>
                                            <Input placeholder={"Enter unique id"} />
                                        </Form.Item>
                                    </Col> */}
                            <Col span={24}>
                                <Form.Item name="osGuid" label={"Invoice Id"}>
                                    <Input placeholder={"Enter invoice id"} />
                                </Form.Item>
                            </Col>
                            <Col span={12} >
                                <Form.Item name="firstName" label={"First Name"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <Input placeholder={"Enter first name"} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="lastName" label={"Last Name"}>
                                    <Input placeholder={"Enter last name"} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="primaryPhoneNumber" label={"Phone Number"} rules={[{ required: true, validator: RestrictPhoneNumberValidator }]}>
                                    <Input placeholder={"0979xxxxxxxx"} onChange={this.handlePhoneNumberChange} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="emailAddress" label={"Email Address"} size={'large'} rules={[{ required: true, message: '*(requried)' }]}>
                                    <Input size="large" placeholder={"example@gmail.com"} />
                                </Form.Item>
                            </Col>
                            <Col span={12} style={{ paddingLeft: 5 }}>
                                <Form.Item name="password" label={"Password"} rules={[{ required: true, message: '*(requried)' }, { validator: ShipperPasswordValidator }]} style={{ cursor: 'pointer' }}>
                                    <Input.Password size="small" placeholder="Enter password" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="gender" label={"Gender"}>
                                    <Select
                                        size="large"
                                        allowClear={true}
                                        style={{
                                            width: '100%',
                                        }}
                                        placeholder="Select gender"
                                        options={[
                                            {
                                                label: 'Male',
                                                value: 'MALE',
                                            },
                                            {
                                                label: 'Female',
                                                value: 'FEMALE',
                                            },
                                        ]}
                                    />
                                </Form.Item>

                            </Col>
                            <Col span={12}>
                                <Form.Item name="dateOfBirth" label={"Date Of Birth"} style={{ cursor: 'pointer' }} className="custom-date-picker">
                                    <DatePicker
                                        allowClear={true}
                                        disabledDate={this.disabledDate}
                                        size="large" style={{ width: '100%', cursor: 'pointer' }} format={'YYYY-MM-DD'} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>

                                <Form.Item name="divisionId" label={"Division/State"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <Select
                                        className="my-location-selector"
                                        placeholder={"Select Division"}
                                        onChange={this.handleDivisionSelect}
                                        size="large"
                                        options={
                                            division_collection && division_collection.map((division) => {
                                                return {
                                                    value: division.id,
                                                    label: division.name,
                                                    type: 'division'
                                                }
                                            })
                                        }
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item name="townshipId" label={"Township/City"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <Select
                                        className="my-location-selector"
                                        placeholder={"Select township"}
                                        onChange={this.handleTownshipSelect}
                                        size="large"
                                        options={
                                            township_collection && township_collection.map((township) => {
                                                return {
                                                    value: township.id,
                                                    label: township.name,
                                                    type: 'division'
                                                }
                                            })
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}></Col>
                            <Col span={12}>
                                <Form.Item name="address1" label={"Address"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <TextArea
                                        style={{ height: 60 }} placeholder={"Enter address"} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="note" label={"Note"}>
                                    <TextArea
                                        style={{ height: 60 }} placeholder={"Enter note"} />
                                </Form.Item>
                            </Col>
                        </Row>


                        <h4>Business Information</h4>
                        <Row gutter={16}>
                            <Col span={24}>
                                {/* <Form.Item name="businessName" label={"Business Name"} size={'large'} rules={[{ required: true, message: '*(requried)' }]}>
                                            <Input size="large" placeholder={"Fashion House"} />
                                        </Form.Item> */}
                                <Row gutter={16}>
                                    <Col span={4}>
                                        <Form.Item name="businessUniqueId" label={"Unique Id"} size={'large'}>
                                            <Input disabled size="large" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name="businessName" label={"Business Name"} size={'large'} rules={[{ required: true, message: '*(requried)' }]}>
                                            <Input size="large" placeholder={"Fashion House"} />

                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item name="tag" label={"Type"} size={'large'}>
                                            <Select
                                                style={{ width: '100%' }}
                                                placeholder={"CITY"}
                                                size="large"
                                            >
                                                {
                                                    SHIPPER_BUSINESS_TYPE.map(type => <Option value={type}>{type}</Option>)
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item name="businessTownshipCode" label={"Township Code"} size={'large'}>
                                            <Input disabled size="large" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="ecommerceRegNumber" label={"E-commerce Registration Number"} size={'large'}>
                                    <Input size="large" placeholder={"1234343"} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="facebookPageLink" label={"Business Facebook Page Link"} size={'large'} rules={[{ required: true, message: '*(requried)' }]}>
                                    <Input size="large" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Row gutter={16}>
                                    <Col span={24}>
                                        <FormLabelOptional label={"Business Owner NRC"} />
                                    </Col>
                                    <Col span={4}>
                                        <Select
                                            style={{ width: '100%' }}
                                            placeholder={"1"}
                                            onChange={this.handleOnCodeChange}
                                            size="large"
                                            value={nrcDivisionNumber}
                                            options={nrcNo_collection}
                                        />
                                    </Col>
                                    <Col span={6}>
                                        <Select
                                            style={{ width: '100%' }}
                                            placeholder={"LAYANA"}
                                            onChange={this.handleOnTownShipCodeChange}
                                            showSearch
                                            filterOption={NJVSearchFilterOption}
                                            value={nrctownshipCode}
                                            size="large"
                                            options={
                                                townshipCode_collection && townshipCode_collection.map((code, index) => {
                                                    return {
                                                        key: index,
                                                        value: code.value,
                                                        label: code.value,
                                                    }
                                                })
                                            }
                                        />
                                    </Col>
                                    <Col span={4}>
                                        <Select
                                            style={{ width: '100%' }}
                                            placeholder={"N"}
                                            value={nrcCitizen}
                                            onChange={(value) => this.setState({ nrcCitizen: value })}
                                            size="large"
                                        >
                                            <Option value={"N"}>N</Option>
                                            <Option value={"F"}>F</Option>
                                            <Option value={"P"}>P</Option>
                                            <Option value={"T"}>T</Option>
                                            <Option value={"E"}>E</Option>
                                        </Select>
                                    </Col>
                                    <Col span={10}>
                                        <Form.Item name="nrcNo" size={'large'}
                                            rules={[
                                                { validator: NRCValidator }
                                            ]}>
                                            <Input size="large" placeholder={"123456"} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>


                            <Col span={12}>
                                <Form.Item name="productCategory" label={"Choose Business Category"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <Select
                                        className="my-location-selector"
                                        placeholder={"Choose Business Category"}
                                        size="large"
                                        options={
                                            product_category_collection && product_category_collection.map(category => {
                                                return {
                                                    value: category.id,
                                                    label: category.name,
                                                }
                                            })
                                        }
                                    >

                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="accountOwnerName" label={"Account Owner Name"} size={'large'} rules={[{ required: true }]}>
                                    <Input size="large" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="paymentType" label={"Primary Payment"} size={'large'} rules={[{ required: true, message: '*(requried)' }]}>
                                    <Select
                                        className="my-location-selector"
                                        placeholder={"Choose Primary Payment"}
                                        size="large"
                                        options={
                                            payment_type_collection && payment_type_collection.map((payment) => {
                                                return {
                                                    value: payment.key,
                                                    label: payment.value,
                                                    type: 'primaryPayment'
                                                }
                                            })
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="paymentNumber" label={"Primary Account Number"} size={'large'} rules={[{ required: true, validator: NumberOnlyValidator }]}>
                                    <Input size="large" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="ninjaCoreOldShipperId" label={"Ninja Van Old Shipper Id"}>
                                    <Input placeholder={"Enter ninja core old shipper id"} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="salePersonCode" label={"Sale Person Code"}>
                                    <Input placeholder={"Enter sale person code"} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="active"
                                    valuePropName="checked">
                                    <Checkbox>Active</Checkbox>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item style={{ textAlign: 'end' }}>
                                    <Button style={{ marginRight: '20px' }}>Reset</Button>
                                    <Button type="primary" htmlType="submit" loading={is_loading}>Create</Button>
                                </Form.Item>
                            </Col>
                        </Row>

                    </Form>
                </Card>
            </>
        );
    }
}

export default compose(withRouter)(ShipperForm)