import { Breadcrumb, Button, Card, Col, Form, Input, Row } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import Theme from "../../../components/theme";
import Api from "../../../network/api";
import { ApiPost } from "../../../network/network-manager";
import withRouter from "../../../network/with-router";
import CustomPath from "../../../routes/custom-path";

class UserGroupForm extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            data: [],
            authorities: [],
            productCategorys: [],
            userTypes: [],
            fileList: [],
            previewImage: '',
            previewOpen: false
        };
        this.inputRef = React.createRef()

    }

    savaUserGroup = async (values) => {
        try {
            const response = await ApiPost(Api.user_group,
                {
                    "name": values.name,
                    "description": values.description
                }, this.props
            )
            if (response.status == 200) {
                this.props.navigate(CustomPath.user_group)
            }
        } catch (error) {
        }
    }

    render() {
        const { navigate } = this.props
        return (
            <>
                <Breadcrumb
                    items={[
                        {
                            title: 'Home',
                        },
                        {
                            title: <Link to={CustomPath.user_group}>User Group</Link>,
                        },
                        {
                            title: 'Form',
                        }
                    ]}
                    style={{ marginBottom: "20px" }}
                />
                <Card
                    style={{ backgroundColor: Theme.colors.card_bg_color, boxShadow: 'none', marginBottom: "20px" }}
                    bordered={false}
                    title={"User Group Form"}
                    extra={<Button type="primary" onClick={() => navigate(CustomPath.user_group)}>Back</Button>}
                >
                    <Form
                        layout="vertical"
                        ref={this.inputRef}
                        initialValues={{ active: true }}
                        onFinish={this.savaUserGroup}>

                        <Form.Item name="name" label={"User Group Name"} rules={[{ required: true, message: '*(requried)' }]}>
                            <Input placeholder={"Enter user group name"} />
                        </Form.Item>

                        <Form.Item name="description" label={"Description"}>
                            <Input placeholder={"Enter description"} />
                        </Form.Item>

                        <Form.Item style={{ textAlign: 'end' }}>
                            <Button style={{ marginRight: '20px' }}>Reset</Button>
                            <Button type="primary" htmlType="submit">Create</Button>
                        </Form.Item>
                    </Form>
                </Card>
            </>
        );
    }
}

export default compose(withRouter)(UserGroupForm)