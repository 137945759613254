import { PlusOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Card, Checkbox, Col, DatePicker, Form, Input, Row, Select, Upload, message } from "antd";
import dayjs from 'dayjs';
import React from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import Theme from "../../components/theme";
import Api from "../../network/api";
import { ApiFilter, ApiPostWithFormData } from "../../network/network-manager";
import withRouter from "../../network/with-router";
import CustomPath from "../../routes/custom-path";
import { GetListFromEnumObject } from "../../core/nvm-utils";

const { TextArea } = Input

class NotificationChannelForm extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            data: [],
            userTypes: [],
            notificationTypes: [],
            fileList: [],
            isPushNotificaton: false,
            is_selected_partnership_admin: false,
            is_creating_data: false
        };
        this.inputRef = React.createRef()

    }

    componentDidMount() {
        this.fetchUserTypes()
        this.fetchNotificationType()
    }

    handleChange = ({ fileList: newFileList }) => {
        let updateFileList = []
        newFileList && newFileList.map(file => {
            updateFileList.push({ ...file, status: 'done' })
        })
        this.setState({
            fileList: updateFileList
        })
    }


    fetchUserTypes = async () => {
        try {
            const params = {
                enumType: 'USER_TYPE'
            }
            const response = await ApiFilter(Api.enum_filter, params, this.props)
            if (response) {
                this.setState({
                    userTypes: GetListFromEnumObject(response),
                });
            }
        } catch (error) {

        }
    }

    fetchNotificationType = async () => {
        try {
            var params = {
                'enumType': 'NOTIFICATION'
            }
            const response = await ApiFilter(Api.enum_filter, params, this.props)
            if (response) {
                let notificationTemp = []
                response.map((res) => {
                    notificationTemp.push({
                        value: res.key,
                        label: res.value,
                    });
                })
                this.setState({
                    notificationTypes: notificationTemp
                })
            }
        } catch (error) {
        }
    }

    saveNotificationChannel = async (values) => {
        this.setState({
            is_creating_data: true
        })
        try {
            let formData = new FormData()
            formData.append('channel', values.channel);
            formData.append('title', values.title);
            formData.append('content', values.content);
            formData.append('active', values.active);
            formData.append('userTypes', values.userTypes ? values.userTypes : []);
            formData.append('pushUserType', values.pushUserType);
            formData.append('notificationType', values.notificationType)
            formData.append('partnerAdminSpecialCode', values.partnerAdminSpecialCode ? values.partnerAdminSpecialCode : '')
            if (values.photoUrl && values.photoUrl.file && values.photoUrl.file.originFileObj) {
                formData.append('file', values.photoUrl.file.originFileObj)
            } else {
                formData.append('file', null)
            }
            if (values.isAutoPushNotification) {
                if (values.pushNotificationTime) {
                    const currentTime = dayjs()
                    if (currentTime > values.pushNotificationTime) {
                        message.error("Invalid selected push time")
                        return
                    }

                    let pushTime = values.pushNotificationTime.format("YYYY-MM-DD HH:mm:ss")
                    formData.append('isAutoPushNotification', values.isAutoPushNotification);
                    formData.append('pushNotificationTime', pushTime);
                } else {
                    message.error("Please select push time")
                    return
                }
            } else {
                formData.append('isAutoPushNotification', false);
                formData.append('pushNotificationTime', "");
            }
            const response = await ApiPostWithFormData(Api.notification_channel, formData, this.props)
            if (response.status == 200) {
                this.props.navigate(CustomPath.notification_channel)
            }
        } catch (error) {
        }
        this.setState({
            is_creating_data: false
        })
    }


    getValidDate = () => {
        const currentDate = dayjs();
        const targetTime = dayjs().set('hour', 14).set('minute', 0).set('second', 0);
        const isBefore2PM = currentDate.isBefore(targetTime);
        if (isBefore2PM) {
            return dayjs().startOf('day');
        } else {
            return dayjs().endOf('day');
        }
    }

    disabledDate = (current) => {
        return current && current < dayjs().startOf('day')
    };

    onSelectAutoPushNotification = (event) => {
        this.setState({
            isPushNotificaton: event.target.checked
        })
    }

    onChangeUserType = (selectedValue, index) => {
        let partnerList = ['SP_ADMIN']
        let is_selected_partner_ship = false
        selectedValue.map(value => {
            if (value === 'SP_ADMIN' || value === 'SP_STAFF') {
                is_selected_partner_ship = true
            }
        })
        if (is_selected_partner_ship) {
            this.setState({
                is_selected_partnership_admin: true
            })
            this.inputRef.current.setFieldsValue({ userTypes: partnerList })
        } else {
            this.setState({
                is_selected_partnership_admin: false
            })
        }

    }

    render() {
        const { navigate } = this.props
        const { userTypes, fileList, isPushNotificaton, notificationTypes, is_selected_partnership_admin, is_creating_data } = this.state
        return (
            <>
                <Breadcrumb
                    items={[
                        {
                            title: 'Home',
                        },
                        {
                            title: <Link to={CustomPath.notification_channel}>NotificationChannel</Link>,
                        },
                        {
                            title: 'Form',
                        }
                    ]}
                    style={{ marginBottom: "20px" }}
                />

                <Card
                    style={{ backgroundColor: Theme.colors.card_bg_color, boxShadow: 'none', marginBottom: "20px" }}
                    bordered={false}
                    title={"NotificationChannel"}
                    extra={
                        <Button
                            type="primary" onClick={() => navigate(CustomPath.notification_channel)}>
                            Back
                        </Button>
                    }
                >
                    <Form
                        layout="vertical"
                        ref={this.inputRef}
                        initialValues={{ active: true }}
                        onFinish={this.saveNotificationChannel}>

                        <Form.Item name="channel" label={"Channel"} rules={[{ required: true, message: '*(requried)' }]}>
                            <Input placeholder={"advertise"} />
                        </Form.Item>
                        <Form.Item name="title" label={"Title"} rules={[{ required: true, message: '*(requried)' }]}>
                            <Input placeholder={"Selling IPhone 15"} />
                        </Form.Item>
                        <Form.Item name="content" label={"Content"} rules={[{ required: true, message: '*(requried)' }]}>
                            <TextArea
                                style={{ height: 100, background: '#fff' }}
                                maxLength={1000}
                                showCount={true}
                                bordered={false}
                                placeholder={"Something....."} />
                        </Form.Item>
                        <Form.Item name="notificationType" label={"Notification Type"} rules={[{ required: true, message: '*(requried)' }]}>
                            <Select
                                size="large"
                                style={{
                                    width: '100%',
                                }}
                                placeholder="Select Notification Type"
                                options={notificationTypes}
                            />
                        </Form.Item>
                        <Form.Item name="pushUserType" label={"Push User Type"} rules={[{ required: true, message: '*(requried)' }]}>
                            <Select
                                size="large"
                                style={{
                                    width: '100%',
                                }}
                                placeholder="Select Notification Type"
                                options={[
                                    {
                                        value: 'USER TYPE',
                                        label: 'User Type',
                                    }, {
                                        value: 'SPECIFIC USER',
                                        label: 'Specific User',
                                    }
                                ]}
                            />
                        </Form.Item>

                        {
                            isPushNotificaton ?
                                <>
                                    <Form.Item name="pushNotificationTime" label={"Select auto push notification date"} style={{ cursor: 'pointer' }} className="custom-date-picker">
                                        <DatePicker
                                            showTime
                                            disabledDate={this.disabledDate}
                                            size="large"
                                            allowClear={true}
                                            style={{ cursor: 'pointer' }}
                                            format={'YYYY-MM-DD HH:mm:ss'}
                                        />
                                    </Form.Item>
                                </>
                                :
                                <></>
                        }
                        <Form.Item name="photoUrl" label={"Upload Notification Photo"}>
                            <Upload
                                accept={["image/png", "image/jpg", "image/jpeg"]}
                                listType="picture-card"
                                fileList={fileList}
                                action={null}
                                showUploadList={{ showPreviewIcon: false }}
                                onChange={this.handleChange}
                            >
                                {fileList.length >= 1 ? null :
                                    <div>
                                        <PlusOutlined />
                                        <div
                                            style={{
                                                marginTop: 8,
                                            }}
                                        >
                                            Upload
                                        </div>
                                    </div>}
                            </Upload>
                        </Form.Item>
                        <Col span={24}>
                            <Form.Item name="userTypes" label={"Recipient Type"}>
                                <Checkbox.Group
                                    style={{
                                        width: '100%',
                                    }}
                                    onChange={this.onChangeUserType}
                                >
                                    <Row>
                                        {
                                            userTypes.map((item) =>
                                                <Col span={24} style={{ marginBottom: 5 }}>
                                                    <Checkbox value={item.value}>{item.label}</Checkbox>
                                                </Col>
                                            )
                                        }
                                    </Row>
                                </Checkbox.Group>

                            </Form.Item>
                        </Col>
                        {
                            is_selected_partnership_admin ?
                                <Form.Item name="partnerAdminSpecialCode" label={"Partnership Admin Unique Code"} >
                                    <TextArea
                                        style={{ height: 100, background: '#fff' }}
                                        bordered={false}
                                        maxLength={255}
                                        showCount={true}
                                        placeholder={"Enter partnership admin's unique code with comma seperated"} />
                                </Form.Item>
                                :
                                <></>
                        }

                        <Col span={24}>
                            <Row>
                                <Col>
                                    <Form.Item
                                        name="active"
                                        valuePropName="checked">
                                        <Checkbox>Active</Checkbox>
                                    </Form.Item>


                                </Col>
                                <Col>
                                    <Form.Item
                                        name="isAutoPushNotification"
                                        onChange={this.onSelectAutoPushNotification}
                                        valuePropName="checked">
                                        <Checkbox>Enable Auto Push Notification</Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Form.Item style={{ textAlign: 'end' }}>
                            <Button type="primary" htmlType="submit" loading={is_creating_data}>Create</Button>
                        </Form.Item>
                    </Form>
                </Card>
            </>
        );
    }
}

export default compose(withRouter)(NotificationChannelForm)