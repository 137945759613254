import { Col, Row, Segmented } from 'antd';
import React, { Component } from 'react'
import { TitleLevel3 } from '../../../components/general-component';
import PricingGroupZonePage from './pricing-group-zone-page';
import PricingGroupZoneToZoneSetup from './pricing-group-zone-to-zone-setup';
import CustomPath from '../../../routes/custom-path';

const ZONE = 1
const PRICING_GROUP_ID = 4

export default class KarmartPricingGroupPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageType: ZONE,
            pring_group_id: props.pricingGroupId
        }
    }
    handleSegment = (value) => {
        this.setState({
            pageType: value
        })
    }
    render() {
        const { pageType } = this.state

        return (
            <Row gutter={[16, 16]}>
                <Col span={24} style={{ display: 'flex', alignItems: 'center' }}>
                    <TitleLevel3 label={"Karmart Pricing Group"} />
                </Col>
                <Col span={24}>
                    <Segmented
                        size='large'
                        block
                        options={[
                            {
                                label: <span style={{ fontWeight: '600', fontSize: 14, }}>Zone</span>,
                                value: 1
                            },
                            {
                                label: <span style={{ fontWeight: '600', fontSize: 14, }}>Zone To Zone Setup</span>,
                                value: 2
                            }
                        ]}
                        onChange={this.handleSegment}
                    />
                </Col>
                {
                    pageType === ZONE ?
                        <Col span={24}>
                            <PricingGroupZonePage pricingGroupId={PRICING_GROUP_ID} pricingGroupRoute={CustomPath.karmart_pricing_group} />
                        </Col>
                        :
                        <Col span={24}>
                            <PricingGroupZoneToZoneSetup pricingGroupId={PRICING_GROUP_ID} pricingGroupRoute={CustomPath.karmart_pricing_group} />
                        </Col>
                }
            </Row>
        )
    }
}
