import { createSlice } from '@reduxjs/toolkit'

export const menuSlice = createSlice({
    name: 'menu',
    initialState: {
        menuList: [],
        menuMap: {}
    },
    reducers: {
        updateMenu: (state, action) => {
            state.menuList = action.payload
            let menuMap = {}
            action.payload && action.payload.map(menuAccess => {
                menuMap[menuAccess.menu] = menuAccess
            })
            state.menuMap = menuMap
        },
        removeMenu: (state) => {
            state.menuList = []
        }
    },
})

export const { updateMenu, removeMenu } = menuSlice.actions
export const menuList = (state) => state.menu.menuList
export const menuMap = (state) => state.menu.menuMap
export default menuSlice.reducer
