import {
    ArrowLeftOutlined,
    PrinterOutlined
} from '@ant-design/icons';
import { compose } from "@reduxjs/toolkit";
import { Button, Col, Image, Layout, Row } from 'antd';
import { Content } from 'antd/es/layout/layout';
import dayjs from 'dayjs';
import React from 'react';
import { Link } from 'react-router-dom';
import ReactToPrint from "react-to-print";
import '../../App.css';
import withRouter from "../../network/with-router";
import AirWayBillSlip from "./awb-slip";

const logo = require('../../asset/Logo.png');

class PreviewAndPrintAWB extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            successfullOrderList: []
        }
    }

    componentDidMount() {
        const successfullOrderList = this.props.location.state ? this.props.location.state.data : null;
        
        const sortedList = [...successfullOrderList].sort((order1, order2) => {
            return order1?.recipientName?.localeCompare(order2.recipientName)
        })
        this.setState({
            successfullOrderList: sortedList
        })
    }

    render() {
        const { successfullOrderList } = this.state
        return (
            <>
                <Layout>
                    <div>
                        <div
                            style={{
                                height: "70px",
                                display: 'flex',
                                alignItems: 'center',
                                position: 'fixed',
                                left: 0,
                                right: 0,
                                top: 0,
                                paddingLeft: 10,
                                paddingRight: 10,
                                background: '#ffffff',
                                zIndex: 2,
                                boxShadow: '2px 2px 150px rgba(0, 0, 0, 0.1)',
                            }}
                        >
                            <Row style={{ width: '100%' }}>
                                <Col lg={12} md={12} sm={0} xs={0}>
                                    <Link to="/dashboard">
                                        <Image
                                            width={200}
                                            src={logo}
                                            preview={false}
                                        />
                                    </Link>
                                </Col>
                                <Col lg={12} md={12} sm={0} xs={0}>
                                    <Row style={{ height: '100%' }}>
                                        <Col span={24} style={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                                            <div style={{ textAlign: 'right', marginRight: 10 }}>
                                                <div
                                                    style={{ fontSize: 18, fontWeight: '600' }}
                                                >
                                                    {/* {shipperName} */}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col lg={0} md={0} sm={24} xs={24}>
                                    <Row>
                                        <Col span={12}>
                                            {/* <Button type='text' shape="square" icon={<AlignRightOutlined />} size={'large'} onClick={showDrawer} /> */}
                                            <Image
                                                width={120}
                                                src={logo}
                                                preview={false}
                                            />
                                        </Col>
                                        <Col span={12} style={{ textAlign: 'right' }}>
                                            {/* <Image
                                                preview={false}
                                                style={{
                                                    width: 45,
                                                    height: 45,
                                                    padding: 1,
                                                    objectFit: 'cover',
                                                    cursor: 'pointer'
                                                }}
                                                src={shipper_profile} /> */}
                                        </Col>
                                    </Row>
                                </Col>


                            </Row>
                        </div>
                    </div>

                    <Layout style={{
                        padding: '0 24px 24px',
                    }}
                    >
                        <Content style={{
                            position: 'fixed',
                            top: 60,
                            right: 0,
                            left: 0,
                            bottom: 0,
                            padding: '50px 0px 24px 0px',
                            margin: 0,
                            overflow: 'scroll',
                            background: 'white'
                        }}>
                            <Row>
                                <Col span={24}>
                                    <Link to={this.props.location.state.route}><Button type='primary' icon={<ArrowLeftOutlined />} style={{ marginLeft: 10, marginTop: 10, marginBottom: 10 }}>Back</Button></Link>

                                </Col>
                                <Col span={24}>
                                    <div
                                        key={1}
                                        ref={(el) => this.refComponent = el}>
                                        {
                                            successfullOrderList && successfullOrderList.length !== 0 ?
                                                <>
                                                    {
                                                        successfullOrderList.map((order, index) => {

                                                            let fromAddress = order.fromAddress
                                                            let toAddress = order.toAddress
                                                            let createdDate = ''
                                                            if (order && order.fromTownship) {
                                                                fromAddress = fromAddress + "," + order.fromTownship.name
                                                                if (order.fromTownship.division) {
                                                                    fromAddress = fromAddress + "," + order.fromTownship.division.name
                                                                }
                                                            }
                                                            if (order && order.toTownship) {
                                                                toAddress = toAddress + "," + order.toTownship.name
                                                                if (order.toTownship.division) {
                                                                    toAddress = toAddress + "," + order.toTownship.division.name
                                                                }
                                                            }


                                                            if (order.createdDate != null) {
                                                                const date = dayjs(order.createdDate, 'YYYY-MM-DD HH:mm A');
                                                                if (date) {
                                                                    createdDate = date.format('YYYY-MMM-DD HH:mm A')
                                                                }
                                                            }
                                                            return <AirWayBillSlip
                                                                key={index}
                                                                senderName={order.businessName ?? order.senderName}
                                                                senderPhone={order.senderPhoneNumber}
                                                                senderAddress={fromAddress}
                                                                receiverName={order.recipientName}
                                                                receiverPhone={order.recipientPhoneNumber}
                                                                receiverAddress={toAddress}
                                                                trackId={order.trackingNumber}
                                                                createDate={createdDate}
                                                                parcelAmount={order.parcelAmount}
                                                                deliveryCharges={order.deliveryCharges}
                                                                cod={order.codAmount}
                                                                note={order.note}
                                                                sortingCode={order.sortingCode}
                                                            />



                                                        })
                                                    }
                                                </>
                                                :
                                                <></>
                                        }
                                    </div>
                                </Col>
                                <Col span={24}>
                                    <ReactToPrint
                                        trigger={() => <Button type='primary' icon={<PrinterOutlined />} style={{ marginLeft: 10, marginTop: 10, marginBottom: 10 }}>Print Air Way Bill</Button>}
                                        content={() => this.refComponent}
                                    />
                                </Col>
                            </Row>
                        </Content>
                    </Layout>
                </Layout >
            </>
        )
    }

}

export default compose(withRouter)(PreviewAndPrintAWB)