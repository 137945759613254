import { Col, Row, Skeleton, Table } from 'antd'
import React from 'react'
import { compose } from "redux";
import withRouter from "../../network/with-router";
import { TitleLevel3 } from '../../components/general-component';
import { ApiHandler } from '../../network/network-manager';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { Constant } from '../../core/constant';

class TicketPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isDataFetching: false,
            pageable: { page: 0, pageSize: Constant.pageSize, totalPagination: 0 },
            data: [],
            filterData: {
            }
        }
    }
    componentDidMount = () => {
        this.fetchData()
    }

    fetchData = async (pageNumber) => {
        const { pageable, filterData } = this.state
        let formattedFilterData = { ...filterData };

        formattedFilterData['date-range-picker'] = null;
        formattedFilterData.fromDate = filterData['date-range-picker'] ? formattedFilterData.fromDate.format("YYYY-MM-DD") : null;
        formattedFilterData.toDate = filterData['date-range-picker'] ? formattedFilterData.toDate.format("YYYY-MM-DD") : null;


        this.setState({
            isDataFetching: true
        })
        const requestParams = {
            size: pageable.pageSize,
            page: pageNumber ? pageNumber - 1 : pageable.page,
            ...formattedFilterData
        }
        await ApiHandler({ url: Api.tickets, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            .then(response => {
                this.setState((prevState) => ({
                    data: response?.content,
                    pageable: { ...prevState.pageable, totalPagination: response.totalElements, page: pageNumber ? pageNumber - 1 : pageable.page }
                }))
            }).catch(error => {

            }).finally(() => {
                this.setState({
                    isDataFetching: false
                })
            })
    }
    render() {
        const { isDataFetching, pageable, data } = this.state
        const getRowClassName = (record, index) => {
            return index % 2 === 0 ? 'blue-row table-row-pointer' : 'black-row table-row-pointer';
        };
        const columns = [
            {
                title: 'No.',
                key: 'index',
                render: (value, item, index) => <span>{(pageable?.page) * pageable?.pageSize + index + 1}</span>,
                width: 5
            },

            {
                title: 'Ticket Number',
                key: 'ticketNumber',
                dataIndex: 'ticketNumber',
            },
            {
                title: 'Title',
                key: 'title',
                dataIndex: 'title',
            },
            {
                title: 'Description',
                dataIndex: 'description',
                key: 'description',
            },
            {
                title: 'Ticket Type',
                dataIndex: 'orgTicketType',
                key: 'orgTicketType',
            },
            {
                title: 'Ticket State',
                dataIndex: 'orgTicketState',
                key: 'orgTicketState',
            },
            {
                title: 'Action',
                width: 100,
                render: (item) => (
                    <>
                    </>
                )
            },
        ]

        return (
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <TitleLevel3 label={"Ticket"} />
                </Col>
                <Col span={24}>
                    {
                        isDataFetching && <Skeleton active />
                    }
                    {
                        !isDataFetching && <Table
                            key={"ticket"}
                            style={{ borderRadius: 10 }}
                            pagination={{
                                position: 'top' | 'bottom',
                                total: pageable?.totalPagination,
                                current: pageable?.page + 1,
                                onChange: this.handlePaginationChange,
                                defaultPageSize: pageable?.pageSize,
                                showSizeChanger: false

                            }}
                            columns={columns}
                            dataSource={data}
                            rowClassName={getRowClassName}
                            onRow={(record) => ({
                                onClick: () => this.handleDrawerChange(record), // Add onClick event for row
                            })}
                        />
                    }
                </Col>
            </Row>
        )
    }
}
export default compose(withRouter)(TicketPage)