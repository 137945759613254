import { compose } from "@reduxjs/toolkit";
import { Button, Col, Row, Skeleton, Space, Table } from "antd";
import React from "react";
import { SvgLocationFilled } from "../../components/custom-svg";
import { TitleLevel3 } from "../../components/general-component";
import { Constant } from "../../core/constant";
import Api from "../../network/api";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import { ApiHandler } from "../../network/network-manager";
import withRouter from "../../network/with-router";
import CustomPath from "../../routes/custom-path";

class DriverLocationLogPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            page: 1,
            totalPagination: 0,
            pageSize: Constant.pageSize,
            isDataFetching: true
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = async (pageNumber) => {
        const { page, pageSize } = this.state
        const requestParams = {
            page: pageNumber ? pageNumber - 1 : page - 1,
            size: pageSize
        }
        await ApiHandler({ url: Api.driver_location_logs, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            .then(response => {
                this.setState({
                    data: response?.content,
                    totalPagination: response?.totalElements,
                    page: pageNumber ? pageNumber : page
                })
            })
            .catch(error => { })
            .finally(() => this.setState({ isDataFetching: false }))
    }

    handlePaginationChange = (pageNumber, pageSize) => {
        this.fetchData(pageNumber);
    };

    goToDetail = (log) => {
        this.props.navigate(
            CustomPath.driver_location_log_detail,
            { state: { data: log } }
        );
    }

    render() {
        const { isDataFetching, totalPagination, pageSize, page, data } = this.state

        const getRowClassName = (record, index) => {
            return index % 2 === 0 ? 'blue-row' : 'black-row';
        };
        const columns = [
            {
                title: 'No.',
                key: 'index',
                render: (value, item, index) => <span>{(page - 1) * pageSize + index + 1}</span>,
                width: 5
            },
            {
                title: 'Driver Name',
                key: 'driverName',
                render: (log) => (
                    <span>{log?.driver?.fullName}</span>
                ),
            },
            {
                title: 'Assign Township',
                key: 'assignTownship',
                render: (log) => (
                    <span>{log?.driver?.assignTownship?.division?.name}/{log?.driver?.assignTownship?.name}</span>
                ),
            },
            {
                title: 'Date',
                dataIndex: 'date',
                key: 'date',
            },
            {
                title: 'Action',
                key: 'action',
                textAlign: 'center',
                width: 60,
                render: (log) => (
                    <Space>
                        {/* <Link to={`${CustomPath.driver_location_log_detail}/${log.id}`}></Link> */}
                        <Button size={'middle'} type="primary" shape="square" onClick={() => this.goToDetail(log)}><SvgLocationFilled width={20} height={20} color={'#fff'} /></Button>
                    </Space>
                ),
            },
        ]
        return (
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <TitleLevel3 label={"Driver Location Log"} />
                </Col>
                <Col span={24}>
                    {
                        isDataFetching ?
                            <Skeleton active />
                            :
                            <Table
                                style={{ borderRadius: 10 }}
                                pagination={{
                                    position: 'top' | 'bottom',
                                    total: totalPagination,
                                    current: page,
                                    onChange: this.handlePaginationChange,
                                    defaultPageSize: pageSize,
                                    showSizeChanger: false
                                }}
                                columns={columns} dataSource={data}
                                rowClassName={getRowClassName}
                            />
                    }
                </Col>
            </Row>
        )
    }
}

export default compose(withRouter)(DriverLocationLogPage)