import React, { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions.css'
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';
import './MapboxMap.css'
import { Button, Col, Row } from 'antd';
import CustomPath from '../../routes/custom-path';
import { SvgArrowLeftFilled } from '../../components/custom-svg';
mapboxgl.workerClass = function () { return new Worker(new URL('./mapboxWorker.js', import.meta.url)); }

const DriverLocationMapbox = () => {
    const mapContainerRef = useRef(null);
    const [directions, setDirections] = useState(null);
    const [loading, setLoading] = useState(false)
    const accessToken = 'pk.eyJ1Ijoibmp2bW0iLCJhIjoiY2xuMTR6cjlqMDhhYTJqb2kwbmtudDdiciJ9.we-mhra0h3nMtaH1KU0WpQ';

    const location = useLocation();
    const data = location.state?.data;
    const locationarr = data?.locations.map(log => Object.values(log))

    const waypoints = locationarr?.map(([lat, long]) => [long, lat])?.filter(([lat, long]) => !(lat === 0 && long === 0))

    const startPoint = waypoints ? waypoints[0] : [96.205926, 16.8163903];
    const endPoint = waypoints ? waypoints[waypoints.length - 1] : [96.205926, 16.8163903];

    const generateDirectionsUrl = (waypoints, accessToken) => {
        const baseUrl = 'https://api.mapbox.com/directions/v5/mapbox/driving/';
        const coordinates = waypoints.map(coord => coord.join(',')).join(';');
        const params = new URLSearchParams({
            alternatives: 'true',
            geometries: 'geojson',
            language: 'en',
            overview: 'full',
            steps: 'true',
            access_token: accessToken
        });
        return `${baseUrl}${coordinates}?${params.toString()}`;
    };

    const chunkArray = (array, size) => {
        const result = [];
        for (let i = 0; i < array?.length; i += size) {
            result.push(array.slice(i, i + size));
        }
        return result;
    };

    useEffect(() => {
        const fetchDirectionsForChunks = async () => {

            const chunks = chunkArray(waypoints, 10);
            const combinedRoutes = [];

            for (const chunk of chunks) {
                setLoading(true)
                const url = generateDirectionsUrl(chunk, accessToken);
                try {
                    const response = await axios.get(url);
                    const { routes } = response.data;
                    if (routes?.length > 0) {
                        combinedRoutes.push(routes[0].geometry);
                    }
                    setLoading(false)
                } catch (error) {
                    console.error('Error fetching directions:', error);
                }
            }
            setDirections(combinedRoutes);
        }

        fetchDirectionsForChunks();
    }, [])

    useEffect(() => {
        if (directions?.length > 0) {

            mapboxgl.accessToken = process.env.REACT_APP_MAP_BOX_TOKEN;
            const map = new mapboxgl.Map({
                container: mapContainerRef.current,
                style: 'mapbox://styles/mapbox/streets-v12',
                center: startPoint,
                zoom: 14,
            });

            map.on('load', () => {

                directions.forEach((route, index) => {
                    map.addSource(`route-${index}`, {
                        type: 'geojson',
                        data: {
                            type: 'Feature',
                            geometry: route
                        }
                    });

                    map.addLayer({
                        id: `route-${index}`,
                        type: 'line',
                        source: `route-${index}`,
                        layout: {
                            'line-cap': 'round',
                            'line-join': 'round'
                        },
                        paint: {
                            'line-color': '#3887be',
                            'line-width': ['interpolate', ['linear'], ['zoom'], 12, 3, 22, 12]
                        },
                    },
                        'waterway-label'
                    );

                    map.addLayer(
                        {
                            id: `route-arrows-${index}`,
                            type: 'symbol',
                            source: `route-${index}`,
                            layout: {
                                'symbol-placement': 'line',
                                'text-field': '▶',
                                'text-size': ['interpolate', ['linear'], ['zoom'], 12, 24, 22, 60],
                                'symbol-spacing': ['interpolate', ['linear'], ['zoom'], 12, 30, 22, 160],
                                'text-keep-upright': false
                            },
                            paint: {
                                'text-color': '#c2002f',
                                'text-halo-color': 'hsl(55, 11%, 96%)',
                                'text-halo-width': 3
                            }
                        },
                        'waterway-label'
                    );
                });

                map.addMarker({
                    coordinates: startPoint,
                    color: '#00ff00',
                    description: 'Start Point'
                })

                map.addMarker({
                    coordinates: endPoint,
                    color: '#ff0000',
                    description: 'End Point'
                });

                const bounds = new mapboxgl.LngLatBounds();
                directions.forEach(route => {
                    route.coordinates.forEach(coord => bounds.extend(coord));
                });
                bounds.extend(startPoint);
                bounds.extend(endPoint);
                map.fitBounds(bounds, { padding: 50 });
            });
        }
    }, [directions])

    return (
        <Row gutter={[16, 16]}>
            <Col span={24} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Link to={CustomPath.driver_location_log}>
                        <Button type="primary" shape="circle" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: 10 }}>
                            <SvgArrowLeftFilled width={20} height={20} color={'#fff'} />
                        </Button>
                    </Link>

                    <span style={{ fontSize: 18, fontWeight: '600' }}>{`${data?.driver?.specialCode} (${data?.driver?.fullName}) - ${data?.driver?.phoneNumber} (${data?.driver?.assignTownship?.division?.name}/${data?.driver?.assignTownship?.name})`}</span>
                </div>
                <span style={{ fontSize: 18, fontWeight: '600' }}>{data?.date}</span>

            </Col>
            <Col span={24}>
                <div className="map-container">
                    <div ref={mapContainerRef} style={{ height: '90vh' }} />
                    {loading && <div className="loading-layer">Loading...</div>}
                </div>
            </Col>
        </Row>
    )
}

mapboxgl.Map.prototype.addMarker = function ({ coordinates, color, description }) {
    new mapboxgl.Marker({ color })
        .setLngLat(coordinates)
        .setPopup(new mapboxgl.Popup().setText(description))
        .addTo(this);
};

export default DriverLocationMapbox