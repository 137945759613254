import { EditOutlined } from "@ant-design/icons";
import { Button, Col, Row, Skeleton, Table, Tag } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { NJVAddButton } from "../../../components/core-component";
import { TitleLevel3 } from "../../../components/general-component";
import { Constant } from "../../../core/constant";
import Api from "../../../network/api";
import { ApiFilter } from "../../../network/network-manager";
import withRouter from "../../../network/with-router";
import CustomPath from "../../../routes/custom-path";

class PartnershipAdminPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pageSize: Constant.pageSize,
            totalPagination: 0,
            page: 1,
            searchName: '',
            searchPhone: '',
            openModal: false,
            openCreateModal: false,
            openUpdateModal: false,
            activeStatus: true,
            updateForm: {},
            updateDivision: {},
            isDataFetching: false
        }
        this.inputRef = React.createRef();
        this.updateRef = React.createRef();
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = async (pageNumber) => {
        const { page, searchName, searchPhone, pageSize } = this.state
        this.setState({
            isDataFetching: true
        })
        try {
            var params = {
                name: searchName,
                phone: searchPhone,
                size: pageSize,
                page: pageNumber ? pageNumber - 1 : page - 1
            }
            const response = await ApiFilter(Api.partnership_admin, null, this.props)
            if (response) {
                this.setState({
                    data: response,
                    // totalPagination: response.totalElements,
                    // page: pageNumber ? pageNumber : page
                })
            }
        } catch (error) {
        }
        this.setState({
            isDataFetching: false
        })
    }

    changeSearchName = (event) => {
        this.setState({
            searchName: event.target.value
        })
    }

    changeSearchPhone = (event) => {
        this.setState({
            searchPhone: event.target.value
        })
    }

    search = () => {
        const { page } = this.state
        this.fetchData(page)
    }

    handlePaginationChange = (pageNumber, pageSize) => {
        this.fetchData(pageNumber);
    };

    gotoStore = (id, name) => {
        let data = {
            id: id,
            name: name
        }
        this.props.navigate(
            CustomPath.partnership_store,
            { state: { data: data } }
        );
    }


    render() {
        const { data, totalPagination, page, pageSize, isDataFetching } = this.state
        const getRowClassName = (record, index) => {
            return index % 2 === 0 ? 'blue-row' : 'black-row';
        };


        const columns = [
            {
                title: 'No.',
                key: 'index',
                render: (value, item, index) => <span>{(page - 1) * pageSize + index + 1}</span>,
                width: 5
            },
            {
                title: 'Full Name',
                key: 'fullName',
                render: (admin) => (
                    <span>{admin.firstName} {admin.lastName}</span>
                ),
            },
            {
                title: 'Phone Number',
                dataIndex: 'phoneNumber',
                key: 'phoneNumber'
            },
            {
                title: 'Status',
                key: 'active',
                render: (_, { userStatus }) => (
                    <>
                        {
                            (
                                userStatus === "ACTIVE" ? <Tag color="#87d068">{userStatus}</Tag> : <Tag color="#cd201f">{userStatus}</Tag>
                            )
                        }
                    </>
                ),

            }, {
                title: 'Store',
                key: 'store',
                render: (admin) => (
                    <Button
                        type="dashed"
                        style={{ backgroundColor: '#f1f1f1' }} onClick={() => this.gotoStore(admin.id, admin.firstName + "" + admin.lastName)} > View Store</Button >
                ),

            }, {
                title: 'Action',
                key: 'action',
                dataIndex: '',
                textAlign: 'center',
                width: 60,
                render: (admin) => (
                    <Link to={`${CustomPath.partnership_admin_update}/${admin.id}`}><Button size={'middle'} type="primary" shape="circle"><EditOutlined /></Button></Link>
                ),
            },
        ]

        return (
            <Row style={{ alignItems: 'center' }} gutter={[20, 20]}>
                <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
                    <TitleLevel3 label={"Partnership Admin"} />
                </Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                    <Link to={CustomPath.partnership_admin_create}><NJVAddButton /></Link>
                </Col>
                {/* <Col span={24}>
                                <Input
                                    onChange={this.changeSearchName}
                                    style={{ width: 200, background: '#f1f1f1', height: 40, marginRight: 8 }}
                                    placeholder="Search by name"
                                />
                                <Input
                                    onChange={this.changeSearchPhone}
                                    style={{ width: 200, background: '#f1f1f1', height: 40, marginRight: 8 }}
                                    placeholder="Search by phone"
                                />
                                <Button onClick={() => this.search()} type="primary" style={{ borderRadius: 10, height: 40 }}>Search</Button>
                            </Col> */}
                <Col span={24}>

                    {
                        isDataFetching ?
                            <Skeleton active />
                            :
                            <Table
                                // className="custom-table"
                                style={{ borderRadius: 10 }}
                                // pagination={{
                                //     position: 'top' | 'bottom',
                                //     total: totalPagination,
                                //     current: page,
                                //     onChange: this.handlePaginationChange,
                                //     defaultPageSize: pageSize,
                                //     showSizeChanger: false
                                // }}
                                pagination={false}
                                columns={columns}
                                dataSource={data}
                                rowClassName={getRowClassName}
                            />
                    }
                </Col>
            </Row>
        );
    }
}

export default compose(withRouter)(PartnershipAdminPage)