import { createSlice } from '@reduxjs/toolkit'

export const notiSlice = createSlice({
    name: 'noti',
    initialState: {
        pending_count: 0,
        fetchData: false
    },
    reducers: {
        updatePendingCount: (state, action) => {
            state.pending_count = action.payload
            
        },
        makeFetchDate: (state, action) => {
            state.fetchData = action.payload
        },
        clearAll: (state, action) => {
            state.pending_count = 0
        }


    },
})

export const { updatePendingCount, makeFetchDate } = notiSlice.actions
export const notiPendingCount = (state) => state.noti.pending_count
export const notiFetchData = (state) => state.noti.fetchData
export default notiSlice.reducer
