import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { compose } from "@reduxjs/toolkit";
import { Button, Col, Modal, Row, Space, Table, Skeleton } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { NJVAddButton } from "../../components/core-component";
import { SvgExcelOutlined } from "../../components/custom-svg";
import { TitleLevel3 } from "../../components/general-component";
import { Colors } from "../../components/theme";
import { Constant } from "../../core/constant";
import Api from "../../network/api";
import { ApiDelete, ApiFilter, downloadExcel } from "../../network/network-manager";
import withRouter from "../../network/with-router";
import CustomPath from "../../routes/custom-path";

class CoreConfigPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pageSize: Constant.pageSize,
            totalPagination: 0,
            page: 1,
            searchValue: [],
            openModal: false,
            openCreateModal: false,
            openUpdateModal: false,
            activeStatus: true,
            updateForm: {},
            updateDivision: {},
            searchName: '',
            isDataFetching: true,
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    downloadExampleTemplate = async () => {
        this.setState({
            downloadingExcel: true
        })
        try {
            const response = await downloadExcel(Api.download_order_sample_excel)
            if (response && response.status === 200) {
                this.setState({
                    downloadingExcel: false
                })
            }
        } catch (error) {
            this.setState({
                downloadingExcel: false
            })
        }
    }


    //filter 
    changeSearchName = (event) => {
        this.setState({
            searchName: event.target.value
        })
    }

    search = () => {

    }

    deleteCoreConfig = (id) => {
        this.setState({
            openModal: true,
            deleteItem: id
        });
    }

    deleteCoreConfigConfirm = async () => {
        const { deleteItem } = this.state
        try {
            await ApiDelete(Api.core_config, deleteItem, this.props)
        } catch (error) {

        }
        this.setState({
            openModal: false,
            deleteItem: ''
        });
        this.fetchData()
    }

    fetchData = async (pageNumber, value) => {
        const { page, pageSize } = this.state
        try {
            var params = {
                size: pageSize,
                page: pageNumber ? pageNumber - 1 : page - 1
            }
            const response = await ApiFilter(Api.core_config_filter, params, this.props)
            if (response && response.content) {

                this.setState(prevState => ({
                    data: response.content,
                    totalPagination: response.totalElements,
                    page: pageNumber ? pageNumber : page
                }));
            }
        } catch (error) {
        }
        value ?? this.setState({ searchValue: value })
        this.setState({
            isDataFetching: false
        })
    }

    handlePaginationChange = (pageNumber, pageSize) => {
        this.fetchData(pageNumber);
    };


    render() {
        const { data, totalPagination, page, pageSize, openModal, isDataFetching } = this.state
        const getRowClassName = (record, index) => {
            return index % 2 === 0 ? 'blue-row' : 'black-row';
        };
        const columns = [
            {
                title: 'No.',
                key: 'index',
                render: (value, item, index) => <span>{(page - 1) * pageSize + index + 1}</span>,
                width: 5
            },
            {
                title: 'Config Type',
                dataIndex: 'coreType',
                key: 'coreType',
                width: 300
            },
            {
                title: 'Key',
                dataIndex: 'key',
                key: 'key',
            }, {
                title: 'Value',
                key: 'value',
                textAlign: 'center',
                render: (CoreConfig) => (
                    <>
                        {
                            CoreConfig.coreType === 'OC_EXCEL_TEMPLATE' ?

                                <Button type="primary" size="small" onClick={() => this.downloadExampleTemplate()} style={{ backgroundColor: Colors.primary, display: 'flex', justifyContent: 'center', marginRight: 20, alignItems: 'center' }}>
                                    <SvgExcelOutlined width={15} height={15} color={'white'} />
                                    <span style={{ marginLeft: 5 }}> Download</span>
                                </Button>
                                :
                                <span>{CoreConfig.value}</span>
                        }
                    </>
                ),
            },
            {
                title: 'Action',
                key: 'action',
                dataIndex: '',
                textAlign: 'center',
                width: 60,
                render: (CoreConfig) => (
                    <Space>
                        <Link to={`${CustomPath.core_config_update}/${CoreConfig.id}`}><Button size={'middle'} type="primary" shape="circle"><EditOutlined /></Button></Link>
                        <Button size={'middle'} type="primary" shape="circle" onClick={() => this.deleteCoreConfig(CoreConfig.id)}><DeleteOutlined /></Button>

                    </Space>
                ),
            },
        ]
        return (
            <>
                <Modal
                    title="Delete"
                    open={openModal}
                    onOk={() => this.deleteCoreConfigConfirm()}
                    onCancel={() => this.setState({ openModal: false })}
                    okText="Confirm"
                    cancelText="Cancel"
                >
                    <p>Are you sure to delete?</p>
                </Modal>
                <Row gutter={[16, 16]}>
                    <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
                        <TitleLevel3 label={"Core Config"} />
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        <Link to={CustomPath.core_config_form}><NJVAddButton /></Link>
                    </Col>
                    <Col span={24}>

                        {
                            isDataFetching ?
                                <Skeleton active />
                                :
                                <Table
                                    style={{ borderRadius: 10 }}
                                    pagination={{
                                        position: 'top' | 'bottom',
                                        total: totalPagination,
                                        current: page,
                                        onChange: this.handlePaginationChange,
                                        defaultPageSize: pageSize
                                    }}
                                    columns={columns} dataSource={data}
                                    rowClassName={getRowClassName}
                                />
                        }
                    </Col>
                </Row>
            </>
        );
    }
}


export default compose(withRouter)(CoreConfigPage)