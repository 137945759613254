import { EditOutlined } from "@ant-design/icons";
import { compose } from "@reduxjs/toolkit";
import { Button, Card, Col, Drawer, Empty, Flex, Form, Row, Skeleton, Tabs } from "antd";
import React from "react";
import { NJVAddButton, NJVSelect } from "../../components/core-component";
import DynamicEnumSelect from "../../components/FetchEnum";
import { TitleLevel3 } from "../../components/general-component";
import Theme from "../../components/theme";
import Api from "../../network/api";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import { ApiHandler } from "../../network/network-manager";
import withRouter from "../../network/with-router";
import CustomPath from "../../routes/custom-path";


class OrgChartPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            departments: [],
            departmentTabs: [],
            departmentOptions: [],
            orgChartData: [],
            orgChartDataMap: new Map(),
            isDataFetching: false,
            openCreateFormDrawer: false,
            is_fetching_data: false,
        }
    }
    componentDidMount() {
        this.fetchDepartmentData()
    }

    fetchDepartmentData = async () => {
        this.setState({
            is_fetching_data: true
        })
        await ApiHandler({ url: Api.department_filter, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON })
            .then(response => {
                let departmentTabs = []
                let departmentOptions = []
                response?.forEach(res => {
                    departmentTabs.push({
                        key: res.id,
                        label: res.name
                    })
                    departmentOptions.push({
                        value: res.id,
                        label: res.name
                    })
                })
                this.setState({
                    departments: response,
                    departmentOptions,
                    departmentTabs
                }, () => this.fetchOrgChartData());
            }).catch(error => {
            }).finally(() => {
                this.setState({
                    is_fetching_data: false,
                })
            })
    }

    fetchOrgChartData = async () => {
        const { departments } = this.state
        await ApiHandler({ url: Api.org_chart, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON })
            .then(response => {
                let orgChartMap = new Map()

                response?.forEach(res => {
                    let list = []
                    if (orgChartMap.has(res.departmentId)) {
                        list = orgChartMap.get(res.departmentId)
                    }
                    list.push(res)
                    orgChartMap.set(res.departmentId, list)
                })
                let orgChartData = []
                if (departments && departments.length > 0) {
                    orgChartData = orgChartMap.get(departments[0].id) ?? []
                }
                this.setState({
                    orgChartDataMap: orgChartMap,
                    orgChartData
                });
            }).catch(error => {
            }).finally(() => {
                this.setState({
                    isDataFetching: false,
                })
            })
    }

    createOrgChart = async (formData) => {
        await ApiHandler({ url: Api.org_chart, method: HTTP_METHOD.POST, mediaType: MEDIA_TYPE.JSON, requestData: formData })
            .then(response => {
                this.setState({ openCreateFormDrawer: false })
            })
            .catch(err => { })
            .finally(() => { })
    }

    onTabChange = (data) => {
        const { orgChartDataMap } = this.state
        let orgChartData = orgChartDataMap.get(data) ?? []
        this.setState({ orgChartData })

    }

    goToParticipantPage = (item) => {
        const { departments } = this.state
        let departmentData = departments.find(department => department.id === item.departmentId)
        departmentData['orgStructureId'] = item.id
        departmentData['orgTicketType'] = item.orgTicketType
        this.props.navigate(CustomPath.org_chart_user_assign, { state: { data: departmentData } })
    }

    render() {
        const { departmentTabs, openCreateFormDrawer, departmentOptions, orgChartData, is_fetching_data } = this.state
        return (
            <>
                <Drawer
                    title={"Org Chart"}
                    width="30%"
                    closable={false}
                    onClose={() => this.setState({ openCreateFormDrawer: false })}
                    open={openCreateFormDrawer}
                    extra={
                        <Button type="primary" onClick={() => this.setState({ openCreateFormDrawer: false })}>Close</Button>
                    }
                >
                    <Form
                        layout="vertical"
                        onFinish={this.createOrgChart}>
                        <Form.Item name="departmentId" label="Department" rules={[{ required: true, message: '*(requried)' }]}>
                            <NJVSelect
                                size="large"
                                placeholder="Select department"
                                bgcolor={Theme.colors.input_bg_color}
                                options={departmentOptions} />
                        </Form.Item>
                        <Form.Item name="orgTicketType" label="Ticket type" rules={[{ required: true, message: '*(requried)' }]}>
                            <DynamicEnumSelect enumtype='ORG_TICKET_TYPE' size='large' bgcolor={Theme.colors.input_bg_color} placeholder={"Select Ticket Type"} width="100%" />
                        </Form.Item>
                        <Form.Item >
                            <Button type="primary" htmlType="submit" loading={this.state.isLoading}>Create</Button>
                        </Form.Item>
                    </Form>
                </Drawer>
                <Row gutter={[16, 16]}>
                    <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
                        <TitleLevel3 label={"Organization Chart"} />
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        <NJVAddButton handleClick={() => this.setState({ openCreateFormDrawer: true })} />
                    </Col>
                    <Col span={24}>
                        <Tabs defaultActiveKey={departmentTabs[0]?.key} items={departmentTabs} onChange={this.onTabChange} />
                    </Col>
                    <Col span={24}>
                        <Row gutter={[16, 16]}>
                            {
                                is_fetching_data && <Col span={24}><Skeleton active /></Col>
                            }
                            {
                                orgChartData && orgChartData.length > 0 ?
                                    <>
                                        {
                                            orgChartData.map(item => (
                                                <Col span={6}>
                                                    <Card
                                                        style={{ padding: 10 }}
                                                        className="card-shadow">
                                                        <Flex
                                                            justify="space-between"
                                                            align="center">
                                                            <span>{item?.orgTicketType}</span>
                                                            <Button type="primary" shape="circle" icon={<EditOutlined />} onClick={() => this.goToParticipantPage(item)} />
                                                        </Flex>

                                                    </Card>

                                                </Col>
                                            ))
                                        }
                                    </>
                                    :
                                    <Col span={24}>
                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                    </Col>
                            }
                        </Row>
                    </Col>
                </Row>
            </>
        )
    }

}
export default compose(withRouter)(OrgChartPage)
