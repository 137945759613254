import { EditOutlined } from "@ant-design/icons";
import { compose } from "@reduxjs/toolkit";
import { Button, Col, Modal, Row, Skeleton, Space, Table, Tag } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { NJVAddButton } from "../../../components/core-component";
import { TitleLevel3 } from "../../../components/general-component";
import { Constant } from "../../../core/constant";
import Api from "../../../network/api";
import { ApiDelete, ApiFilter } from "../../../network/network-manager";
import withRouter from "../../../network/with-router";
import CustomPath from "../../../routes/custom-path";

class RolePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pageSize: Constant.pageSize,
            totalPagination: 0,
            page: 1,
            searchValue: [],
            openModal: false,
            openCreateModal: false,
            openUpdateModal: false,
            isFetchingData: true,
            activeStatus: true,
            updateForm: {},
            updateDivision: {}
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    deleteRole = (id) => {
        this.setState({
            openModal: true,
            deleteItem: id
        });
    }

    deleteRoleConfirm = async () => {
        const { deleteItem } = this.state
        try {
            await ApiDelete(Api.role, deleteItem, this.props)
        } catch (error) {

        }
        this.setState({
            openModal: false,
            deleteItem: ''
        });
        this.fetchData()
    }

    //fetch data
    fetchData = async (pageNumber, value) => {
        const { page, searchValue } = this.state
        try {
            var params = {
                page: 0,
                size: 100
            }
            const response = await ApiFilter(Api.role_filter, params, this.props)
            if (response && response.content) {
                this.setState(prevState => ({
                    data: response.content,
                    totalPagination: response.totalElements,
                    page: pageNumber ? pageNumber : page
                }));
            }
        } catch (error) {
        }
        this.setState({
            isFetchingData: false
        })
    }

    render() {
        const { data, openModal, isFetchingData } = this.state
        const getRowClassName = (record, index) => {
            return index % 2 === 0 ? 'blue-row' : 'black-row';
        };
        const columns = [
            {
                title: 'No.',
                key: 'index',
                render: (value, item, index) => <span>{index + 1}</span>,
                width: 5
            },
            {
                title: 'Role',
                dataIndex: 'roleType',
                key: 'roleType',
                width: 200
            }, {
                title: 'Menu',
                key: 'menu',
                render: (role) => (
                    <div>
                        {
                            role.menus && role.menus.map(menu => {
                                return <Tag style={{ marginBottom: 7 }}>{menu.name}</Tag>
                            })
                        }
                    </div>
                ),

            },
            {
                title: 'Action',
                key: 'action',
                dataIndex: '',
                textAlign: 'center',
                width: 60,
                render: (role) => (
                    <Space>
                        <Link to={`${CustomPath.role_update}/${role.id}`}><Button size={'middle'} type="primary" shape="circle"><EditOutlined /></Button></Link>
                        {/* <Button size={'middle'} type="primary" shape="circle" onClick={() => this.deleteRole(role.id)}><DeleteOutlined /></Button> */}
                    </Space>
                ),
            },
        ]
        return (
            <>
                <Modal
                    title="Delete"
                    open={openModal}
                    onOk={() => this.deleteRoleConfirm()}
                    onCancel={() => this.hideModal()}
                    okText="Confirm"
                    cancelText="Cancel"
                >
                    <p>Are you sure to delete?</p>
                </Modal>
                <Row style={{ marginBottom: "20px" }}>
                    <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
                        <TitleLevel3 label={"Role & Permission"} />
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        <Link to={CustomPath.role_create}><NJVAddButton /></Link>
                    </Col>
                </Row>
                {
                    isFetchingData ?
                        <Skeleton active />
                        :
                        <Table
                            style={{ borderRadius: 10 }}
                            pagination={false}
                            columns={columns}
                            dataSource={data}
                            rowClassName={getRowClassName}
                        />

                }
            </>
        );
    }
}


export default compose(withRouter)(RolePage)