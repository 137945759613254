import {
    DeleteOutlined,
    DownloadOutlined,
    EditOutlined,
    InboxOutlined,
    LoadingOutlined,
    PrinterOutlined,
    QuestionOutlined
} from '@ant-design/icons';
import { compose } from '@reduxjs/toolkit';
import { Breadcrumb, Button, Card, Checkbox, Col, Input, Modal, Radio, Row, Segmented, Select, Space, Spin, Steps, Table, Upload, message } from "antd";
import Lottie from 'lottie-react';
import React from "react";
import { v4 as uuidv4 } from 'uuid';
import animationData from '../../animate-lottie/animation_lnfk8gz0.json';
import ShippingAnimation from '../../animate-lottie/lottie-shipping.json';
import SuccessAnimation from '../../animate-lottie/lottie-success.json';
import RemovingDataAnimation from '../../animate-lottie/lottie_removing_data.json';
import { SvgBrushOutlined } from '../../components/custom-svg';
import { FormLabel, FormLabelOptional } from '../../components/general-component';
import { Colors } from '../../components/theme';
import '../../css/order-create.css';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiHandler, ApiPostWithFormDataOrderCreate, downloadExcel } from "../../network/network-manager";
import withRouter from '../../network/with-router';
import { clearOrder, updateAdvanceOrderList, updateSenderInformation } from '../../redux/order-form-slice';
import CustomPath from '../../routes/custom-path';
import SelectShipper from './select-shipper';
import ODSenderForm from './sender-form';
import { NJVSearchFilterOption } from '../../components/core-component';

const { TextArea } = Input;
const { Dragger } = Upload;
const stepLabelStyle = {
    fontWeight: '500',
    fontSize: 14
}

const labelStyle = { fontSize: 13, fontWeight: '500', color: 'gray' }

const initState = 1
const confirmState = 2
const successState = 3
const failState = 4

const HOME_DELIVERY = 1
const BUS_GATE = 2
const POST_OFFICE = 3
const NINJA_POINT = 4

const COLLECT_FROM_CUSTOMER = 'Collect from customer'
const PAY_BY_SHIPPER = 'Pay by shipper'
const DELIVERY_FEES = 2000

const steps = [
    {
        title: <span style={stepLabelStyle}>Sender Information</span>
    },
    {
        title: <span style={stepLabelStyle}>Receiver Information</span>
    },
    {
        title: <span style={stepLabelStyle}>Create Order</span>
    },
];

const orderTypeList = [
    {
        id: 1,
        value: 'Home Delivery'
    }, {
        id: 2,
        value: 'Bus Gate'
    },
    {
        id: 3,
        value: 'Post Office'
    },
    // {
    //     id: 4,
    //     value: 'Ninja Point',
    // }
]

const orderTypeMap = {
    '1': 'Home Delivery',
    '2': 'Bus Gate',
    '3': 'Post Office',
    '4': 'Ninja Point',
}


class AdvanceOrderForm extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedShipper: null,
            shipperAddress: null,
            page: 1,
            currentStep: 0,
            defaultLocation: {},
            shipper_colllection: [],
            userAddress_collection: [],
            pickupInformation: {},
            divisionList: [],
            townshipList: [],
            busGateList: [],
            allDivisionList: [],
            activeDivisionList: [],
            activeTownshipMap: {},
            allTownshipMap: {},
            busGateMap: {},
            senderTownshipList: [],
            deliveryChargesTemp: [],
            userInput: { favourite: false, orderType: 1, deliveryFees: 0 },
            isFavouriteLocation: false,
            isUpdateData: false,
            userInput_collection: [],
            openConfirmModal: false,
            orderState: initState,
            openClearConfirmModal: false,
            confirmRemoveAllData: false,
            isFetchingPartnershipStore: false,
            partnershipStoreList: [],
            mobile_columns: [
                {
                    title: 'Receiver',
                    dataIndex: 'name',
                    key: 'name'
                },
                {
                    title: 'Phone Number',
                    dataIndex: 'phone',
                    key: 'phone',
                },
                {
                    title: 'Action',
                    key: 'action',
                    render: (_, order) => (
                        <div>
                            <Button size={'middle'} type="primary" shape="circle" style={{ marginBottom: 5 }} onClick={() => this.editOrder(order.id)}><EditOutlined /></Button><br />
                            <Button size={'middle'} type="primary" shape="circle" onClick={() => this.deleteOrder(order.id)} danger><DeleteOutlined /></Button>
                        </div>
                    ),
                },
            ],
            division_collection_home_delivery: [],
            township_map_home_delivery: {},
            division_collection_bus_gate_service: [],
            township_map_bus_gate_service: {},
            receiverAddress_collection_home_delivery: [],
            receiverAddress_collection_bus_gate_service: [],
            excel_order_list: [],

            isSameDay: false,
            isFetchingSameDay: false,
            isFetchingPriceScript: false,
            isFetchingBusGate: false,
            isBusgateServiceAvailable: false,
            isOrderTypeUpload: true,
            downloadingExcel: false,
            isExcelFileUploading: false,

            total_excel_role: 0,
            valid_excel_role: 0,
            invalid_excel_role: 0,
            fail_message_code: '076',
        }
    }
    items = steps.map((item) => ({ key: item.title, title: item.title }));

    componentDidMount() {
        this.fetchDivision()
    }

    fetchBusGate = async (senderTownshipId, receiverTownshipId) => {
        try {
            this.setState({
                isFetchingBusGate: true,
                busGateList: []
            })
            const requestParams = {
                'receiverTownshipId': receiverTownshipId,
                'senderLocationId': senderTownshipId
            }
            const response = await ApiHandler({ url: Api.bus_gate_by_receiver_and_sender, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            if (response) {
                this.setState({
                    busGateList: response
                })
            }
        } catch (error) {
        }
        this.setState({
            isFetchingBusGate: false
        })
    }

    fetchDivision = async () => {
        try {
            const response = await ApiHandler({ url: Api.division_get_all, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON })
            if (response) {
                this.setState({
                    division_collection: response,
                    isFinishFetchingData: true
                })
                let activeDivisionList = []
                let allDivisionList = []
                let activeTownshipMap = {}
                let allTownshipMap = {}

                let divisionListHomeDelivery = []
                let townshipMapHomeDelivery = {}
                let divisionListBusGateSevice = []
                let townshipMapBusGateService = {}

                response.map(division => {
                    if (division.active) {
                        activeDivisionList.push(division)
                        divisionListBusGateSevice.push(division)
                        let activeTownshipList = []
                        let townshipListBusGateService = []
                        division.townshipDTOList.map(township => {
                            if (township.active) {
                                activeTownshipList.push(township)
                            }
                            if (township.active && township.enableBusGatePostOfficeService) {
                                townshipListBusGateService.push(township)
                            }
                        })
                        activeTownshipMap[division.id] = activeTownshipList
                        townshipMapBusGateService[division.id] = townshipListBusGateService
                    }

                    if (division.active && division.enableHomeDelivery) {
                        divisionListHomeDelivery.push(division)
                        let townshipListHomeDelivery = []
                        division.townshipDTOList.map(township => {
                            if (township.active && township.enableHomeDelivery) {
                                townshipListHomeDelivery.push(township)
                            }
                        })
                        townshipMapHomeDelivery[division.id] = townshipListHomeDelivery
                    }

                    allDivisionList.push(division)
                    allTownshipMap[division.id] = division.townshipDTOList
                })

                this.setState({
                    allDivisionList: allDivisionList,
                    allTownshipMap: allTownshipMap,
                    activeDivisionList: activeDivisionList,
                    activeTownshipMap: activeTownshipMap,
                    divisionList: divisionListHomeDelivery,
                    isFinishFetchingData: false,

                    division_collection_home_delivery: divisionListHomeDelivery,
                    township_map_home_delivery: townshipMapHomeDelivery,

                    division_collection_bus_gate_service: divisionListBusGateSevice,
                    township_map_bus_gate_service: townshipMapBusGateService

                })
            }
        } catch (error) {
        }
    }

    getPriceScript = async (senderTownshipId, receiverTownshipId, userInput, sameDay) => {
        const { selectedShipper } = this.state
        try {
            const requestParams = {
                'senderTownshipId': senderTownshipId,
                'receiverTownshipId': receiverTownshipId,
                'specificUserId': selectedShipper.id,
                'isSameDay': sameDay ? sameDay : false
            }

            const response = await ApiHandler({ url: Api.get_price, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            if (response || response === 0) {
                this.setState({
                    userInput: { ...userInput, deliveryFees: response },
                    isFetchingPriceScript: false
                })
            }
        } catch (error) {
            this.setState({
                isFetchingPriceScript: false
            })
        }

    }

    fetchPartnershipStore = async (receiverTownshipId) => {
        this.setState({
            isFetchingPartnershipStore: true,
            partnershipStoreList: []
        })
        const requestParams = {
            'townshipId': receiverTownshipId
        }
        await ApiHandler({ url: Api.order_partnership_stores, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            .then((response) => {
                this.setState({
                    partnershipStoreList: response ? response : [],
                    isFetchingPartnershipStore: false
                })

            })
            .catch((error) => {
                this.setState({
                    isFetchingPartnershipStore: false
                })
            })
    }

    checkSameDay = async (senderTownshipId, receiverTownshipId) => {
        try {
            const requestParams = {
                'fromTownshipId': senderTownshipId,
                'toTownshipId': receiverTownshipId
            }
            const response = await ApiHandler({ url: Api.order_check_same_day, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            if (response) {
                this.setState({
                    isSameDayAvailable: response
                })
            }
        } catch (error) {
        }
        this.setState({
            isFetchingSameDay: false
        })
    }

    handleShipperSelect = (shipper) => {
        this.setState({
            selectedShipper: shipper,
            currentStep: shipper ? 1 : 0,
            shipperAddress: shipper
        })
    }

    handleShipperRemove = () => {
        this.setState({
            openClearConfirmModal: true
        })
    }

    clearAllCacheDataConfirm = () => {
        this.props.dispatch(clearOrder(null))
        this.setState({
            selectedShipper: null,
            currentStep: 0,
            openClearConfirmModal: false,
            confirmRemoveAllData: false,
            userInput_collection: []

        })
    }


    handleSenderUserSelect = (value) => {
        const { townshipMap } = this.props
        let townshipList = []
        if (townshipMap[value] !== undefined) {
            townshipList = townshipMap[value]
        }
        this.setState({
            senderTownshipList: townshipList
        })
    }

    confirmSenderForm = (value) => {
        const { userInput } = this.state
        const senderInfomation = { ...value, formPickupDate: null }
        this.props.dispatch(updateSenderInformation(senderInfomation))
        this.setState({
            pickupInformation: value,
            currentStep: 2,
            userInput: { ...userInput, orderType: 1 },
            isBusgateServiceAvailable: value.isBusgateServiceAvailable
        })
    }

    handleUserInput = (event) => {
        let { userInput, activeDivisionList, allDivisionList, division_collection_bus_gate_service, division_collection_home_delivery, pickupInformation, isSameDayAvailable } = this.state
        if (event.target.name === 'phone') {
            const numericValue = event.target.value.replace(/[^0-9]/g, '')
            let phoneNumber = numericValue.substring(0, 12);
            userInput = { ...userInput, [event.target.name]: phoneNumber }
        } else if (event.target.name === 'parcelAmount') {
            const numericValue = event.target.value.replace(/[^0-9]/g, '')
            const parcelAmount = numericValue.substring(0, 7);
            if (numericValue.length === 1 && numericValue == 0) {
                userInput = { ...userInput, [event.target.name]: '' }
            } else {
                userInput = { ...userInput, [event.target.name]: parcelAmount }
            }
        } else if (event.target.name === 'name' || event.target.name === 'address' || event.target.name === 'notes') {
            if (event.target.name === 'name') {
                let inputValue = event.target.value;
                if (inputValue.length > 100) {
                    inputValue = inputValue.substring(0, 100);
                }
                userInput = { ...userInput, [event.target.name]: inputValue };
            } else {
                userInput = { ...userInput, [event.target.name]: event.target.value };
            }
        } else if (event.target.name === 'orderType') {
            userInput = { ...userInput, [event.target.name]: event.target.value }
            if (event.target.value === 1 || event.target.value === 4) {
                userInput = { ...userInput, [event.target.name]: event.target.value, deliveryChargesStatus: null, deliveryChargesStatusName: null, deliveryFees: 0 }
            } else {
                userInput = { ...userInput, [event.target.name]: event.target.value, deliveryChargesStatus: '2', deliveryChargesStatusName: PAY_BY_SHIPPER, deliveryFees: DELIVERY_FEES }
            }

            userInput = { ...userInput, townshipId: null, townshipName: null, divisionId: null, divisionName: null, 'busGateId': null, 'busGateName': null, address: null, 'partnershipStoreId': null, 'partnershipStoreName': null, 'partnershipStoreAddress': null }
            // if (event.target.value === 3) {
            //     userInput = { ...userInput, busGateFees: true }
            // }
            if (event.target.value === 1) {
                this.setState({
                    divisionList: division_collection_home_delivery,
                    busGateList: [],
                    townshipList: [],
                    partnershipStoreList: [],
                    isSameDayAvailable: false
                })
            } else {
                this.setState({
                    divisionList: division_collection_bus_gate_service,
                    busGateList: [],
                    townshipList: [],
                    partnershipStoreList: [],
                    isFetchingPriceScript: false
                })
            }
        } else if (event.target.name === 'favourite') {
            userInput = { ...userInput, [event.target.name]: event.target.checked }
            this.setState({
                isFavouriteLocation: event.target.checked
            })
        } else if (event.target.name === 'busGateFees') {
            userInput = { ...userInput, [event.target.name]: event.target.checked }
        } else if (event.target.name === 'deliveryChargesStatus') {
            userInput = { ...userInput, 'deliveryChargesStatus': event.target.value, 'deliveryChargesStatusName': event.target.value === 1 ? COLLECT_FROM_CUSTOMER : PAY_BY_SHIPPER }
            this.setState({
                userInput: userInput
            })
        } else if (event.target.name === 'serviceType') {
            userInput = { ...userInput, [event.target.name]: event.target.checked }
            if (event.target.checked) {
                this.getPriceScript(pickupInformation.township, userInput.townshipId, userInput, true)
            } else {
                this.getPriceScript(pickupInformation.township, userInput.townshipId, userInput)
            }
        }
        this.setState({
            userInput: userInput
        })

    }

    handleUserSelect = async (id, input) => {
        let { userInput, pickupInformation, activeTownshipMap, allTownshipMap, township_map_bus_gate_service, township_map_home_delivery } = this.state
        if (input.type === 'division') {
            userInput = { ...userInput, 'divisionId': input.value, 'divisionName': input.label, 'townshipId': null, 'townshipName': null, 'busGateId': null, 'busGateName': null }
            let townshipList = []
            if (userInput.orderType === 1 || userInput.orderType === 4) {
                userInput = { ...userInput, 'deliveryFees': 0 }
                townshipList = township_map_home_delivery[input.value]
            } else {
                userInput = { ...userInput, 'deliveryFees': DELIVERY_FEES }
                townshipList = township_map_bus_gate_service[input.value]
            }
            this.setState({
                userInput: userInput,
                townshipList: townshipList
            })
        } else if (input.type === 'township') {
            userInput = { ...userInput, 'townshipId': input.value, 'townshipName': input.label, 'busGateId': null, 'busGateName': null, 'partnershipStoreId': null, 'partnershipStoreName': null, 'partnershipStoreAddress': null }

            if (userInput.orderType === HOME_DELIVERY) {
                userInput['serviceType'] = false
                this.setState({
                    isFetchingPriceScript: true,
                    isFetchingSameDay: true,
                    isSameDayAvailable: false,
                });
                await Promise.all([
                    this.checkSameDay(pickupInformation.township, input.value),
                    this.getPriceScript(pickupInformation.township, input.value, userInput)
                ]);
                return
            } else if (userInput.orderType === BUS_GATE && pickupInformation?.township) {
                this.fetchBusGate(pickupInformation.township, input.value)
            } else if (userInput.orderType === NINJA_POINT) {
                this.setState({
                    isFetchingPriceScript: true,
                })
                this.fetchPartnershipStore(input.value)
                this.getPriceScript(pickupInformation.township, input.value, userInput)
            }
            this.setState({
                userInput
            })

        } else if (input.type === 'deliveryChargesStatus') {
            userInput = { ...userInput, 'deliveryChargesStatus': input.value, 'deliveryChargesStatusName': input.label }
            this.setState({
                userInput: userInput
            })
        } else if (input.type === 'busGate') {
            userInput = { ...userInput, 'busGateId': input.value, 'busGateName': input.label }
            this.setState({
                userInput: userInput
            })
        } else if (input.type === 'partnershipStoreId') {
            this.setState(prevState => ({
                userInput: { ...prevState.userInput, 'partnershipStoreId': input.value, 'partnershipStoreName': input.label, 'partnershipStoreAddress': input.address }
            }));
        }
    }

    clearInput = () => {
        this.setState({
            userInput: { favourite: this.state.isFavouriteLocation, orderType: 1, deliveryFees: 0 },
            isUpdateData: false
        })
    }

    clearAllUserOrder = () => {
        this.setState({
            userInput: { favourite: this.state.isFavouriteLocation, orderType: 1, deliveryFees: 0 },
            isUpdateData: false,
            userInput_collection: []
        })
    }

    handleAddOrder = () => {
        const { userInput, isFavouriteLocation, userInput_collection, receiverAddress_collection, isFetchingPriceScript } = this.state
        const uid = uuidv4()
        let isUpdate = false
        let currentUserInput = { ...userInput }
        if (currentUserInput.orderType === 4) {
            currentUserInput = { ...currentUserInput, address: currentUserInput.partnershipStoreAddress }
        }
        if (currentUserInput.id === null || currentUserInput.id === '' || currentUserInput.id === undefined) {
            currentUserInput = { ...currentUserInput, id: uid, key: uid }
        } else {
            isUpdate = true
        }
        let validInput = true
        if (isFavouriteLocation) {
            if (userInput.favLocationId !== '' && userInput.favLocationId !== null && userInput.favLocationId !== undefined) {
                let userFav = {}
                receiverAddress_collection.map((value, index) => {
                    if (value.id === userInput.favLocationId) {
                        userFav = { name: value.name, phone: value.phone, address: value.address1, townshipId: value.township.id, townshipName: value.township.name }
                    }
                })
                currentUserInput = { ...currentUserInput, ...userFav }
            } else {

            }
        } else {
            if (userInput.name === '' || userInput.name === null || userInput.name === undefined) {
                message.error("Enter receiver name")
                return
            }
            if (userInput.phone === '' || userInput.phone === null || userInput.phone === undefined) {
                message.error("Enter receiver phone number")
                return
            }
            if (userInput.townshipId === '' || userInput.townshipId === null || userInput.townshipId === undefined) {
                message.error("Select township")
                return
            }
            if (userInput.orderType === 1 && (userInput.address === '' || userInput.address === null || userInput.address === undefined)) {
                message.error("Select address")
                return
            }
        }
        if (userInput.orderType === 1 && isFetchingPriceScript) {
            validInput = false
        }
        if (userInput.orderType === 2) {
            if (userInput.busGateId === '' || userInput.busGateId === null || userInput.busGateId === undefined) {
                message.error("Select bus gate")
                return
            }
        }
        if (userInput.deliveryChargesStatus === null || userInput.deliveryChargesStatus === undefined) {
            message.error("Select payment option")
            return
        }

        if (userInput.orderType === 4) {
            if (!userInput.partnershipStoreId) {
                message.error("Select partner store")
                return
            }
        }

        currentUserInput = {
            ...currentUserInput,
            codAmount: userInput.codAmount ? +userInput.codAmount : 0,
            parcelAmount: userInput.parcelAmount ? +userInput.parcelAmount : 0,
            deliveryFees: userInput.deliveryFees ? +userInput.deliveryFees : 0,
            orderType: userInput.orderType ? userInput.orderType : 1,
            isSameDay: userInput.serviceType ? userInput.serviceType : false
        }

        if (validInput) {
            if (currentUserInput.phone.length > 5 && currentUserInput.phone.length < 12) {
                let new_userinput_collection = []
                if (currentUserInput.orderType === BUS_GATE || currentUserInput.orderType === POST_OFFICE) {
                    currentUserInput = { ...currentUserInput, total: 0 }
                } else if (currentUserInput.orderType === HOME_DELIVERY || currentUserInput.orderType === NINJA_POINT) {
                    if (currentUserInput.deliveryChargesStatus === 1) {
                        let total = currentUserInput.deliveryFees
                        if (currentUserInput.parcelAmount) {
                            total = total + parseInt(currentUserInput.parcelAmount)
                        }
                        currentUserInput = { ...currentUserInput, total: total }
                    } else if (currentUserInput.deliveryChargesStatus === 2) {
                        currentUserInput = { ...currentUserInput, total: currentUserInput.parcelAmount ? currentUserInput.parcelAmount : 0 }
                    }
                }
                if (isUpdate) {
                    userInput_collection.map(order => {
                        if (order.id === currentUserInput.id) {
                            new_userinput_collection = [...new_userinput_collection, currentUserInput]
                        } else {
                            new_userinput_collection = [...new_userinput_collection, order]
                        }
                    })
                } else {
                    if (userInput_collection)
                        new_userinput_collection = [...userInput_collection, currentUserInput]
                    else
                        new_userinput_collection = [currentUserInput]
                }
                this.setState({
                    userInput_collection: new_userinput_collection,
                    userInput: { favourite: this.state.isFavouriteLocation, orderType: 1, deliveryFees: 0 },
                    isUpdateData: false,
                    busGateList: [],
                    townshipList: []
                })
                message.success("New receiver added")
                this.props.dispatch(updateAdvanceOrderList(new_userinput_collection))
            } else {
                message.error("Invalid Phone Number")
            }
        } else {
            message.error("Invalid Input")
        }
    }

    deleteOrder = (uid) => {
        const { userInput_collection, page, isUpdateData } = this.state
        if (isUpdateData) {
            message.destroy()
            message.error("You can't delete the receiver while editing their information.")
            return
        }
        let new_user_collection = []
        let newPage = page
        userInput_collection.map(value => {
            if (value.id !== uid) {
                new_user_collection = [...new_user_collection, value]
            }
        })
        if (new_user_collection.length === 0) {
            newPage = 1
        } else if ((new_user_collection.length / 10) + 1 === page) {
            newPage = page - 1
        }
        this.setState({
            userInput_collection: new_user_collection,
            page: newPage
        })
        this.props.dispatch(updateAdvanceOrderList(new_user_collection))
    }

    editOrder = (uid) => {
        const { userInput_collection, pickupInformation, activeTownshipMap, allTownshipMap } = this.state
        let editOrder = {}
        userInput_collection.map(value => {
            if (value.id === uid) {
                editOrder = value
                return
            }
        })
        if (editOrder) {
            if (editOrder.townshipId) {
                this.fetchBusGate(pickupInformation.township, editOrder.townshipId)
                if (editOrder.orderType === 4) {
                    this.fetchPartnershipStore(editOrder.townshipId)
                }
                let townshiplist = []
                if (editOrder.orderType === 1 || editOrder.orderType === 4) {
                    townshiplist = activeTownshipMap[editOrder.divisionId]
                } else {
                    townshiplist = allTownshipMap[editOrder.divisionId]
                }
                this.setState({
                    townshipList: townshiplist
                })
            }


        }
        this.setState({
            userInput: editOrder,
            isUpdateData: true,
            isFavouriteLocation: editOrder.favourite
        })
    }

    confirm = () => {
        this.setState({
            openConfirmModal: true,
            currentStep: 2
        })
    }

    createOrderNow = async () => {
        const { userInput_collection, pickupInformation, selectedShipper, isOrderTypeUpload, excel_order_list, fail_message_code } = this.state

        this.setState({
            orderState: confirmState
        })
        if (isOrderTypeUpload) {
            try {
                let formData = new FormData()
                userInput_collection.map((receiver, index) => {
                    let addressDetail = ''

                    if (receiver.orderType === 1 || receiver.orderType === 4) {
                        addressDetail = receiver.address
                        let note = receiver.notes ? receiver.notes : ""
                        receiver = { ...receiver, notes: note }
                    } else if (receiver.orderType === 2) {
                        if (receiver.busGateFees) {
                            let note = receiver.notes ? "(" + receiver.notes + ")" : ""
                            receiver = { ...receiver, notes: receiver.busGateFees ? 'Pay Bus Gate Fees ' + note : receiver.notes }
                        } else {
                            let note = receiver.notes ? receiver.notes : ""
                            receiver = { ...receiver, notes: note }
                        }
                        addressDetail = receiver.busGateName + " (Bus Gate)"
                    } else if (receiver.orderType === 3) {
                        let note = receiver.notes ? "(" + receiver.notes + ")" : ""
                        receiver = { ...receiver, notes: 'Pay Post Office Fees' + note }
                        addressDetail = receiver.address + "(Post Office)"
                    }
                    formData.append(`receiverDTOS[${index}].isFavourite`, false)
                    formData.append(`receiverDTOS[${index}].receiverName`, receiver.name)
                    formData.append(`receiverDTOS[${index}].phoneNumber`, receiver.phone)
                    formData.append(`receiverDTOS[${index}].orderType`, receiver.orderType ? orderTypeMap[receiver.orderType] : orderTypeMap[1])
                    formData.append(`receiverDTOS[${index}].addressDetail`, addressDetail)
                    formData.append(`receiverDTOS[${index}].isSameDay`, receiver.serviceType ? receiver.serviceType : false)
                    formData.append(`receiverDTOS[${index}].parcelAmount`, receiver.parcelAmount ? receiver.parcelAmount : 0)
                    formData.append(`receiverDTOS[${index}].paymentOption`, receiver.orderType === 1 ? receiver.deliveryChargesStatusName : PAY_BY_SHIPPER)
                    formData.append(`receiverDTOS[${index}].deliveryCharges`, receiver.deliveryFees ? receiver.deliveryFees : 0)
                    formData.append(`receiverDTOS[${index}].note`, receiver.notes)
                    formData.append(`receiverDTOS[${index}].isFavourite`, receiver.false)
                    formData.append(`receiverDTOS[${index}].townShipId`, receiver.townshipId)
                    formData.append(`receiverDTOS[${index}].busGateId`, receiver.busGateId ? receiver.busGateId : 0)
                    formData.append(`receiverDTOS[${index}].codAmount`, receiver.total ? receiver.total : 0)
                    formData.append(`receiverDTOS[${index}].partnershipStoreId`, receiver.partnershipStoreId ? receiver.partnershipStoreId : 0)
                })
                formData.append('pickUpLocation', 0)
                formData.append('pickUpDate', pickupInformation.pickupDate)
                formData.append('senderName', pickupInformation.name)
                formData.append('senderPhoneNumber', pickupInformation.phone)
                formData.append('address', pickupInformation.address)
                formData.append('townShipId', pickupInformation.township)
                formData.append('shipperId', selectedShipper.id)
                formData.append('isAdmin', true)
                const response = await ApiPostWithFormDataOrderCreate(Api.order_create, formData)
                if (response && response.status === 200) {
                    this.setState({
                        orderState: successState,
                        currentStep: 0,
                        userInput_collection: [],
                        confirmRemoveAllData: true,
                        successfullOrderList: response.data
                    })
                    this.clearAllUserOrder()
                }
            } catch (error) {
                this.setState({
                    orderState: failState
                })
            }
        } else {
            try {

                let formData = new FormData()
                formData.append('pickUpLocation', 0)
                formData.append('pickUpDate', pickupInformation.pickupDate)
                formData.append('senderName', pickupInformation.name)
                formData.append('senderPhoneNumber', pickupInformation.phone)
                formData.append('address', pickupInformation.address)
                formData.append('townShipId', pickupInformation.township)
                formData.append('shipperId', selectedShipper.id)
                formData.append('isAdmin', true)

                excel_order_list.map((receiver, index) => {
                    formData.append(`receiverDTOS[${index}].isFavourite`, false)
                    formData.append(`receiverDTOS[${index}].receiverName`, receiver.receiverName)
                    formData.append(`receiverDTOS[${index}].phoneNumber`, receiver.phoneNumber)
                    formData.append(`receiverDTOS[${index}].orderType`, receiver.orderType)
                    formData.append(`receiverDTOS[${index}].addressDetail`, receiver.addressDetail)
                    formData.append(`receiverDTOS[${index}].isSameDay`, false)
                    formData.append(`receiverDTOS[${index}].parcelAmount`, receiver.parcelAmount)
                    formData.append(`receiverDTOS[${index}].paymentOption`, receiver.paymentOption)
                    formData.append(`receiverDTOS[${index}].deliveryCharges`, receiver.deliveryCharges)
                    formData.append(`receiverDTOS[${index}].note`, receiver.note)
                    formData.append(`receiverDTOS[${index}].townShipId`, receiver.townShipId)
                    formData.append(`receiverDTOS[${index}].busGateId`, receiver.busGateId || 0)
                    formData.append(`receiverDTOS[${index}].codAmount`, receiver.codAmount)
                });
                const response = await ApiPostWithFormDataOrderCreate(Api.order_create, formData)
                if (response && response.status === 200) {
                    this.setState({
                        orderState: successState,
                        currentStep: 0,
                        userInput_collection: [],
                        busGateList: [],
                        successfullOrderList: response.data,
                        excel_order_list: [],
                        valid_excel_role: 0,
                        invalid_excel_role: 0,
                        confirmRemoveAllData: true
                    })
                    this.clearAllUserOrder()
                }
            } catch (error) {
                let code = fail_message_code
                if (error && error.response && error.response.data && error.response.data.code) {
                    code = error.response.data.code
                }
                this.setState({
                    orderState: failState,
                    fail_message_code: code
                })

            }
        }

    }

    onChangePagination = (currentPage) => {
        this.setState({
            page: currentPage.current
        })
    }

    handleSegment = () => {
        this.setState({
            isOrderTypeUpload: !this.state.isOrderTypeUpload
        })
    }

    handleFileUpload = async (file) => {
        const { shipperAddress } = this.state
        try {
            this.setState({
                isExcelFileUploading: true
            })
            if (shipperAddress && shipperAddress.township && shipperAddress.township.id) {
                let formData = new FormData()
                formData.append('excel', file);
                formData.append('id', shipperAddress.township.id);
                formData.append('shipperId', shipperAddress.id)
                const response = await ApiHandler({ url: Api.upload_order_excel, method: HTTP_METHOD.POST, mediaType: MEDIA_TYPE.FORM_DATA, requestData: formData })
                if (response && response.data) {
                    let list = []
                    let valid = 0
                    let invalid = 0
                    response.data.map(order => {
                        order = {
                            ...order,
                            parcelAmount: order.parcelAmount ? parseInt(order.parcelAmount) : 0,
                            deliveryCharges: order.deliveryCharges ? parseInt(order.deliveryCharges) : 0,

                        }
                        if (order.paymentOption === COLLECT_FROM_CUSTOMER) {
                            order = { ...order, codAmount: order.parcelAmount + order.deliveryCharges }
                        } else if (order.paymentOption === PAY_BY_SHIPPER) {
                            order = { ...order, codAmount: order.parcelAmount }
                        }
                        list.push({ ...order, id: uuidv4() })
                        if (order.confirm) {
                            valid = valid + 1
                        } else {
                            invalid = invalid + 1
                        }
                    })
                    this.setState({
                        excel_order_list: list,
                        valid_excel_role: valid,
                        invalid_excel_role: invalid,
                        isExcelFileUploading: false
                    })
                }
            }
        } catch (error) {
            if (error && error.response.status === 500) {
                message.destroy()
                message.error("Something went wrong")
            }
        }
    }


    removeInvalid = (id) => {
        const { excel_order_list, invalid_excel_role } = this.state
        let new_excel_order_list = []
        let invalid_role = 0;
        excel_order_list && excel_order_list.map(order => {
            if (order.id !== id) {
                new_excel_order_list.push(order)
            } else if (order.id === id) {
                if (invalid_excel_role !== 0) {
                    invalid_role = invalid_excel_role - 1
                }
            }
        })
        this.setState({
            invalid_excel_role: invalid_role,
            excel_order_list: new_excel_order_list
        })
    }

    uploadNewExcel = () => {
        this.setState({
            invalid_excel_role: 0,
            valid_excel_role: 0,
            excel_order_list: []
        })
    }

    downloadExampleTemplate = async () => {
        this.setState({
            downloadingExcel: true
        })
        try {
            const response = await downloadExcel(Api.download_order_sample_excel)
            if (response && response.status === 200) {
                this.setState({
                    downloadingExcel: false
                })
            }
        } catch (error) {
            this.setState({
                downloadingExcel: false
            })
        }

    }

    airwaybill = () => {
        this.props.navigate(CustomPath.airwaybill, { state: { data: this.state.successfullOrderList, route: CustomPath.order_create } })
    }

    render() {
        const { openFailReasonModal, failReasons, isSameDayAvailable, isFetchingSameDay, openClearConfirmModal, confirmRemoveAllData, isFetchingPriceScript, currentStep, isUpdateData, openConfirmModal, orderState, activeTownshipMap,
            userInput_collection, userInput, activeDivisionList, townshipList, page, shipperAddress, busGateList, divisionList, isBusgateServiceAvailable, isFetchingBusGate, isOrderTypeUpload, excel_order_list, downloadingExcel, invalid_excel_role, valid_excel_role, isExcelFileUploading, isFetchingPartnershipStore, partnershipStoreList } = this.state

        const draggerProps = {
            name: 'file',
            multiple: false,
            showUploadList: false,
            beforeUpload: () => false,
            onChange: (info) => {
                const { file } = info;
                this.handleFileUpload(file);
            },
        };

        let columns = [
            {
                title: 'No.',
                key: 'index',
                render: (value, item, index) => <span>{(page - 1) * 10 + index + 1}</span>,
                width: 5
            },
            {
                title: 'Receiver',
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: 'Phone Number',
                dataIndex: 'phone',
                key: 'phone',
                width: 10
            },
            {
                title: 'Township',
                dataIndex: 'townshipName',
                key: 'townshipName'
            },
            {
                title: 'Parcel Value',
                dataIndex: 'parcelAmount',
                key: 'parcelAmount',
                width: 30
            },
            {
                title: 'Delivery Fees',
                dataIndex: 'deliveryFees',
                key: 'deliveryFees',
                width: 30
            },
            {
                title: 'COD',
                dataIndex: 'total',
                key: 'total',
                width: 30
            },
            {
                title: 'Action',
                key: 'action',
                render: (_, order) => (
                    <Space>
                        <Button size={'middle'} type="primary" shape="circle" onClick={() => this.editOrder(order.id)}><EditOutlined /></Button>
                        <Button size={'middle'} type="primary" shape="circle" onClick={() => this.deleteOrder(order.id)} danger><DeleteOutlined /></Button>

                    </Space>
                ),
                width: 10
            },
        ]

        let excel_order_columns = [
            {
                title: "No",
                key: 'index',
                render: (value, item, index) => <span>{(page - 1) * 10 + index + 1}</span>,
                width: 5
            },
            {
                title: "Receiver Name",
                dataIndex: 'receiverName',
                key: 'receiverName'
            },
            {
                title: "Receiver Phone Number",
                dataIndex: 'phoneNumber',
                key: 'phoneNumber',
                width: 10
            },
            {
                title: "Township",
                dataIndex: 'townShip',
                key: 'townShip',

            },
            {
                title: "Parcel Value",
                dataIndex: 'parcelAmount',
                key: 'parcelAmount',
                width: 30
            },
            {
                title: "Delivery Fees",
                dataIndex: 'deliveryCharges',
                key: 'deliveryCharges',
                width: 30
            },
            {
                title: "Cod",
                dataIndex: 'codAmount',
                key: 'codAmount',
                width: 30
            }, {
                title: "Status",
                key: 'status',
                width: 10,
                render: (_, order) => (
                    <Space>
                        {
                            order.confirm ?
                                <span style={{ color: Colors.darkGreen }}>Valid</span>
                                :
                                <>
                                    <span style={{ color: Colors.red }}>Invalid</span>
                                    <Button
                                        size='small'
                                        type='primary' shape='circle' onClick={() => this.setState({ openFailReasonModal: true, failReasons: order.failReasons })}>
                                        <QuestionOutlined color='#000000' />
                                    </Button>
                                </>
                        }

                    </Space>
                ),
            }, {
                title: 'Action',
                key: 'action',
                render: (_, order) => (
                    <Space>
                        {
                            order.confirm ?
                                <></>
                                :
                                <Button size={'middle'} type="primary" shape="circle" onClick={() => this.removeInvalid(order.id)} danger><DeleteOutlined /></Button>
                        }

                    </Space>
                ),
                width: 10
            },
        ]

        return (
            <>
                <Modal
                    title={null}
                    centered
                    open={openClearConfirmModal}
                    onOk={() => this.setState({ confirmRemoveAllData: true })}
                    footer={null}
                    closeIcon={null}
                    className='custom-modal'
                >
                    <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ width: '100%', textAlign: 'center' }}>

                            <Lottie
                                style={{ height: '200px' }}
                                animationData={RemovingDataAnimation}
                                loop={true}
                                autoplay={true}
                            />
                            <div style={{ marginTop: 30, fontSize: 15, fontWeight: '500' }}>This action will clear your added order</div>
                            <Button type='text' style={{ marginTop: 10, marginRight: 10 }} onClick={() => this.setState({ openClearConfirmModal: false })}>Cancel</Button>
                            <Button type='primary' style={{ marginTop: 10 }} onClick={() => this.setState({ confirmRemoveAllData: true })}>Confirm</Button>
                        </div>
                    </div>
                </Modal>
                <Modal
                    title="Fail Reason"
                    centered
                    open={openFailReasonModal}
                    onCancel={() => this.setState({ openFailReasonModal: false })}
                    footer={null}
                    closeIcon={null}
                    className='custom-modal'
                >
                    {
                        failReasons && failReasons.map((item) => <div>{item}</div>)
                    }
                    <Button type='primary' style={{ marginTop: 10 }} onClick={() => this.setState({ openFailReasonModal: false })}>Ok</Button>
                </Modal >
                <Modal
                    title={null}
                    centered
                    open={openConfirmModal}
                    onOk={() => this.createOrderNow()}
                    // onCancel={() => this.setState({ openConfirmModal: false })}
                    footer={null}
                    closeIcon={null}
                    className='custom-modal'
                >
                    <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ width: '100%', textAlign: 'center' }}>
                            {
                                orderState === initState ?
                                    <>
                                        <Lottie
                                            style={{ height: '200px' }}
                                            animationData={ShippingAnimation}
                                            loop={true}
                                            autoplay={true}
                                        />
                                        <div style={{ marginTop: 30, fontSize: 15, fontWeight: '500' }}>Please confirm orders</div>
                                        <Button type='text' style={{ marginTop: 10, marginRight: 10 }} onClick={() => this.setState({ openConfirmModal: false, currentStep: 1 })}>Cancel</Button>
                                        <Button type='primary' style={{ marginTop: 10 }} onClick={() => this.createOrderNow()}>Confirm</Button>

                                    </> :
                                    <></>
                            }
                            {
                                orderState === confirmState ?
                                    <>
                                        <Lottie
                                            style={{ height: '200px' }}
                                            animationData={animationData}
                                            loop={true}
                                            autoplay={true}
                                        />

                                        <div style={{ fontSize: 15, fontWeight: '500' }}>We're putting the finishing touches on it.</div>
                                    </> :
                                    <></>
                            }
                            {
                                orderState === successState ?
                                    <>
                                        <Lottie
                                            style={{ height: '200px' }}
                                            animationData={SuccessAnimation}
                                            loop={false}
                                            autoplay={true}
                                        />
                                        <div style={{ marginTop: 30, fontSize: 15, fontWeight: '500' }}>Parcel created successfully</div>
                                        <Button type='text' icon={<PrinterOutlined />} onClick={() => this.airwaybill()} style={{ marginTop: 10, marginBottom: 10 }}>Print Air Way Bill</Button>
                                        <br />
                                        <Button type='primary' onClick={() => this.setState({ openConfirmModal: false, orderState: initState })}>Close</Button>
                                    </>
                                    :
                                    <></>
                            }
                            {
                                orderState === failState ?
                                    <>
                                        <Lottie
                                            style={{ height: '200px' }}
                                            animationData={SuccessAnimation}
                                            loop={false}
                                            autoplay={true}
                                        />
                                        <div style={{ marginTop: 30, fontSize: 15, fontWeight: '500' }}>Something Wrong!</div>
                                        <Button type='primary' onClick={() => this.setState({ openConfirmModal: false, orderState: initState })}>Close</Button>
                                    </>
                                    :
                                    <></>
                            }

                        </div>

                    </div>

                </Modal>
                <Row>
                    <Col span={24}>
                        <Row>
                            <Col span={24} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
                                <Breadcrumb
                                    items={[
                                        {
                                            title: 'Home',
                                        },
                                        {
                                            title: 'Parcel Create'
                                        }
                                    ]}
                                />
                                <Button
                                    onClick={() => this.clearAllUserOrder()}
                                    icon={<SvgBrushOutlined width={14} height={14} color={"#fff"} />}
                                    type='primary'
                                    style={{ background: 'red' }}
                                >Clear Receiver Information
                                </Button>
                            </Col>
                        </Row>
                        <Steps current={currentStep} labelPlacement="vertical" items={this.items} />
                        <Card
                            style={{ backgroundColor: "#f5f5f5", boxShadow: 'none' }}
                            bordered={false}
                        >
                            <Row>
                                <Col span={24}>
                                    <h4 style={{ marginTop: 5, marginBottom: 5 }}>Search and Select shipper</h4>
                                </Col>
                                <Col span={24}>
                                    <SelectShipper
                                        removeStatus={confirmRemoveAllData}
                                        confirmRemove={this.clearAllCacheDataConfirm}
                                        onRemove={this.handleShipperRemove}
                                        onSelect={this.handleShipperSelect}
                                    />
                                </Col>
                            </Row>
                        </Card>
                        <br />

                        {
                            currentStep === 1 || currentStep === 2 || currentStep === 3 ?
                                <>
                                    {
                                        activeTownshipMap && Object.keys(activeTownshipMap).length !== 0 ?
                                            <ODSenderForm
                                                divisionList={activeDivisionList}
                                                shipperAddress={shipperAddress}
                                                townshipMap={activeTownshipMap}
                                                handleDivisionSelect={this.handleSenderUserSelect}
                                                confirmSenderForm={this.confirmSenderForm}
                                            />
                                            :
                                            <></>
                                    }
                                </>
                                :
                                <></>
                        }
                        {
                            currentStep === 2 || currentStep === 3 ?
                                <Row style={{ marginBottom: 30 }}>
                                    <Col span={24}>
                                        <Card
                                            style={{
                                                backgroundColor: "#f5f5f5",
                                                boxShadow: 'none',
                                                paddingTop: 25,
                                                paddingBottom: 10,
                                            }}
                                            bordered={false}
                                        >

                                            <Row>
                                                <Col span={24} style={{ marginBottom: 20 }}>
                                                    <Segmented
                                                        size='large'
                                                        style={{ backgroundColor: '#c2002f' }}
                                                        block
                                                        options={[
                                                            {
                                                                label: <span style={{ fontWeight: '600', fontSize: 14, }}>Manual Order Entry</span>,
                                                                value: 'Manual Order Entry'
                                                            },
                                                            {
                                                                label: <span style={{ fontWeight: '600', fontSize: 14, }}>Bulk Order Upload</span>,
                                                                value: 'Bulk Order Upload'
                                                            }
                                                        ]}
                                                        onChange={this.handleSegment}
                                                    />
                                                </Col>
                                            </Row>


                                            {
                                                isOrderTypeUpload ?
                                                    <>
                                                        <Row>
                                                            <Col span={24}>
                                                                <Table
                                                                    className='table-white'
                                                                    columns={columns}
                                                                    dataSource={userInput_collection}
                                                                    expandable={{
                                                                        expandedRowRender: (record) => (
                                                                            <>
                                                                                <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                                    <Col span={12} style={{ marginBottom: 5 }}>
                                                                                        <div style={labelStyle}>Charges Type</div>
                                                                                        <div>{record.deliveryChargesStatusName}</div>
                                                                                    </Col>

                                                                                    {
                                                                                        record.address ?
                                                                                            <Col span={12}>
                                                                                                <div style={labelStyle}>Address</div>
                                                                                                <div>{record.address}</div>

                                                                                            </Col>
                                                                                            :
                                                                                            <>
                                                                                                {
                                                                                                    record.busGateName ?
                                                                                                        <Col span={12}>
                                                                                                            <div style={labelStyle}>Bus Gate Name</div>
                                                                                                            <div>{record.busGateName}</div>
                                                                                                        </Col> :
                                                                                                        <></>
                                                                                                }

                                                                                            </>
                                                                                    }

                                                                                    <Col span={12} style={{ marginBottom: 5 }}>
                                                                                        <div style={labelStyle}>Order Type</div>
                                                                                        <div>{orderTypeMap[record.orderType]}</div>
                                                                                    </Col>
                                                                                    {
                                                                                        record.isSameDay ?
                                                                                            <Col span={12} style={{ marginBottom: 5 }}>
                                                                                                <div style={labelStyle}>Same Day</div>
                                                                                                <div>TRUE</div>
                                                                                            </Col>
                                                                                            :
                                                                                            <></>
                                                                                    }
                                                                                    {
                                                                                        record.notes ?
                                                                                            <Col span={12} style={{ marginBottom: 5 }}>
                                                                                                <div style={labelStyle}>Note</div>
                                                                                                <div>
                                                                                                    {record.notes}
                                                                                                </div>
                                                                                            </Col>
                                                                                            :
                                                                                            <></>

                                                                                    }

                                                                                </Row>



                                                                            </>
                                                                        ),
                                                                        rowExpandable: (record) => record.notes !== 'Not Expandable',
                                                                    }}
                                                                    onChange={this.onChangePagination}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col span={24}>
                                                                <h4>Receiver Information</h4>
                                                            </Col>
                                                            <Col span={24} style={{ marginBottom: 25 }}>
                                                                <Row gutter={[16, 16]}>
                                                                    <Col lg={12} md={12} sm={24} xs={24}>
                                                                        <FormLabelOptional label="Order Type" />
                                                                        <Radio.Group name='orderType' onChange={this.handleUserInput} value={userInput && userInput.orderType ? userInput.orderType : 1}>
                                                                            {
                                                                                orderTypeList.map((order) => {
                                                                                    return <Radio disabled={order.id === 1 ? false : (order.id === 2 && isBusgateServiceAvailable) ? false : order.id === 3 ? false : order.id === 4 ? false : true} value={order.id}>{order.value}</Radio>
                                                                                })
                                                                            }
                                                                        </Radio.Group>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col span={24} style={{ marginBottom: 10 }}>
                                                                <Row gutter={[16, 16]}>
                                                                    <Col lg={userInput.orderType === 1 ? 6 : 6} md={6} sm={12} xs={12}>
                                                                        <FormLabel label="Customer Name" />
                                                                        <Input name="name" onChange={this.handleUserInput} size="large" value={userInput.name} />
                                                                    </Col>
                                                                    <Col lg={userInput.orderType === 1 ? 6 : 6} md={6} sm={12} xs={12}>
                                                                        <FormLabel label="Phone Number" />
                                                                        <Input name="phone" onChange={this.handleUserInput} size="large" placeholder="09" value={userInput.phone} />
                                                                    </Col>
                                                                    <Col lg={userInput.orderType === 1 ? 6 : 6} md={6} sm={12} xs={12}>
                                                                        <FormLabel label={"State & Division"} />
                                                                        <Select
                                                                            style={{
                                                                                width: '100%',
                                                                            }}
                                                                            value={(userInput && userInput.divisionId) ?? userInput.divisionId}
                                                                            placeholder={"Select Division"}
                                                                            size="large"
                                                                            onChange={this.handleUserSelect}
                                                                            options={
                                                                                divisionList && divisionList.map((division) => {
                                                                                    return {
                                                                                        value: division.id,
                                                                                        label: division.name,
                                                                                        type: 'division'
                                                                                    }
                                                                                })
                                                                            }
                                                                        />
                                                                    </Col>
                                                                    <Col lg={userInput.orderType === 1 ? 6 : 6} md={6} sm={12} xs={12}>
                                                                        <FormLabel label={"Township"} />
                                                                        <Select
                                                                            style={{
                                                                                width: '100%',
                                                                            }}
                                                                            showSearch
                                                                            filterOption={NJVSearchFilterOption}
                                                                            value={(userInput && userInput.townshipId) ?? userInput.townshipId}
                                                                            placeholder={"Select Township"}
                                                                            size="large"
                                                                            onChange={this.handleUserSelect}
                                                                            options={
                                                                                townshipList && townshipList.map((township) => {
                                                                                    return {
                                                                                        value: township.id,
                                                                                        label: township.name,
                                                                                        type: 'township'
                                                                                    }
                                                                                })
                                                                            }
                                                                        />
                                                                    </Col>
                                                                    {
                                                                        userInput.orderType === 1 || userInput.orderType === 3 ?
                                                                            <Col lg={12} md={24} sm={24} xs={24}>
                                                                                <FormLabel label="Address" />
                                                                                <TextArea showCount maxLength={180}
                                                                                    name="address" onChange={this.handleUserInput}
                                                                                    style={{
                                                                                        width: '100%',
                                                                                        background: '#fff'
                                                                                    }}
                                                                                    bordered={false}
                                                                                    placeholder="Enter full address"
                                                                                    value={userInput && userInput.address ? userInput.address : ''}
                                                                                />
                                                                            </Col> : <></>
                                                                    }
                                                                    {
                                                                        userInput.orderType === 4 ?
                                                                            <Col lg={12} md={12} xs={24} sm={24}>
                                                                                <FormLabel label="Partner Store" />
                                                                                <Select
                                                                                    style={{
                                                                                        width: '100%',
                                                                                    }}
                                                                                    name="partnershipStoreId"
                                                                                    loading={isFetchingPartnershipStore}
                                                                                    className='my-location-selector'
                                                                                    placeholder="Partner Store"
                                                                                    value={(userInput && userInput.partnershipStoreId) ?? userInput.partnershipStoreId}
                                                                                    size="large"
                                                                                    onChange={this.handleUserSelect}
                                                                                    options={
                                                                                        partnershipStoreList && partnershipStoreList.map((partnershipStore) => {
                                                                                            return {
                                                                                                value: partnershipStore.id,
                                                                                                label: partnershipStore.fullName,
                                                                                                address: partnershipStore.address,
                                                                                                type: 'partnershipStoreId'
                                                                                            }
                                                                                        })
                                                                                    }
                                                                                />
                                                                            </Col>
                                                                            :
                                                                            <></>
                                                                    }
                                                                </Row>
                                                                <Row gutter={16} style={{ marginTop: 10 }}>
                                                                    {
                                                                        userInput.orderType === 2 ?
                                                                            <Col lg={12} md={12} xs={24} sm={24}>
                                                                                <FormLabel label="Bus Gate Name" />
                                                                                <Select
                                                                                    style={{
                                                                                        width: '100%',
                                                                                    }}
                                                                                    loading={isFetchingBusGate}
                                                                                    value={(userInput && userInput.busGateId) ?? userInput.busGateId}
                                                                                    placeholder={"Select bus gate"}
                                                                                    size="large"
                                                                                    onChange={this.handleUserSelect}
                                                                                    options={
                                                                                        busGateList && busGateList.map((busGate) => {
                                                                                            return {
                                                                                                value: busGate.id,
                                                                                                label: busGate.busGateName,
                                                                                                type: 'busGate'
                                                                                            }
                                                                                        })
                                                                                    }
                                                                                />
                                                                            </Col>
                                                                            :
                                                                            <></>
                                                                    }

                                                                </Row>
                                                            </Col>
                                                            <Col span={24}>
                                                                <Row gutter={[16, 16]} style={{ marginBottom: 10 }}>
                                                                    <Col lg={24} md={24} sm={24} xs={24}>
                                                                        {
                                                                            userInput.orderType === 1 ?
                                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                    <Checkbox
                                                                                        name={'serviceType'}
                                                                                        onChange={this.handleUserInput}
                                                                                        checked={userInput.serviceType}
                                                                                        disabled={!isSameDayAvailable}
                                                                                    >
                                                                                        Same Day
                                                                                    </Checkbox>
                                                                                    {
                                                                                        isFetchingSameDay ?
                                                                                            <Spin
                                                                                                indicator={
                                                                                                    <LoadingOutlined
                                                                                                        style={{
                                                                                                            fontSize: 24,
                                                                                                        }}
                                                                                                        spin
                                                                                                    />
                                                                                                }
                                                                                            />
                                                                                            :
                                                                                            <></>
                                                                                    }
                                                                                </div>
                                                                                : <></>
                                                                        }
                                                                    </Col>
                                                                    <Col span={24}>
                                                                        <Row gutter={[16, 16]}>
                                                                            <Col lg={8} md={8} xs={24} sm={24} >
                                                                                <FormLabelOptional label="Payment option" />
                                                                                <Radio.Group name='deliveryChargesStatus' onChange={this.handleUserInput} value={userInput.orderType == 1 || userInput.orderType == 4 ? (userInput && userInput.deliveryChargesStatus) ?? userInput.deliveryChargesStatus : 2}>
                                                                                    <Radio
                                                                                        value={1}
                                                                                        disabled={userInput.orderType === 2 || userInput.orderType === 3 ? true : false}
                                                                                    >
                                                                                        {COLLECT_FROM_CUSTOMER}
                                                                                    </Radio>
                                                                                    <Radio
                                                                                        value={2}>{PAY_BY_SHIPPER}
                                                                                    </Radio>
                                                                                </Radio.Group>
                                                                            </Col>
                                                                            {
                                                                                userInput.orderType === 1 || userInput.orderType === 4 ?
                                                                                    <Col lg={8} md={8} xs={12} sm={12} >
                                                                                        <FormLabelOptional label="Parcel Amount" />
                                                                                        <Input type='text' name="parcelAmount" onChange={this.handleUserInput} size="large" value={userInput.parcelAmount} />
                                                                                    </Col>
                                                                                    :
                                                                                    <></>
                                                                            }
                                                                            <Col lg={8} md={8} xs={12} sm={12} >
                                                                                <FormLabelOptional label="Delivery Fees" />
                                                                                <div style={{ height: 40, display: 'flex', alignItems: 'center' }}>
                                                                                    {
                                                                                        userInput.orderType === 1 || userInput.orderType === 4 ?
                                                                                            <>
                                                                                                {
                                                                                                    isFetchingPriceScript ?
                                                                                                        <Spin
                                                                                                            indicator={
                                                                                                                <LoadingOutlined
                                                                                                                    style={{
                                                                                                                        fontSize: 24,
                                                                                                                    }}
                                                                                                                    spin
                                                                                                                />
                                                                                                            }
                                                                                                        />
                                                                                                        :
                                                                                                        <>{userInput.deliveryFees} MMK</>
                                                                                                }
                                                                                            </>
                                                                                            :
                                                                                            <>
                                                                                                {userInput.deliveryFees} MMK
                                                                                            </>
                                                                                    }
                                                                                </div>
                                                                            </Col>
                                                                            {/* {
                                                                                        userInput.orderType === 2 || userInput.orderType === 3 ?
                                                                                            <Col span={24} >
                                                                                                <Checkbox
                                                                                                    name={'busGateFees'}
                                                                                                    onChange={this.handleUserInput}
                                                                                                    checked={userInput.busGateFees}
                                                                                                    disabled={userInput.orderType === 3 ? true : false}
                                                                                                >
                                                                                                    {
                                                                                                        userInput.orderType === 2 ?
                                                                                                            <span>Pay Bus Gate Fees</span>
                                                                                                            :
                                                                                                            <span>Pay Post Office Fees</span>
                                                                                                    }

                                                                                                </Checkbox>
                                                                                            </Col>
                                                                                            :
                                                                                            <></>

                                                                                    } */}
                                                                            {
                                                                                userInput.orderType === 2 ?
                                                                                    <Col span={24} >
                                                                                        <Checkbox
                                                                                            name={'busGateFees'}
                                                                                            onChange={this.handleUserInput}
                                                                                            checked={userInput.busGateFees}
                                                                                        >
                                                                                            <span>Pay Bus Gate Fees</span>
                                                                                        </Checkbox>
                                                                                    </Col>
                                                                                    :
                                                                                    <></>

                                                                            }

                                                                        </Row>
                                                                    </Col>
                                                                </Row>
                                                            </Col>

                                                            <Col span={24}>
                                                                <FormLabelOptional label="Notes" />
                                                                <TextArea
                                                                    name="notes" onChange={this.handleUserInput}
                                                                    showCount
                                                                    maxLength={100}
                                                                    style={{
                                                                        width: '100%',
                                                                        height: 80,
                                                                        marginBottom: 24,
                                                                    }}
                                                                    placeholder="Add any special instructions or comments here..."
                                                                    value={userInput.notes}
                                                                />
                                                            </Col>
                                                            <Col span={24}>
                                                                <Button type="primary" onClick={() => this.clearInput()} danger style={{ marginRight: 10 }}>Clear</Button>
                                                                <Button type="primary" onClick={() => this.handleAddOrder()}>
                                                                    {
                                                                        isUpdateData ? <span>Update</span> : <span>Add Receiver</span>
                                                                    }
                                                                </Button>
                                                            </Col>

                                                        </Row>
                                                    </>
                                                    :
                                                    <Row>
                                                        {
                                                            excel_order_list && excel_order_list.length !== 0 ?
                                                                <Col lg={24} md={24} sm={0} xs={0}>
                                                                    <div style={{ marginBottom: 15, display: 'flex', justifyContent: 'space-between' }}>
                                                                        <div>
                                                                            <span className='valid-tag'>Valid : {valid_excel_role}</span>
                                                                            {
                                                                                invalid_excel_role && invalid_excel_role !== 0 ?
                                                                                    <span className='invalid-tag'>Invalid : {invalid_excel_role}</span>
                                                                                    :
                                                                                    <></>
                                                                            }
                                                                        </div>
                                                                        <Button type="primary" style={{ backgroundColor: 'red' }} onClick={() => this.uploadNewExcel()}>Upload New Excel</Button>

                                                                    </div>
                                                                    <Table
                                                                        rowKey={(record) => record.id}
                                                                        columns={excel_order_columns}
                                                                        dataSource={excel_order_list}
                                                                        expandable={{
                                                                            expandedRowRender: (record) => (
                                                                                <>
                                                                                    <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                                        <Col span={12} style={{ marginBottom: 5 }}>
                                                                                            <div style={labelStyle}>Payment Option</div>
                                                                                            <div>{record.paymentOption}</div>
                                                                                        </Col>

                                                                                        <Col span={12}>
                                                                                            <div style={labelStyle}>Address</div>
                                                                                            <div>{record.addressDetail}</div>
                                                                                        </Col>
                                                                                        <Col span={12} style={{ marginBottom: 5 }}>
                                                                                            <div style={labelStyle}>OrderType</div>
                                                                                            <div>{record.orderType}</div>
                                                                                        </Col>
                                                                                        {
                                                                                            record.isSameDay ?
                                                                                                <Col span={12} style={{ marginBottom: 5 }}>
                                                                                                    <div style={labelStyle}>Same Day</div>
                                                                                                    <div>TRUE</div>
                                                                                                </Col>
                                                                                                :
                                                                                                <></>
                                                                                        }
                                                                                        {
                                                                                            record.note ?
                                                                                                <Col span={12} style={{ marginBottom: 5 }}>
                                                                                                    <div style={labelStyle}>Note</div>
                                                                                                    <div>{record.note}</div>
                                                                                                </Col>
                                                                                                :
                                                                                                <></>
                                                                                        }

                                                                                    </Row>



                                                                                </>
                                                                            ),
                                                                            rowExpandable: (record) => record.notes !== 'Not Expandable',
                                                                        }}
                                                                        onChange={this.onChangePagination}
                                                                    />
                                                                    <div>
                                                                        <Button disabled={invalid_excel_role === 0 && valid_excel_role > 0 ? false : true} type="primary" size='large' onClick={() => this.confirm()}>Create Order</Button>
                                                                    </div>
                                                                </Col>
                                                                :
                                                                <Col span={24}>
                                                                    <Col span={24} style={{ textAlign: 'center' }}>
                                                                        <Button type="primary" shape="round" icon={<DownloadOutlined />} size='large' loading={downloadingExcel} onClick={() => this.downloadExampleTemplate()}>
                                                                            Download Excel Template Sample
                                                                        </Button>
                                                                        <Dragger
                                                                            {...draggerProps}
                                                                            accept={[".xlsx", ".xls"]}
                                                                            maxCount={1}
                                                                            style={{ marginTop: 15 }}>
                                                                            <p className="ant-upload-drag-icon">
                                                                                <InboxOutlined />
                                                                            </p>
                                                                            <p className="ant-upload-hint">
                                                                                {
                                                                                    isExcelFileUploading ?
                                                                                        <div>
                                                                                            <Spin /> <span>uploading...</span>
                                                                                        </div>
                                                                                        :
                                                                                        <span>Upload excel file only</span>
                                                                                }

                                                                            </p>
                                                                        </Dragger>

                                                                    </Col>
                                                                </Col>

                                                        }

                                                    </Row>
                                            }

                                        </Card>
                                        {
                                            userInput_collection && userInput_collection.length !== 0 ?
                                                <Col span={24} style={{ textAlign: 'right', marginTop: 20 }}>
                                                    <Button type="primary" size='large' onClick={() => this.confirm()}>Create Parcel</Button>
                                                </Col>
                                                :
                                                <></>
                                        }
                                    </Col>
                                </Row>
                                :
                                <></>
                        }
                    </Col>
                </Row>
            </>
        )
    }
}

export default compose(withRouter)(AdvanceOrderForm)