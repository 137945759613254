import { InboxOutlined } from "@ant-design/icons";
import { compose } from "@reduxjs/toolkit";
import { Badge, Button, Col, Input, message, Modal, Row, Skeleton, Spin, Table, Upload } from "antd";
import dayjs from 'dayjs';
import React from "react";
import { TitleLevel3 } from "../../components/general-component";
import Api from "../../network/api";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import { ApiHandler } from "../../network/network-manager";
import withRouter from "../../network/with-router";
const { Dragger } = Upload;

class AnnouncementPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            data: [],
            date_list: [],
            isDataFetching: false,
            filterDate: null,
            name_list: []
        }
    }

    componentDidMount() {
        this.fetchActivity()

        const today = dayjs();

        let date_list = []
        date_list.push(
            {
                key: 0,
                label: today.format('MMM DD, YYYY'),
                value: today.format('YYYY-MM-DD')
            }
        )
        for (let i = 1; i < 7; i++) {
            date_list.push(
                {
                    key: i,
                    label: today.subtract(i, 'day').format('MMM DD, YYYY'),
                    value: today.subtract(i, 'day').format('YYYY-MM-DD')
                }
            )
        }
        this.setState({
            date_list,
            filterDate: today.format('YYYY-MM-DD')
        })

    }

    fetchActivity = async () => {
        this.setState({
            isDataFetching: true
        })
        try {
            const response = await ApiHandler({ url: Api.announcement, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON})
            if (response) {
                this.setState({ data: response, name_list: response })
            }
        } catch (error) {
        }
        this.setState({
            isDataFetching: false
        })
    }

    handleFilterChange = (e) => {
        const value = e.target.value;
        const filteredList = this.state.name_list.filter(item =>
            item.businessName.toLowerCase().includes(value.toLowerCase())
        )
        this.setState({
            data: filteredList
        })
    }

    handleFileUpload = async (file) => {
        try {
            this.setState({
                isUploading: true
            })
            let formData = new FormData()
            formData.append('file', file);
            const response = ApiHandler({url: Api.upload_announcement_excel,method: HTTP_METHOD.POST,mediaType: MEDIA_TYPE.FORM_DATA,requestData: formData})
            if (response) {
                this.setState({
                    openUploadExcelModal: false
                })
            }
            this.fetchActivity()
        } catch (error) {
        }
        this.setState({
            isUploading: false,
            openUploadExcelModal: false
        })
    }

    closeModal = () => {
        if (this.state.isUploading) {
            message.error("You can not close at this time")
        } else {
            this.setState({
                openUploadExcelModal: false
            })
        }
    }

    render() {
        const { data, isDataFetching, openUploadExcelModal, isUploading } = this.state
        const draggerProps = {
            name: 'file',
            multiple: false,
            showUploadList: false,
            beforeUpload: () => false,
            onChange: (info) => {
                const { file } = info;
                this.handleFileUpload(file);
            },
        };
        const getRowClassName = (record, index) => {
            return index % 2 === 0 ? 'blue-row' : 'black-row';
        };
        const columns = [
            {
                title: 'No.',
                key: 'index',
                render: (value, item, index) => <span>{index + 1}</span>,
                width: 5
            },
            {
                title: 'Business Name',
                dataIndex: 'businessName',
                key: 'businessName',
            },
            {
                title: 'Content',
                dataIndex: 'content',
                key: 'content',
            },
            {
                title: 'Status',
                dataIndex: 'isRead',
                key: 'isRead',
                render: (record) => <Badge status={`${record.isRead ? 'success' : 'error'}`} text={`${record.isRead ? 'Seen' : 'Unseen'}`} />
            },
        ]
        return (
            <>
                <Modal
                    title={"Upload Announcement Excel file"}
                    centered
                    open={openUploadExcelModal}
                    footer={null}
                    onCancel={() => this.closeModal()}
                    className='custom-modal'
                >
                    <Dragger
                        disabled={isUploading}
                        {...draggerProps}
                        accept={[".xlsx", ".xls"]}
                        maxCount={1}
                        style={{ marginTop: 15 }}>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-hint">
                            {
                                isUploading ?
                                    <div>
                                        <Spin /> <span> Uploading...</span>
                                    </div>
                                    :
                                    <span>Upload Excel File Only</span>
                            }

                        </p>
                    </Dragger>
                </Modal>
                <Row>
                    <Col span={1} />
                    <Col span={22}>
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <TitleLevel3 label={"Announcement"} />
                            </Col>
                            <Col span={12} style={{ textAlign: "right" }}>
                                <Button type='primary' onClick={() => this.setState({ openUploadExcelModal: true })}>Upload Announcement</Button>
                            </Col>
                            <Col span={24}>
                                <Input
                                    onChange={this.handleFilterChange}
                                    style={{ width: 200, background: '#f1f1f1', height: 40, marginRight: 8 }}
                                    placeholder="Search by business name"
                                />
                            </Col>
                            <Col span={24}>
                                {
                                    isDataFetching ?
                                        <Skeleton active />
                                        :
                                        <Table
                                            style={{ borderRadius: 10 }}
                                            pagination={false}
                                            columns={columns}
                                            dataSource={data}
                                            rowClassName={getRowClassName} />
                                }
                            </Col>
                        </Row>
                    </Col>

                    <Col span={1} />
                </Row>
            </>
        )
    }
}

export default compose(withRouter)(AnnouncementPage)