import { Breadcrumb, Button, Card, Col, Form, Input, Row, Select } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import Theme from "../../components/theme";
import Api from "../../network/api";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import { ApiHandler } from "../../network/network-manager";
import withRouter from "../../network/with-router";
import CustomPath from "../../routes/custom-path";

class DepartmentForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            departmentTypes: [],
            loading: false
        };
        this.inputRef = React.createRef()
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = async () => {
        this.setState({
            isDataFetching: true
        })
        await ApiHandler({ url: Api.department_filter, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON })
            .then(response => {
                let departmentMap = new Map()
                response?.forEach((res) => {
                    departmentMap.set(res.name, res.name)
                })
                this.fetchDepartmentType(departmentMap)
            }).catch(error => {
            }).finally(() => {
                this.setState({
                    isDataFetching: false,
                })
            })
    }

    fetchDepartmentType = async (departmentMap) => {
        const requestParams = {
            'enumType': 'DEPARTMENT_TYPE',
        }
        await ApiHandler({ url: Api.enum_filter, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            .then(response => {
                let departmentTemp = []
                response.forEach((res) => {
                    if (!departmentMap.get(res.value)) {
                        departmentTemp.push({
                            value: res.value,
                            label: res.value,
                        });
                    }

                })
                this.setState({
                    departmentTypes: departmentTemp
                })
            })
            .catch(error => { })
            .finally(() => { })

    }

    saveDepartment = async (formData) => {
        this.setState({ loading: true })
        await ApiHandler({ url: Api.department, method: HTTP_METHOD.POST, mediaType: MEDIA_TYPE.JSON, requestData: formData })
            .then(response => {
                this.props.navigate(CustomPath.department)
            })
            .catch(err => { })
            .finally(() => {
                this.setState({ loading: false })
            })

    }

    render() {
        const { departmentTypes, loading } = this.state
        const { navigate } = this.props
        return (
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Breadcrumb
                        items={[
                            {
                                title: 'Home',
                            },
                            {
                                title: <Link to={CustomPath.department}>Department</Link>,
                            },
                            {
                                title: 'Form',
                            }
                        ]}
                    />
                </Col>
                <Col span={24}>
                    <Card
                        style={{ backgroundColor: Theme.colors.card_bg_color, boxShadow: 'none' }}
                        bordered={false}
                        title={"Department Form"}
                        extra={<Button type="primary" onClick={() => navigate(CustomPath.department)}>Back</Button>}
                    >
                        <Form
                            layout="vertical"
                            ref={this.inputRef}
                            initialValues={{ active: true }}
                            onFinish={this.saveDepartment}>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item name="name" label={"Department"} rules={[{ required: true, message: '*(requried)' }]}>
                                        <Select enumtype='DEPARTMENT_TYPE' size='large' placeholder={"Select department "} width="100%"
                                            options={departmentTypes}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item name="description" label={"Description"}>
                                        <Input placeholder={"Enter description"} />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit" loading={loading}>Create</Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                </Col>
            </Row>
        );
    }
}

export default compose(withRouter)(DepartmentForm)