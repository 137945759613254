import { createSlice } from '@reduxjs/toolkit'

export const orderFormSlice = createSlice({
    name: 'orderform',
    initialState: {
        orderList: [],
        orderCardList: [],
        advanceOrderList: [],
        senderInformation: {},
        selectedShipperInfo: {}
    },
    reducers: {
        updateOrderList: (state, action) => {
            state.orderList = action.payload
        },
        updateOrderCardList: (state, action) => {
            state.orderCardList = action.payload
        },
        updateAdvanceOrderList: (state, action) => {
            state.advanceOrderList = action.payload
        },
        updateSenderInformation: (state, action) => {
            state.senderInformation = action.payload
        },
        upateSelectedShipper: (state, action) => {
            state.selectedShipperInfo = action.payload
            if (action.payload === null) {
                state.senderInformation = null
            }
        },
        clearOrder: (state, action) => {
            state.orderList = []
            state.advanceOrderList = []
            state.senderInformation = {}
            state.selectedShipperInfo = {}
        }


    },
})

export const { updateOrderList, updateOrderCardList, clearOrder, updateAdvanceOrderList, updateSenderInformation, upateSelectedShipper } = orderFormSlice.actions
export const orderList = (state) => state.orderform.orderList
export const orderCardList = (state) => state.orderform.orderCardList
export const advanceOrderList = (state) => state.orderform.advanceOrderList
export const senderInformation = (state) => state.orderform.senderInformation
export const selectedShipperInformation = (state) => state.orderform.selectedShipperInfo
export default orderFormSlice.reducer
