import { compose } from "@reduxjs/toolkit";
import { Button, Col, Row, Skeleton, Table } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { NJVAddButton } from "../../components/core-component";
import { TitleLevel3 } from "../../components/general-component";
import Api from "../../network/api";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import { ApiHandler } from "../../network/network-manager";
import withRouter from "../../network/with-router";
import CustomPath from "../../routes/custom-path";

class DepartmentPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isDataFetching: false,
        }
    }
    componentDidMount() {
        this.fetchData()
    }

    fetchData = async () => {
        this.setState({
            isDataFetching: true
        })
        await ApiHandler({ url: Api.department_filter, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON })
            .then(response => {
                this.setState({
                    data: response,
                });
            }).catch(error => {
            }).finally(() => {
                this.setState({
                    isDataFetching: false,
                })
            })
    }


    render() {
        const { data, isDataFetching } = this.state
        const getRowClassName = (record, index) => {
            return index % 2 === 0 ? 'blue-row' : 'black-row';
        };
        const columns = [
            {
                title: 'No.',
                key: 'index',
                render: (value, item, index) => <span>{index + 1}</span>,
                width: 100
            },
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                width: 250
            },
            {
                title: 'Description',
                dataIndex: 'description',
                key: 'description',
            },
            {
                title: 'Action',
                key: 'action',
                dataIndex: '',
                textAlign: 'center',
                width: 60,
                render: (department) => (
                    <Link to={`${CustomPath.department_update}/${department.id}`}><Button size={'middle'} type="primary" shape="round">Team Management</Button></Link>
                )
            },
        ]
        return (
            <Row gutter={[16, 16]}>
                <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
                    <TitleLevel3 label={"Department"} />
                </Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                    <Link to={CustomPath.department_create}><NJVAddButton /></Link>
                </Col>
                <Col span={24}>
                    {
                        isDataFetching ?
                            <Skeleton active />
                            :
                            <Table
                                // className="custom-table"
                                style={{ borderRadius: 10 }}
                                pagination={false}
                                columns={columns} dataSource={data}
                                rowClassName={getRowClassName} />
                    }
                </Col>
            </Row>
        );
    }
}


export default compose(withRouter)(DepartmentPage)