import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Col, Input, Modal, Row, Select, Skeleton, Space, Table, Tag } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { NJVAddButton } from "../../components/core-component";
import { TitleLevel3 } from "../../components/general-component";
import { Constant } from "../../core/constant";
import Api from "../../network/api";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import { ApiHandler } from "../../network/network-manager";
import withRouter from "../../network/with-router";
import CustomPath from "../../routes/custom-path";
import FetchSearchUrl from "../../components/fetchSearchUrl";
import SearchFilter from "../../components/searchFilter";
import SearchPagination from "../../components/searchPagination";

class NearbyPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pageSize: Constant.pageSize,
            totalPagination: 0,
            page: 1,
            searchValue: [],
            fetchUserTypes: [],
            deleteItem: null,
            nearbyTypes: [],
            filter_name: '',
            filter_type: 'ALL',
            isDataFetching: false
        }
    }

    componentDidMount() {
        const { filter_name, filter_type } = this.state;
        const existingFilterData = filter_type || {};
        const defaultFilter = existingFilterData.filter_type ? { type: existingFilterData.filter_type } : { type: 'ALL' };

        const { filterData, urlPage } = FetchSearchUrl(defaultFilter)

        if (urlPage) {
            this.setState({
                filter_name: filterData.name || filter_name,
                filter_type: filterData.type || filter_type,
            }, () => this.fetchData(urlPage))
        }
        this.fetchNearbyType()
    }

    fetchNearbyType = async () => {

        try {
            const response = await ApiHandler({ url: Api.nearby_type, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON })
            if (response) {
                let nearbyTypeTemp = []
                nearbyTypeTemp.push({
                    value: 'ALL',
                    label: 'ALL',
                });
                response.map((value) => {
                    nearbyTypeTemp.push({
                        value: value,
                        label: value,
                    });
                })
                this.setState({
                    nearbyTypes: nearbyTypeTemp
                });
            }
        } catch (error) {

        }
    }

    fetchData = async (pageNumber) => {
        const { page, filter_name, filter_type, pageSize } = this.state
        this.setState({
            isDataFetching: true
        })
        const requestParams = {
            size: pageSize,
            page: pageNumber ? pageNumber - 1 : page - 1,
            nearbyType: filter_type === 'ALL' ? null : filter_type,
            name: filter_name
        }
        await ApiHandler({ url: Api.nearby_filter, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            .then(response => {
                this.setState({
                    data: response.content,
                    totalPagination: response.totalElements,
                    page: pageNumber ? pageNumber : page
                });
            }).catch(error => {

            }).finally(() => {
                this.setState({
                    isDataFetching: false
                })
            })
    }


    handleSearchName = (event) => {
        if (event.target.name === 'name') {
            this.setState({ filter_name: event.target.value })
        }
    }

    handleSelect = (value) => {
        this.setState({ filter_type: value })
    }

    search = () => {
        const { filter_name, filter_type } = this.state;

        const filterData = {
            name: filter_name,
            type: filter_type
        }

        const newUrl = SearchFilter(filterData);
        window.history.pushState({}, '', newUrl)
        this.fetchData(1)
    }

    handlePaginationChange = (pageNumber) => {
        const newUrl = SearchPagination(pageNumber)
        window.history.pushState({}, '', newUrl)
        this.fetchData(pageNumber);
    };

    deleteNearby = (id) => {
        this.setState({
            deleteItem: id,
            openModal: true
        })
    }
    deleteNearbyConfirm = async () => {
        const { deleteItem } = this.state
        try {
            await ApiHandler({ url: Api.nearby, method: HTTP_METHOD.DELETE, mediaType: MEDIA_TYPE.JSON, specificId: deleteItem })
        } catch (error) {

        }
        this.setState({
            openModal: false,
            deleteItem: ''
        });
        this.fetchData()
    }


    render() {
        const { data, totalPagination, page, pageSize, openModal, nearbyTypes, isDataFetching, filter_name, filter_type } = this.state
        const currentSearchUrl = window.location.search;

        const getRowClassName = (record, index) => {
            return index % 2 === 0 ? 'blue-row' : 'black-row';
        };
        const columns = [
            {
                title: 'No.',
                key: 'index',
                render: (value, item, index) => <span>{(page - 1) * pageSize + index + 1}</span>,
                width: 5
            },
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
            }, {
                title: 'Latitude',
                dataIndex: 'latitude',
                key: 'latitude',
            },
            {
                title: 'Longitude',
                dataIndex: 'longitude',
                key: 'longitude',
            },
            {
                title: 'Type',
                key: 'nearbyType',
                render: (_, { nearbyType }) => (
                    <>
                        <Tag color="green">{nearbyType}</Tag>
                    </>
                ),

            },
            {
                title: 'Status',
                key: 'active',
                render: (_, { active }) => (
                    <>
                        {
                            (
                                active ? <Tag color="#87d068">Active</Tag> : <Tag color="#cd201f">Inactive</Tag>
                            )
                        }
                    </>
                ),

            },
            {
                title: 'Action',
                key: 'action',
                dataIndex: '',
                textAlign: 'center',
                width: 60,
                render: (nearby) => (
                    <Space>
                        <Link to={`${CustomPath.nearby_update}/${nearby.id}`} state={{ route: `${CustomPath.nearby}${currentSearchUrl}` }}><Button size={'middle'} type="primary" shape="circle"><EditOutlined /></Button></Link>
                        <Button size={'middle'} type="primary" shape="circle" onClick={() => this.deleteNearby(nearby.id)}><DeleteOutlined /></Button>
                    </Space>
                ),
            },
        ]

        return (
            <>
                <Modal
                    title="Delete"
                    open={openModal}
                    onOk={() => this.deleteNearbyConfirm()}
                    onCancel={() => this.setState({ openModal: false })}
                    okText="Confirm"
                    cancelText="Cancel"
                >
                    <p>Are you sure to delete?</p>
                </Modal>
                <Row style={{ marginBottom: "20px" }}>
                    <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
                        <TitleLevel3 label={"Nearby"} />
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        <Link to={CustomPath.nearby_create}><NJVAddButton /></Link>
                    </Col>
                </Row>
                <Row gutter={16} style={{ marginBottom: 30 }}>
                    <Col span={6}>
                        <Input
                            value={filter_name}
                            onChange={this.handleSearchName}
                            style={{ background: '#f1f1f1', height: 40, marginRight: 8 }}
                            placeholder="Search by name"
                            name="name"
                        />
                    </Col>
                    <Col span={6} >
                        <Select
                            size="large"
                            className="custom-selector-gray"
                            // defaultValue="ALL"
                            value={filter_type}
                            onChange={this.handleSelect}
                            style={{
                                width: '100%',
                            }}
                            options={nearbyTypes}
                        />
                    </Col>
                    <Col span={6} />
                    <Col span={6} className="search">
                        <Button onClick={() => this.search()} type="primary" style={{ borderRadius: 10, height: 40, width: '100%' }}>Search</Button>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        {
                            isDataFetching ?
                                <Skeleton active />
                                :
                                <Table
                                    style={{ borderRadius: 10 }}
                                    pagination={{
                                        position: 'top' | 'bottom',
                                        total: totalPagination,
                                        current: page,
                                        onChange: this.handlePaginationChange,
                                        defaultPageSize: pageSize,
                                        showSizeChanger: false
                                    }}
                                    columns={columns}
                                    dataSource={data}
                                    rowClassName={getRowClassName}
                                />
                        }
                    </Col>
                </Row>
            </>
        );
    }
}

export default compose(withRouter)(NearbyPage)