import { compose } from '@reduxjs/toolkit'
import { Breadcrumb, Button, Card, Col, Form, Image, Input, message, Radio, Row, Select, Skeleton } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import ThumbnailImage from '../../../asset/ninja_thumbnail.jpg'
import { NJVSearchFilterOption, NJVUpdateButton } from '../../../components/core-component'
import Theme from '../../../components/theme'
import { PhoneNumberValidator } from '../../../components/validator'
import Api from '../../../network/api'
import { HTTP_METHOD } from '../../../network/httpMethod'
import { MEDIA_TYPE } from '../../../network/mediaType'
import { ApiHandler } from '../../../network/network-manager'
import withRouter from '../../../network/with-router'
import CustomPath from '../../../routes/custom-path'

class CustomerUpdate extends Component {
    constructor(props) {
        super(props)
        this.state = {
            profileFileList: [],
            isFetching: false,
            previewImage: '',
            previewOpen: false,
            previewTitle: '',
            data: {},
            township_collection: []
        }
        this.inputRef = React.createRef()
    }

    componentDidMount = () => {
        this.fetchData()
    }

    fetchData = async () => {
        this.setState({
            isFetching: true
        })
        await ApiHandler({ url: Api.customer, method: HTTP_METHOD.GET, specificId: this.props?.params?.id })
            .then(response => {
                if (response?.userStatus === 'ACTIVE') {
                    response['status'] = true
                } else {
                    response['status'] = false
                }
                
                this.setState({
                    data: response,
                    township_collection: this.props?.townshipCollectionMap?.[response?.township?.division?.id]
                }, () => this.inputRef?.current?.setFieldsValue({ ...response, divisionId: response?.township?.division?.id, townshipId: response?.township?.id }))
            })
            .catch(error => {console.log(error) })
            .finally(() => {
                this.setState({
                    isFetching: false
                })
            })
    }

    handleDivisionSelect = (value) => {
        this.setState({
            township_collection: this.props?.townshipCollectionMap?.[value]
        })
        this.inputRef.current.setFieldsValue({ townshipId: null });
    }

    updateCustomer = async (values) => {

        const { data } = this.state
        this.setState({
            isLoading: true
        })
        let formData = new FormData()
        formData.append("id", data.id);
        formData.append("fullName", values.fullName);
        formData.append("phoneNumber", values.phoneNumber)
        formData.append("address", values.address)
        formData.append("nrc", values.nrc)
        formData.append("townshipId", values.townshipId)
        formData.append("userStatus", values.userStatus);
        await ApiHandler({ url: Api.customer, method: HTTP_METHOD.PUT, mediaType: MEDIA_TYPE.FORM_DATA, requestData: formData, specificId: data.id })
            .then(() => { this.props.navigate(CustomPath.customer) })
            .catch(() => { })
            .finally(() => {
                this.setState({
                    isLoading: false
                })
            })
    }

    onFinishFailed = () => {
        message.destroy()
        message.error('Please fill required fields');
    }

    render() {
        const { data, township_collection, isLoading, isFetching } = this.state

        return (
            <>
                <Breadcrumb
                    items={[
                        {
                            title: 'Home',
                        },
                        {
                            title: <Link to={CustomPath.customer}>Customer</Link>,
                        },
                        {
                            title: 'Update',
                        }
                    ]}
                    style={{ marginBottom: "20px" }}
                />
                {
                    isFetching ? <Skeleton /> : (
                        <Card
                            style={{ backgroundColor: Theme.colors.card_bg_color, boxShadow: 'none', marginBottom: "20px" }}
                            bordered={false}
                            title={"Customer Update"}
                            extra={<Link to={CustomPath.customer}><Button type="primary">Back</Button></Link>}
                        >
                            <Form
                                ref={this.inputRef}
                                layout="vertical"
                                onFinish={this.updateCustomer}
                                onFinishFailed={this.onFinishFailed}
                            >
                                <Row gutter={[16, 16]}>
                                    {
                                        data?.profileUrl &&
                                        <Col span={24}>
                                            <Form.Item name="profileUrl">
                                                <Image width={120} height={120} style={{ border: '1px dashed gray', borderRadius: '50%' }} src={data?.profileUrl} fallback={ThumbnailImage} />
                                            </Form.Item>
                                        </Col>
                                    }
                                    <Col span={12} >
                                        <Form.Item name="fullName" label={"Full Name"} rules={[{ required: true, message: '*(requried)' }]}>
                                            <Input placeholder={"Enter full name"} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12} >
                                        <Form.Item name="phoneNumber" label={"Phone Number"} rules={[
                                            { required: true, message: '*(requried)' },
                                            { validator: PhoneNumberValidator }]}>
                                            <Input placeholder={"Enter phone number"} disabled />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12} >
                                        <Form.Item name="nrc" label={"NRC"}
                                        >
                                            <Input placeholder={"Enter NRC number"} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name="divisionId" label={"Division/State"} rules={[{ required: true, message: '*(requried)' }]}>
                                            <Select
                                                className="my-location-selector"
                                                placeholder={"Select Division"}
                                                onChange={this.handleDivisionSelect}
                                                showSearch
                                                filterOption={NJVSearchFilterOption}
                                                size="large"
                                                options={
                                                    this.props?.divisionCollection && this.props?.divisionCollection.map((division) => {
                                                        return {
                                                            value: division.id,
                                                            label: division.name,
                                                            type: 'division'
                                                        }
                                                    })
                                                }
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item name="townshipId" label={"Township/City"} rules={[{ required: true, message: '*(requried)' }]}>
                                            <Select
                                                className="my-location-selector"
                                                placeholder={"Select township"}
                                                showSearch
                                                filterOption={NJVSearchFilterOption}
                                                size="large"
                                                options={
                                                    township_collection && township_collection.map((township) => {
                                                        return {
                                                            value: township.id,
                                                            label: township.name,
                                                            type: 'division'
                                                        }
                                                    })
                                                }
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name="address" label={"Address"} rules={[{ required: true, message: '*(requried)' }]}>
                                            <TextArea
                                                style={{ height: 60 }} placeholder={"Enter address"} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            name="userStatus">
                                            <Radio.Group>
                                                <Radio value={'ACTIVE'}>Active</Radio>
                                                <Radio value={'INACTIVE'}>Inactive</Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item>
                                            <NJVUpdateButton type="primary" htmlType="submit"
                                                loading={isLoading}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Card>
                    )
                }
            </>
        )
    }
}

export default compose(withRouter)(CustomerUpdate)