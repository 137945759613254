import { Button, Col, Drawer, Input, Modal, Row, Select, Skeleton, Table, message } from "antd";
import dayjs from 'dayjs';
import React from "react";
import { compose } from "redux";
import { FormLabelOptional, TitleLevel3 } from "../../components/general-component";
import Theme, { Colors } from "../../components/theme";
import { Constant } from "../../core/constant";
import Api from "../../network/api";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import { ApiHandler, downloadExcel } from "../../network/network-manager";
import withRouter from "../../network/with-router";
import DriverAwbInventoryHistory from "./driver-awb-inventory-history";
import { updateDivisionList, updateTownShipList, updateTreeData } from "../../redux/location-slice";
import {
    HistoryOutlined,
    PlusOutlined,
    RetweetOutlined
} from '@ant-design/icons';

class DriverAwbInventoryPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pageSize: Constant.pageSize,
            totalPagination: 0,
            page: 1,
            searchName: '',
            searchPhone: '',
            openModal: false,
            openCreateModal: false,
            openUpdateModal: false,
            activeStatus: true,
            isDataFetching: false,
            updateForm: {},
            updateDivision: {},
            filterData: {},
            specificDriveData: { currentStock: 0, differentStock: 0, possibleAwbRemainCount: 100 },
            openStockCheckModal: false,
            openStockInModal: false,
            isFetchingCurrentPickupCount: false,
            submittingStockCheck: false,
            submittingStockIn: false,
            currentPickCount: 0,
            openBottomSheet: false,
            selectedDriver: null,
            downloading_excel: false,
            township_collection: []
        }
        this.inputRef = React.createRef();
        this.updateRef = React.createRef();
    }

    componentDidMount() {
        this.fetchData()
        this.fetchDivision()
    }

    fetchData = async (pageNumber) => {
        const { page, pageSize, filterData } = this.state
        this.setState({
            isDataFetching: true
        })
        if (filterData && filterData.assignTownshipName && filterData.assignTownshipName.length !== 0 && filterData.assignTownshipName.length < 2) {
            message.error("Please enter at least three words")
            this.setState({
                isDataFetching: false
            })
            return
        }
        const requestParams = {
            'name': filterData?.name,
            'phoneNumber': filterData?.phoneNumber,
            'townshipId': filterData?.townshipId,
            size: pageSize,
            page: pageNumber ? pageNumber - 1 : page - 1
        }
        await ApiHandler({ url: Api.driver_filter, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            .then(response => {

                let temp = response?.content?.map(item => {
                    if (item?.updatedStockDate) {
                        const updateTime = dayjs(item?.updatedStockDate, 'YYYY-MM-DD hh:mm a');
                        item['updatedStockDate'] = updateTime.format('YYYY-MM-DD hh:mm A')
                    }
                    return item
                })
                this.setState({
                    data: temp,
                    totalPagination: response.totalElements,
                    page: pageNumber ? pageNumber : page
                })
            })
            .catch(() => { })
            .finally(() => {
                this.setState({
                    isDataFetching: false
                })
            })
    }
    changeSearchName = (event) => {
        this.setState({
            searchName: event.target.value
        })
    }

    changeSearchPhone = (event) => {
        this.setState({
            searchPhone: event.target.value
        })
    }

    search = () => {
        this.fetchData(1)
    }

    handlePaginationChange = (pageNumber, pageSize) => {
        this.fetchData(pageNumber);
    };

    updateFilterData = (key, value) => {
        let { filterData } = this.state
        const { townshipCollectionMap } = this.props;
        filterData = {
            ...filterData,
            [key]: value
        }
        if (key === 'divisionName') {
            const townshipLists = townshipCollectionMap[value?.value];
            if (townshipLists) {
                this.setState({
                    township_collection: townshipLists
                });
            }
        }
        if (key === 'townshipName') {
            filterData = {
                ...filterData,
                'townshipId': value?.value
            }
        }
        console.log(filterData)
        this.setState({
            filterData
        })
    }

    goToStockChecker = (driver) => {
        this.setState({
            openStockCheckModal: true,
            specificDriveData: {
                ...driver, currentStock: 0, differentStock: 0, possibleAwbRemainCount: 100
            }
        })
    }

    onChange = (key, value) => {
        let { specificDriveData } = this.state
        const numberPattern = /^-?\d*$/;
        if (key === 'stockCheckCurrentValue') {
            if (numberPattern.test(value)) {
                specificDriveData['stockCheckCurrentValue'] = value
                let possibleCurrentCount = specificDriveData.currentAwbCount
                // if (possibleCurrentCount > value) {
                //     specificDriveData['differentStock'] = possibleCurrentCount - value
                // } else {
                //     specificDriveData['differentStock'] = 0
                // }
                specificDriveData['differentStock'] = value - possibleCurrentCount
            } else {
                specificDriveData['stockCheckCurrentValue'] = ''
                specificDriveData['differentStock'] = 0
            }

        } else if (key === 'note') {
            specificDriveData['note'] = value
        } else if (key === 'stockInTotalValue') {
            if (numberPattern.test(value)) {
                specificDriveData['stockInTotalValue'] = value
            } else {
                specificDriveData['stockInTotalValue'] = ''
            }
        }
        this.setState({
            specificDriveData
        })
    }


    fetchPickupCount = async () => {
        const { specificDriveData } = this.state
        try {
            const response = await ApiHandler({ url: Api.driver_inventory_get_current_pickup_count, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, specificId: specificDriveData.id })
            if (response) {
                this.setState({
                    currentPickCount: response
                })
            }
        } catch (error) {

        }
    }
    stockCheck = async () => {
        const { specificDriveData, currentPickCount } = this.state
        this.setState({
            submittingStockCheck: true
        })
        try {
            const formData = {
                driverId: specificDriveData.id,
                status: 'STOCK_CHECK',
                note: specificDriveData.note,
                differentStock: specificDriveData.differentStock,
                updatedStock: specificDriveData.currentAwbCount > currentPickCount ? specificDriveData.currentAwbCount - currentPickCount : 0,
                mainStock: specificDriveData.currentAwbCount,
                currentStock: specificDriveData.stockCheckCurrentValue
            }
            await ApiHandler({ url: Api.driver_inventory_stock_update, method: HTTP_METHOD.POST, mediaType: MEDIA_TYPE.JSON, requestData: formData }).then(() => { this.fetchData() })

        } catch (error) {
        }
        this.setState({
            submittingStockCheck: false,
            openStockCheckModal: false,
            specificDriveData: {}
        })
    }

    stockIn = async () => {
        const { specificDriveData } = this.state
        this.setState({
            submittingStockIn: true
        })
        try {
            const formData = {
                driverId: specificDriveData.id,
                status: 'STOCK_IN',
                mainStock: specificDriveData.stockInTotalValue,
                currentStock: specificDriveData.stockInTotalValue
            }
            await ApiHandler({ url: Api.driver_inventory_stock_update, method: HTTP_METHOD.POST, mediaType: MEDIA_TYPE.JSON, requestData: formData }).then(() => { this.fetchData() })
        } catch (error) {

        }
        this.setState({
            submittingStockIn: false,
            openStockInModal: false,
            specificDriveData: {}

        })
    }

    downloadExcel = async () => {
        const { filterData } = this.state
        this.setState({
            downloading_excel: true
        })
        try {
            let params = {
                'driverName': filterData?.name,
                'phoneNumber': filterData?.phoneNumber,
                'townshipId': filterData?.townshipId,
            }
            await downloadExcel(Api.driver_inventory_record_export, params, "driver-inventory-record-export")
        } catch (error) {
        }
        this.setState({
            downloading_excel: false
        })
    }
    fetchDivision = async () => {
        await ApiHandler({ url: Api.division_get_all, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON })
            .then((response) => {
                let township_map = {}
                let division_collection = []

                division_collection = response
                this.props.dispatch(updateDivisionList(response));

                division_collection?.map(division => {
                    township_map[division.id] = division.townshipDTOList
                })
                this.props.dispatch(updateTownShipList(township_map))
                const treeData = response.map(division => ({
                    title: division.name,
                    value: `division-${division.id}`,
                    selectable: false,
                    children: division.townshipDTOList.map(township => ({
                        title: township.name,
                        value: `division-${division.id}-township-${township.id}`
                    }))
                }))

                this.props.dispatch(updateTreeData(treeData))

            })
            .catch((error) => {
            })
    }

    render() {
        const { filterData, data, totalPagination, page, pageSize, isDataFetching, openStockCheckModal, openStockInModal, specificDriveData, downloading_excel, submittingStockCheck, submittingStockIn, openBottomSheet, selectedDriver, township_collection, fetching_division } = this.state
        const getRowClassName = (record, index) => {
            return index % 2 === 0 ? 'blue-row' : 'black-row';
        };
        const columns = [
            {
                title: 'No.',
                key: 'index',
                render: (value, item, index) => <span>{(page - 1) * pageSize + index + 1}</span>,
                width: 5
            },
            {
                title: 'Unique ID',
                dataIndex: 'specialCode',
                key: 'specialCode'
            },
            {
                title: 'Full Name',
                key: 'fullName',
                render: (driver) => (
                    <span>{driver.firstName} {driver.lastName}</span>
                ),
            },
            {
                title: 'Total AWB',
                key: 'totalAwbCount',
                dataIndex: 'totalAwbCount',
                width: 120
            },
            {
                title: 'Current AWB',
                key: 'currentAwbCount',
                dataIndex: 'currentAwbCount',
                width: 120
            }, {
                title: 'AWB Print Count',
                key: 'awbPrintCount',
                dataIndex: 'awbPrintCount',
                width: 150,
            }, {
                title: 'Stock Updated Date',
                key: 'updatedStockDate',
                dataIndex: 'updatedStockDate'
            },
            {
                title: 'Action',
                key: 'action',
                dataIndex: '',
                textAlign: 'center',
                width: 100,
                render: (driver) => (
                    <>
                        <Button size='small'
                            type="text"
                            icon={<RetweetOutlined />}
                            style={{ fontWeight: '600', color: Theme.colors.default }}
                            onClick={() => {
                                this.setState({
                                    openStockCheckModal: true,
                                    isFetchingCurrentPickupCount: true,
                                    specificDriveData: {
                                        ...driver, currentStock: 0, differentStock: 0, possibleAwbRemainCount: 100
                                    }

                                }, () => this.fetchPickupCount())
                            }}>Stock Check</Button><br />
                        <Button size='small' type="text"
                            style={{ marginTop: 5, fontWeight: '600', color: Theme.colors.default }}
                            icon={<PlusOutlined />}
                            onClick={() => this.setState({
                                openStockInModal: true,
                                specificDriveData: {
                                    ...driver, currentStock: 0, differentStock: 0, possibleAwbRemainCount: 0
                                }
                            })}>Stock In</Button><br />

                        <Button size='small' type="text"
                            icon={<HistoryOutlined />}
                            onClick={() => {
                                document.body.style.zoom = "100%";
                                this.setState({
                                    openBottomSheet: true,
                                    selectedDriver: driver
                                })
                            }} style={{ marginTop: 5, fontWeight: '600', color: Theme.colors.default }}>History</Button>
                    </>
                ),
            },
        ]

        return (
            <>
                <Modal
                    title={"Stock Check"}
                    open={openStockCheckModal}
                    onCancel={() => this.setState({ openStockCheckModal: false, specificDriveData: {} })}
                    footer={false}
                >
                    <Row gutter={[16, 16]}>
                        {/* <Col span={24}> */}
                        {/* Picked Up Count : {currentPickCount}<br /> */}
                        {/* <span>From </span> */}

                        {/* specificDriveData.updatedStockDate ?
                                     <span>{dayjs(specificDriveData.updatedStockDate, 'YYYY-MM-DD HH:mm:ss').format('DD MMM,YYYY hh:mm A')}</span>
                                 :
                                     <></> */}

                        {/* <span> To Current Date</span>
                         </Col> */}
                        <Col span={24}>
                            <FormLabelOptional label="Driver's physical AWB count" />
                            <Input
                                placeholder="Enter AWB count"
                                onChange={(e) => this.onChange('stockCheckCurrentValue', e.target.value)}
                                style={{ background: '#f1f1f1', height: 40, marginRight: 8 }}
                                value={specificDriveData.stockCheckCurrentValue}
                            />
                        </Col>
                        <Col span={24}>
                            <FormLabelOptional label="Different AWB" />
                            <Input
                                disabled={true}
                                style={{ background: '#f1f1f1', height: 40, marginRight: 8 }}
                                value={specificDriveData.differentStock}
                            />
                        </Col>
                        <Col span={24}>
                            <FormLabelOptional label="Note" />
                            <Input
                                placeholder="Enter note"
                                onChange={(e) => this.onChange('note', e.target.value)}
                                style={{ background: '#f1f1f1', height: 40, marginRight: 8 }}
                                value={specificDriveData.note}
                            />
                        </Col>
                        <Col span={24}>
                            <Button type="primary" onClick={() => this.stockCheck()} loading={submittingStockCheck}>
                                Stock Check
                            </Button>
                        </Col>
                    </Row>
                </Modal>
                <Modal
                    title={"Stock In"}
                    open={openStockInModal}
                    onCancel={() => this.setState({ openStockInModal: false, specificDriveData: {} })}
                    footer={false}
                >
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <FormLabelOptional label="AWB count" />
                            <Input
                                placeholder="Enter awb count"
                                onChange={(e) => this.onChange('stockInTotalValue', e.target.value)}
                                style={{ background: '#f1f1f1', height: 40, marginRight: 8 }}
                                value={specificDriveData.stockInTotalValue}
                            />
                        </Col>
                        <Col span={24}>
                            <Button type="primary" onClick={() => this.stockIn()} loading={submittingStockIn}>
                                Stock In
                            </Button>
                        </Col>
                    </Row>
                </Modal>
                <Drawer
                    title={`${selectedDriver?.fullName}'s AWB Inventory History`}
                    placement={'bottom'}
                    closable={false}
                    open={openBottomSheet}
                    height={'95%'}
                    extra={<Button type="primary" onClick={() => {
                        document.body.style.zoom = "85%";
                        this.setState({ openBottomSheet: false })
                    }
                    }>Close</Button>}
                >
                    <DriverAwbInventoryHistory id={selectedDriver?.id} refresh={openBottomSheet} />
                </Drawer>
                <Row style={{ alignItems: 'center' }} gutter={[20, 20]}>
                    <Col span={24} style={{ display: 'flex', alignItems: 'center' }}>
                        <TitleLevel3 label={"Driver AWB Inventory"} />
                    </Col>

                    <Col span={6}>
                        <Input
                            onChange={(event) => this.updateFilterData('name', event.target.value)}
                            style={{ background: '#f1f1f1', height: 40, marginRight: 8 }}
                            placeholder="Search by name"
                        />
                    </Col>
                    <Col span={6}>
                        <Input
                            onChange={(event) => this.updateFilterData('phoneNumber', event.target.value)}
                            style={{ background: '#f1f1f1', height: 40, marginRight: 8 }}
                            placeholder="Search by phone"
                        />
                    </Col>
                    <Col span={6}>
                        <Select
                            value={filterData?.divisionName}
                            className="custom-selector-gray"
                            onChange={(_, obj) => this.updateFilterData('divisionName', obj)}
                            size="large"
                            allowClear={true}
                            style={{ width: '100%' }}
                            placeholder="Select division"
                            options={this.props?.divisionCollection?.map(division => {
                                return {
                                    value: division.id,
                                    label: division.name,
                                    type: 'division'
                                }
                            })}
                        />
                    </Col>
                    <Col span={6}>
                        <Select
                            value={filterData?.townshipName}
                            className="custom-selector-gray"
                            onChange={(_, obj) => this.updateFilterData('townshipName', obj)}
                            size="large"
                            allowClear={true}
                            showSearch
                            optionFilterProp="label"
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            style={{ width: '100%' }}
                            placeholder="Select assign township"
                            loading={fetching_division}
                            options={township_collection?.map(township => {
                                return {
                                    value: township.id,
                                    label: township.name,
                                    type: 'township'
                                }
                            })}
                        />
                    </Col>
                    <Col span={12} />
                    <Col span={6}>
                        <Button onClick={() => this.search()} type="primary" style={{ borderRadius: 10, height: 40, width: '100%' }}>Search</Button>
                    </Col>
                    <Col span={6}>
                        <Button loading={downloading_excel} onClick={() => this.downloadExcel()} type="primary" style={{ borderRadius: 10, height: 40, width: '100%', backgroundColor: Colors.darkGreen }}>Export</Button>
                    </Col>
                    <Col span={24}>

                        {
                            isDataFetching ?
                                <Skeleton active />
                                :
                                <Table
                                    style={{ borderRadius: 10 }}
                                    pagination={{
                                        position: 'top' | 'bottom',
                                        total: totalPagination,
                                        current: page,
                                        onChange: this.handlePaginationChange,
                                        defaultPageSize: pageSize,
                                        showSizeChanger: false
                                    }}
                                    columns={columns}
                                    dataSource={data}
                                    rowClassName={getRowClassName}
                                />
                        }
                    </Col>
                </Row>
            </>
        );
    }
}

export default compose(withRouter)(DriverAwbInventoryPage)