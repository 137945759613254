import { ArrowLeftOutlined, PlusCircleFilled, SearchOutlined } from '@ant-design/icons';
import { Button, ConfigProvider, Input, InputNumber, Select } from 'antd';
import { useSelector } from 'react-redux';
import { menuMap } from '../redux/menu-slice';
import Theme from './theme';

export const NJVSpacer = ({ height, width }) => {
    return (
        <div style={{ height: height, width: width, display: 'inline-block' }}></div>
    )
}

export const NJVAddButton = ({ handleClick, label }) => {
    return <Button
        type="primary"
        icon={<PlusCircleFilled />}
        shape="round"
        onClick={handleClick}
    >
        {label || 'Create'}
    </Button>
}

export const NJVSearchButton = (props) => {
    return <Button
        type="primary"
        icon={<SearchOutlined />}
        shape="round"
        {...props}
    >
        Search
    </Button>
}


export const NJVBackButton = ({ handleClick, label }) => {
    return <Button
        type="primary"
        icon={<ArrowLeftOutlined />}
        shape="round"
        onClick={handleClick}
    >
        {label || 'Back'}
    </Button>
}

export const NJVButton = ({ ...buttonProps }) => {
    const menuAccessMap = useSelector(menuMap)
    const access = menuAccessMap[buttonProps.menuName]
    if (access && access.write_edit) {
        return (
            <ConfigProvider theme={{
                token: {
                    // colorPrimary: '#bf002e',
                    borderRadius: '7px',
                    borderRadiusLG: '9px'
                }
            }}>
                <Button
                    {...buttonProps}
                >
                    {buttonProps.text}
                </Button>
            </ConfigProvider>
        )
    }
}

export const NJVUpdateButton = (props) => {
    const { label } = props;
    return <Button
        type="primary"
        shape="round"
        {...props}
    >{label || 'Update'}</Button>
}
export const NJVInput = ({ ...inputProps }) => {
    const { bgcolor, ispasswordinput, istextareainput, issearchinput, isnumberinput } = inputProps;

    return (
        <ConfigProvider theme={{
            token: {
                colorBorder: '#fff',
                colorBgContainer: `${bgcolor ? bgcolor : '#fff'}`,
                colorPrimaryHover: Theme.colors.default,
                colorPrimary: 'rgb(128, 128, 128)',
            },
            components: {
                Input: {
                    activeShadow: `0 0 0 1px ${Theme.colors.default}`,
                    borderRadius: 9,
                    borderRadiusLG: 9,
                },
                InputNumber: {
                    activeShadow: '0 0 0 0.5px rgb(128,128,128)',
                    borderRadius: 9,
                    borderRadiusLG: 9,
                },
                Search: {
                    activeShadow: '0 0 0 0.5px rgb(128,128,128)',
                    borderRadius: 9,
                    borderRadiusLG: 9,
                }
            },
        }}>
            {
                ispasswordinput ? <Input.Password {...inputProps} /> : isnumberinput ? <InputNumber {...inputProps} /> :
                    istextareainput ? <Input.TextArea  {...inputProps} /> :
                        issearchinput ? <Input.Search {...inputProps} /> : <Input {...inputProps} />
            }
        </ConfigProvider>
    )
}

export const NJVSelect = ({ children, ...selectProps }) => {
    const { bgcolor } = selectProps;
    return (
        <ConfigProvider theme={{
            token: {
                colorBgContainer: `${bgcolor ? bgcolor : '#fff'}`,
                colorBorder: '#fff',
                colorPrimary: 'rgb(128, 128, 128)',
                colorPrimaryHover: '#fff',
                // controlHeightLG: '40px',
            },
            components: {
                Select: {
                    borderRadius: '9px',
                    optionPadding: '4px 12px',
                    optionSelectedBg: 'rgb(128, 128, 128)',
                    optionSelectedColor: '#fff',
                    optionHeight: 30
                }
            }
        }}>
            <Select {...selectProps} >{children}</Select>
        </ConfigProvider>
    )
}

export const NJVLabel = ({ label }) => {
    return (
        <>
            <div style={{ fontSize: 13, fontWeight: '600', color: 'gray' }}>{label}</div>
        </>)
}

export const NJVSearchFilterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());