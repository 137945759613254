import { Spin } from "antd";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearCookie } from "../../redux/cookie-slice";
import CustomPath from "../../routes/custom-path";

function RedirectLogin() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(clearCookie())
        navigate(CustomPath.app)
    }, [])

    return (
        <>
            <div style={{ width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Spin size='large' />
            </div>
        </>
    )

}

export default RedirectLogin