import { compose } from "@reduxjs/toolkit";
import { Card, Col, Row, Skeleton, Statistic } from "antd";
import React from "react";
import CountUp from 'react-countup';
import Theme from "../components/theme";
import Api from "../network/api";
import { HTTP_METHOD } from "../network/httpMethod";
import { MEDIA_TYPE } from "../network/mediaType";
import { ApiHandler } from "../network/network-manager";
import withRouter from "../network/with-router";

const formatter = (value) => <CountUp end={value} separator="," />;

const titlfontStyle = {
    fontWeight: '600',
    fontSize: 16,
}

class DashboardPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            admin_count: 0,
            shipper_count: 0,
            pending_shipper_count: 0,
            reject_shipper_count: 0,
            isAdmin: false,
            isFetchingData: false
        }
    }

    componentDidMount() {
        this.fetchBaseUserSummary()
    }

    fetchBaseUserSummary = async () => {
        this.setState({
            isFetchingData: true
        })
        try {
            const response = await ApiHandler({ url: Api.base_user_summary, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON })
            if (response) {
                this.setState({
                    admin_count: response.adminCount,
                    shipper_count: response.shipperCount,
                    pending_shipper_count: response.pendingShipperCount,
                    reject_shipper_count: response.rejectShipperCount,
                    isAdmin: response.admin
                })
            }
        } catch (error) {
            console.log(error);
        } finally {
            this.setState({
                isFetchingData: false
            })
        }
    }

    render() {
        const { admin_count,
            shipper_count,
            pending_shipper_count,
            reject_shipper_count, isAdmin, isFetchingData } = this.state
        return (
            <>
                {
                    isFetchingData ?
                        <Skeleton active />
                        :
                        isAdmin ?
                            <Row gutter={[16, 16]}>
                                <Col span={6}>
                                    <Card
                                        style={{ backgroundColor: Theme.colors.card_bg_color, boxShadow: 'none' }}
                                        bordered={false}>
                                        <Statistic
                                            title={<span style={titlfontStyle}>Admin</span>}
                                            value={admin_count}
                                            valueStyle={{
                                                color: '#3f8600',
                                            }}
                                            formatter={formatter}
                                        />
                                    </Card>
                                </Col>
                                <Col span={6}>
                                    <Card
                                        style={{ backgroundColor: Theme.colors.card_bg_color, boxShadow: 'none' }}
                                        bordered={false}>
                                        <Statistic
                                            title={<span style={titlfontStyle}>Shipper</span>}
                                            value={shipper_count}
                                            valueStyle={{
                                                color: '#3f8600',
                                            }}
                                            formatter={formatter}
                                        />
                                    </Card>
                                </Col>
                                <Col span={6}>
                                    <Card
                                        style={{ backgroundColor: Theme.colors.card_bg_color, boxShadow: 'none' }}
                                        bordered={false}>
                                        <Statistic
                                            title={<span style={titlfontStyle}>Pending Shipper</span>}
                                            value={pending_shipper_count}
                                            valueStyle={{
                                                color: '#3f8600',
                                            }}
                                            formatter={formatter}
                                        />
                                    </Card>
                                </Col>
                                <Col span={6}>
                                    <Card
                                        style={{ backgroundColor: Theme.colors.card_bg_color, boxShadow: 'none' }}
                                        bordered={false}>
                                        <Statistic
                                            title={<span style={titlfontStyle}>Rejected Shipper</span>}
                                            value={reject_shipper_count}
                                            valueStyle={{
                                                color: '#3f8600',
                                            }}
                                            formatter={formatter}
                                        />
                                    </Card>
                                </Col>

                            </Row> :
                            <div style={{ textAlign: 'center', marginTop: 200 }}>
                                <h1 style={{ fontSize: 50 }}><span style={{ color: "#c2002f" }}>Ninja</span> Van Myanmar</h1>
                            </div>
                }
            </>
        )
    }
}

export default compose(withRouter)(DashboardPage)